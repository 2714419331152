import { Typography, Radio, message } from "antd";
// import { useActivateCandidateMutation } from 'graphql/generated/graphql'
import React, { useContext, useEffect, useState } from "react";

import "./styles.scss";

interface PropsType {
  candidate: any;
}

const { Title } = Typography;

const Styles = {
  flexDefault: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 2,
  },
  btn: {
    width: 28,
    height: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 36,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#F5F7F9",
    marginTop: -5,
    marginRight: 20,
    cursor: "pointer",
  },
  radio: {
    marginRight: 10,
    borderRadius: 40,
    padding: 5,
    borderWidth: 1,
    height: 30,
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioGroup: {
    display: "flex",
  },
};

const Activation: React.FC<PropsType> = ({ candidate }) => {
  const [score, setScore] = useState<any>(0);
  // const [addActivateCandidateMutation] = useActivateCandidateMutation();
  // useEffect(() => {
  //     setScore(editContext?.match?.activationScore)
  // }, [editContext?.match])

  const handleChangeScore = (e: any) => {
    // addActivateCandidateMutation({
    //     variables: {
    //         matchId: editContext?.match?._id,
    //         activationScore: parseInt(e.target.value),
    //     },
    // }).then(res => {
    //     setScore(e.target.value)
    //     message.success({ content: 'Activation score updated!', duration: 2 });
    // }).catch(err => {
    //     console.log(err)
    // })
  };

  return (
    <div style={Styles.flexDefault}>
      <Title style={{ fontSize: 16, margin: 0, marginTop: -8, width: 110 }}>
        Activation
      </Title>
      <Radio.Group
        onChange={handleChangeScore}
        className="activation-radio"
        style={Styles.radioGroup}
        value={score}
        buttonStyle="solid"
      >
        <Radio.Button style={Styles.radio} value={-1}>
          -1
        </Radio.Button>
        <Radio.Button style={Styles.radio} value={0}>
          0
        </Radio.Button>
        <Radio.Button style={Styles.radio} value={1}>
          1
        </Radio.Button>
        <Radio.Button style={Styles.radio} value={2}>
          2
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default Activation;
