import { FlagIcon } from "assets/icons/DetailedProfile";
import React, { useContext, useEffect } from "react";
import { SectionTitle } from "../../Shared";
import { SingleLanguage, LanguageComment } from "./Index";

interface PropsType {
  candidate: any;
  nocomments: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #BC9623",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    // paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 20,
  },
};

const Languages: React.FC<PropsType> = ({ candidate, nocomments }) => {
  return (
    <>
      {candidate?.languages?.length > 0 && (
        <div style={Styles.root}>
          <SectionTitle
            title="Languages"
            icon={FlagIcon}
            fontSize="16px"
            actionType="edit"
            showstyle={false}
          />
          <div style={Styles.body}>
            {candidate?.languages &&
              candidate?.languages.map((language: any) => {
                return (
                  <div>
                    <SingleLanguage language={language} />
                  </div>
                );
              })}
            {nocomments === false ? (
              <></>
            ) : (
              candidate?.languageComments && (
                <LanguageComment comment={candidate?.languageComments} />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Languages;
