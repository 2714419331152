import React, { useState } from "react";
import {
  Card,
  Table,
  Input,
  Popconfirm,
  Form,
  Button,
  Select,
  Modal,
  Space,
} from "antd";

const { Option } = Select;

interface Props {
  skills?: any;
  name?: any;
}

interface Item {
  key: string;
  skillName: string;
  skillRequired: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <Select>
        <Option value="1">Must have</Option>
        <Option value="2">Nice to have</Option>
      </Select>
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AddSkillsTable: React.FC<Props> = ({ skills, name }) => {
  const originData: Item[] = skills ? [...skills] : [];

  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [modalVisible, setModalVisible] = useState(false);

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Item) => {
    // form.setFieldsValue({ skillName: "", skillRequired: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Skill",
      dataIndex: "skillName",
      editable: true,
    },

    {
      title: "Requirements",
      dataIndex: "skillRequired",
      key: "skillRequired",
      editable: true,
      render: (skillRequired: any) => (
        <div>{skillRequired === 1 ? "Must have" : "Nice to have"}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
              className="font-10 px-2 m-2 badge badge-pill badge-light"
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a className="font-10 px-2 m-2 badge badge-pill badge-default">
                Cancel
              </a>
            </Popconfirm>
          </span>
        ) : (
          <a
            onClick={() => edit(record)}
            className="font-10 px-2 m-2 badge badge-pill badge-light"
          >
            Edit
          </a>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "skillRequired" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = () => {
    const newData = {
      key: "ss",
      skillName: `Edward King`,
      skillRequired: 2,
    };
    setData([...data, newData]);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModal = (values: any) => {
    const newData = {
      key: values.skill,
      skillName: values.skill,
      skillRequired: values.skillRequirement,
    };
    setData([...data, newData]);
    setModalVisible(false);
  };
  return (
    <>
      <Card>
        <div className="add-skills-table-wrapper">
          <h4>{name}</h4>
          <div className="text-right">
            <Button
              onClick={() => setModalVisible(true)}
              className="ant-btn-burgundy  custom-btn"
            >
              Add a skill
            </Button>
          </div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={data}
              columns={mergedColumns}
              size="small"
              rowClassName="editable-row"
              pagination={false}
            />
          </Form>
        </div>
      </Card>
      <Modal
        title="Add skills"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div>
          <Form
            name="normal_login"
            className="login-form"
            layout="vertical"
            // onFieldsChange={() => setHasError(false)}
            onFinish={handleModal}
          >
            <Form.Item
              name="skill"
              label="Skill"
              rules={[
                {
                  required: true,
                  message: "Please enter skill!",
                },
              ]}
            >
              <Input type="text" placeholder="Enter skill" />
            </Form.Item>
            <Form.Item
              name="skillRequirement"
              label="Requirment"
              rules={[
                {
                  required: true,
                  message: "Please select requirement!",
                },
              ]}
              hasFeedback
            >
              <Select placeholder="Select assessment key">
                <Option value="1">Must have</Option>
                <Option value="2">Nice to have</Option>
              </Select>
            </Form.Item>
            <div className="text-right">
              <Space>
                <Button
                  className="ant-btn-default"
                  shape="round"
                  onClick={() => setModalVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="ant-btn-primary"
                  htmlType="submit"
                  shape="round"
                >
                  Add a skill
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddSkillsTable;
