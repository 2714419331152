import React from "react";

interface PropsType {
  language: any;
}

const SingleLanguage: React.FC<PropsType> = ({ language }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginLeft: 24,
      }}
    >
      <div style={{ width: "45%" }}>
        <p style={{ fontSize: 14, color: "#495057", margin: 0 }}>
          {language.language}
        </p>
      </div>
      <div style={{ width: "55%" }}>
        <p style={{ fontSize: 14, color: "#2E4A79", margin: 0 }}>
          {language.level}
        </p>
      </div>
    </div>
  );
};

export default SingleLanguage;
