import React, { useState, useEffect, useRef } from "react";
import {
  Redirect,
  Route,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  useGetCandidateQuery,
  useGetMatchQuery,
} from "graphql/generated/graphql";
import { Row, Col, message } from "antd";
import { PersonalInfo } from "containers/Candidates/Profile";
import {
  Benchmark,
  Summary,
} from "containers/Candidates/Profile/Summary/Index";
import Skills from "containers/Candidates/Profile/Summary/Components/Skills";
import {
  Attachment,
  Education,
  Experience,
  Languages,
  PersonalDetails,
  ProfessionalIntrests,
  Resume,
  TargetLocations,
  YearlyCompensation,
} from "containers/Candidates/Profile";
import Comments from "containers/Candidates/Profile/RecruiterComments/Comments";
import NextSteps from "containers/Candidates/Profile/RecruiterComments/NextSteps";
import "./print.scss";
import Myobjective from "containers/Candidates/Profile/Motivations/Myobjective";
import Mytarget from "containers/Candidates/Profile/Motivations/Mytarget";
import Inspirecompanies from "containers/Candidates/Profile/Motivations/Inspirecompanies";
import Printview2 from "./Printview2";
import printlogo from "assets/icons/printicons/printlogo.svg";

interface ViewRecruiterProps {
  Data: any;
  nocomments: any;
  loading?: any;
}

const Styles = {
  Summary: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 29,
    paddingBottom: "0px !important",
    // border: "solid 1px #314774",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    // width: "101%",
    justifyCintent: "justify",
  },
};

const Printview: React.FC<ViewRecruiterProps> = (Data, nocomments) => {
  const { path } = useRouteMatch();
  console.log(Data);
  console.log(path);
  const professionalInterestsRef = useRef<HTMLDivElement>(null);
  const pdRef = useRef<HTMLDivElement>(null);
  const ycRef = useRef<HTMLDivElement>(null);
  const tfRef = useRef<HTMLDivElement>(null);
  const { candidateId, matchId } = useParams<any>();
  const location: any = useLocation();
  const [candidateData, setCandidateData] = useState<any | null | undefined>(
    null
  );
  const [candidates, setCandidates] = useState<any>(null);
  const [matchData, setMatchData] = useState<any | null | undefined>(null);
  const [match, setMatch] = useState<any | null | undefined>(null);

  //gql query for get data
  const {
    data: data1,
    loading: loading1,
    error: error1,
  }: any = useGetMatchQuery({
    variables: {
      // matchId: location.state?.matchId,
      // matchId: "62e3b851ff4442000f716feb",
      // matchId: "62e3b851ff4442000f716feb",
      matchId: matchId,
    },
    fetchPolicy: "network-only",
  });

  const { data, loading, error } = useGetCandidateQuery({
    variables: {
      // candidateId: "62e3b851ff4442000f716fe7",
      candidateId: candidateId,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data1 && data1?.getMatch && data1?.getMatch?.candidateListing) {
      const { candidateListing, ...rest }: any = data1?.getMatch;
      setMatch(rest);
      setMatchData(rest);
      setCandidates(location.state?.candidateArray);
    }
  }, [data1]);

  useEffect(() => {
    if (data && data.getCandidate) {
      console.log({ ...data?.getCandidate });
      setCandidateData(data?.getCandidate);
    }
  }, [data]);

  // let candidate = { description: candidateData?.description, Visible: false }
  return (
    <div
      className="main"
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        paddingLeft: 35,
        paddingRight: 35,
        paddingTop: 29,
        paddingBottom: 29,
      }}
    >
      <div style={{ marginBottom: "25px" }}>
        <img src={printlogo} alt="" />
      </div>
      <Row gutter={24}>
        <Col span={6} style={{ marginLeft: "-10px" }}>
          <PersonalInfo
            match={matchData}
            candidate={candidateData}
            showActions={false}
            showSocial={false}
            showVideo={false}
            loading={false}
          />
        </Col>
        <Col span={18} style={Styles.Summary}>
          <Summary
            candidate={candidateData}
            match={matchData}
            Visible={false}
            showBenchmark={false}
          />
        </Col>
        {/* <Col
          className="column-sb"
          style={{
            display: "flex",
            // border: "1px solid rgb(91, 98, 107)",
            boxShadow: "0px 0px 4px #00000040 ",
            borderRadius: "10px",
            paddingTop: "20px",
            paddingRight: "1px",
            width:'60%'
          }}
          span={18}
        >
          <Col span={16}>
            <Skills
              candidate={candidateData}
              inDrawer={false}
              showSeeMore={false}
              showskillstyle={true}
            />
            </Col>
          <Col span={8} className="bench">
            <Benchmark candidate={candidateData} match={matchData} />
        </Col>
        </Col> */}
      </Row>

      <Row>
        <Col
          className="column-sb mt-4"
          style={{
            display: "flex",
            // border: "1px solid rgb(91, 98, 107)",
            boxShadow: "0px 0px 4px #00000040 ",
            borderRadius: "10px",
            paddingTop: "20px",
            // marginRight:'10px',
            // paddingRight: "20px",
            // width:'90%'
          }}
          span={24}
        >
          <Col span={18} style={{ paddingLeft: "20px" }}>
            <Skills
              candidate={candidateData}
              inDrawer={false}
              showSeeMore={false}
              showskillstyle={true}
            />
          </Col>
          <Col span={6} className="bench" style={{ paddingBottom: "20px" }}>
            {match?.benchmark != null && match?.benchmark != "" ? (
              <Benchmark candidate={candidateData} match={matchData} />
            ) : (
              ""
            )}
          </Col>
        </Col>

        {/* <Col span={24} style={Styles.Summary} className="mt-4">
            <Summary
              candidate={candidateData}
              match={matchData}
              Visible={false}
              showBenchmark={false}
            />
        </Col> */}
      </Row>
      <Row style={{ marginTop: 25, justifyContent: "center" }} gutter={24}>
        {/* Motivation */}
        <Col span={6}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              fontSize: 20,
              fontWeight: 600,
              color: "white",
              backgroundColor: "#2e4a79",
              borderRadius: 10,
              marginBottom: 30,
            }}
          >
            <span>MOTIVATIONS</span>
          </div>
          <div ref={tfRef}>
            <TargetLocations candidate={candidateData} />
          </div>

          <div ref={professionalInterestsRef} style={{ marginTop: 20 }}>
            <ProfessionalIntrests candidate={candidateData} />
          </div>
          <div ref={professionalInterestsRef} style={{ marginTop: 20 }}>
            <Myobjective candidate={candidateData} />
          </div>
          <div ref={professionalInterestsRef} style={{ marginTop: 20 }}>
            <Mytarget candidate={candidateData} />
          </div>
          <div ref={professionalInterestsRef} style={{ marginTop: 20 }}>
            <Inspirecompanies candidate={candidateData} />
          </div>

          <div ref={ycRef} style={{ marginTop: 20 }}>
            <YearlyCompensation
              nocomments={location?.state?.comments}
              candidate={candidateData}
            />
          </div>

          <div ref={pdRef} style={{ marginTop: 20 }}>
            <PersonalDetails candidate={candidateData} />
          </div>
        </Col>
        {/* Motivation */}

        {/* Background */}
        <Col span={10}>
          {/* Background */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              fontSize: 18,
              fontWeight: 600,
              color: "white",
              backgroundColor: "#BC9623",
              borderRadius: 10,
              marginBottom: 30,
            }}
          >
            <span>BACKGROUND</span>
          </div>
          {/* Experience */}
          <div style={{ marginTop: 20 }}>
            <Experience
              candidate={candidateData}
              nocomments={location?.state?.comments}
            />
          </div>
          {/* Education */}
          <div style={{ marginTop: 20 }}>
            <Education
              candidate={candidateData}
              nocomments={location?.state?.comments}
            />
          </div>
          {/* Languages */}
          <div style={{ marginTop: 20 }}>
            <Languages
              candidate={candidateData}
              nocomments={location?.state?.comments}
            />
          </div>
          {/* Resume */}
          <div style={{ marginTop: 20 }}>
            {/* <Resume candidate={candidateData} /> */}
          </div>
          {/* Attachment */}
          <div style={{ marginTop: 20 }}>
            {/* <Attachment candidate={candidateData} /> */}
          </div>
        </Col>
      </Row>
      {location.state?.comments && (
        <div>
          <Printview2 />
        </div>
      )}
    </div>
  );
};

export default Printview;
