import { Popover, Tag } from "antd";
import React, { useState } from "react";

interface PopoverTextProps {
  country?: string;
  isTag: Boolean;
  event?: string;
  isJobLocation?: boolean;
  countries?: any;
  placement?: any;
}

const PopoverText: React.FC<PopoverTextProps> = ({
  countries,
  country,
  isTag,
  event,
  isJobLocation,
  placement,
}) => {
  const content = <div style={{ maxWidth: "400px" }}>{country}</div>;
  const countriesPopUpContent = (
    <>
      {countries?.map((countrySigle: any, index: any) => {
        if (countrySigle) {
          if (countries.length > 1 && index + 1 < countries.length)
            return countrySigle?.name + ", ";
          else return countrySigle?.name;
        }
      })}
    </>
  );
  return (
    <>
      <Popover
        placement={placement ? placement : "top"}
        title={""}
        content={isJobLocation ? countriesPopUpContent : content}
        trigger={event == "hover" ? "hover" : "click"}
        style={{ width: "100%" }}
        className="Popover-long-location"
      >
        {isTag ? (
          <Tag
            style={{
              borderRadius: 10,
              marginTop: 2,
              maxWidth: "85px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            color="#bc9623"
          >
            {country}
          </Tag>
        ) : isJobLocation ? (
          <div
            style={{
              marginTop: 2,
              maxWidth: "240px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {countries?.map((countrySigle: any, index: any) => {
              if (countrySigle) {
                if (countries.length > 1 && index + 1 < countries.length)
                  return countrySigle?.name + ", ";
                else return countrySigle?.name;
              }
            })}
          </div>
        ) : (
          <div
            style={{
              marginTop: 2,
              maxWidth: "240px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {country}
          </div>
        )}
      </Popover>
    </>
  );
};

export default PopoverText;
