import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import JobListings from "./JobListings/JobListings";
import ViewJob from "./ViewJob/ViewJob";
import InterviewDashboard from "./InterviewDashboard/InterviewDashboard";
import CandidateReport from "./InterviewDashboard/CandidateReport";
import CandidateInterview from "./InterviewDashboard/CandidateInterview";
import ManageAssessment from "./InterviewDashboard/ManageAssessment";
import NotFound from "../../components/NotFound/NotFound";
import AddJob from "./PostJob/PostJob";
import Candidates from "containers/Candidates";
const Jobs: React.FC = () => {
  let { path, url } = useRouteMatch();
  let { pathname } = useLocation();

  return (
    <>
      <Switch>
        <Route exact path={path} component={JobListings} />
        <Route exact path={`${path}/view/:jobId`} component={ViewJob} />
        <Route
          exact
          path={`${path}/view/:jobId/candidate`}
          component={Candidates}
        />
        <Route
          path={`${path}/dashboard/:jobId/candidate/:candidateId`}
          component={CandidateReport}
        />
        <Route
          path={`${path}/dashboard/:jobId/manage-assessment`}
          component={ManageAssessment}
        />
        <Route
          path={`${path}/dashboard/:jobId/:interviewId/candidate/:candidateId`}
          component={CandidateInterview}
        />

        <Route path={`${path}/add-job`} component={AddJob} />
        <Route path={`${path}/edit/:jobId`} component={AddJob} />
        <Route
          path={`${path}/dashboard/:jobId`}
          component={InterviewDashboard}
        />

        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    </>
  );
};

export default Jobs;
