import {
  Matchs,
  matchsActionTypes,
  STORE_NEW_CANDIDATES,
  STORE_LIKED_CANDIDATES,
  MOVED_TO_MATCHED_CANDIDATES,
  STORE_DISLIKED_CANDIDATES,
  STORE_MATCHED_CANDIDATES,
  MOVED_TO_LIKED_CANDIDATES,
  MOVED_TO_DISLIKED_CANDIDATES,
  MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES,
  MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES,
  MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES,
  MOVED_TO_MATCHED_FROM_DISLIKED_CANDIDATES,
  MOVED_TO_MATCHED_FROM_SELECTED_CANDIDATES,
  MOVED_TO_SELECTED_FROM_REJECTED_CANDIDATES,
  STORE_SELECTED_CANDIDATES,
  STORE_SELECTED_CANDIDATES_LOAD,
  STORE_REJECTED_CANDIDATES,
  LOAD_REJECTED_CANDIDATES,
  STORE_HIRED_CANDIDATES,
  LOAD_HIRED_CANDIDATES,
  STORE_FAVOURITES_CANDIDATES,
  LOAD_FAVOURITES_CANDIDATES,
} from "./types";
import _ from "lodash";

import { updateObject } from "../../helpers/updateObject";

const initialState: Matchs = {
  selectedCandidates: [],
  newCandidates: [],
  disLikedCandidates: [],
  likedCandidates: [],
  matchedCandidates: [],
  rejectedCandidates: [],
  hiredCandidates: [],
  favouritesCandidates: [],
};

function containsObject(obj: any, list: any) {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
}

const storeLikedCandidates = (state: Matchs, action: any) => {
  return updateObject(state, { likedCandidates: action.likedCandidates });
};

const moveToLikedFromNewCandidates = (state: Matchs, action: any) => {
  const likedCandidates = [...state.likedCandidates];
  let newCandidates = [...state.newCandidates];
  const candidate = newCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  newCandidates = newCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  likedCandidates.push(candidate);

  return updateObject(state, {
    newCandidates: newCandidates,
    likedCandidates: likedCandidates,
  });
};

const moveToMatchedFromNewCandidates = (state: Matchs, action: any) => {
  const matchedCandidates = [...state.matchedCandidates];
  let newCandidates = [...state.newCandidates];
  const candidate = newCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  newCandidates = newCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  matchedCandidates.push(candidate);

  return updateObject(state, {
    newCandidates: newCandidates,
    matchedCandidates: matchedCandidates,
  });
};

const MoveToMatchedFromDislikeCandidates = (state: Matchs, action: any) => {
  const matchedCandidates = [...state.matchedCandidates];
  let disLikedCandidates = [...state.disLikedCandidates];
  const candidate = disLikedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  disLikedCandidates = disLikedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  matchedCandidates.push(candidate);

  return updateObject(state, {
    disLikedCandidates: disLikedCandidates,
    matchedCandidates: matchedCandidates,
  });
};

const moveToLikedFromDislikedCandidates = (state: Matchs, action: any) => {
  const likedCandidates = [...state.likedCandidates];
  let disLikedCandidates = [...state.disLikedCandidates];
  const candidate = disLikedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  disLikedCandidates = disLikedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  likedCandidates.push(candidate);

  return updateObject(state, {
    disLikedCandidates: disLikedCandidates,
    likedCandidates: likedCandidates,
  });
};

const moveToDisLikedFromMatchedCandidates = (state: Matchs, action: any) => {
  const disLikedCandidates = [...state.disLikedCandidates];
  let matchedCandidates = [...state.matchedCandidates];
  const candidate = matchedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  matchedCandidates = matchedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  disLikedCandidates.push({ ...candidate, lastAction: "match-disliked" });
  return updateObject(state, {
    matchedCandidates: matchedCandidates,
    disLikedCandidates: disLikedCandidates,
  });
};

const moveToDisLikedFromNewCandidates = (state: Matchs, action: any) => {
  const disLikedCandidates = [...state.disLikedCandidates];
  let newCandidates = [...state.newCandidates];
  const candidate = newCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  newCandidates = newCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  disLikedCandidates.push({
    ...candidate,
    lastAction: "new-candidate-disliked",
  });

  return updateObject(state, {
    newCandidates: newCandidates,
    disLikedCandidates: disLikedCandidates,
  });
};

const moveToDisLikedFromLikedCandidates = (state: Matchs, action: any) => {
  const disLikedCandidates = [...state.disLikedCandidates];
  let likedCandidates = [...state.likedCandidates];
  const candidate = likedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  likedCandidates = likedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  disLikedCandidates.push(candidate);

  return updateObject(state, {
    likedCandidates: likedCandidates,
    disLikedCandidates: disLikedCandidates,
  });
};

const storeDisLikedCandidates = (state: Matchs, action: any) => {
  return updateObject(state, {
    disLikedCandidates: action.disLikedCandidates || [],
  });
};

const storeNewCandidates = (state: Matchs, action: any) => {
  return updateObject(state, { newCandidates: action.newCandidates || [] });
};

const storeMatchedCandidates = (state: Matchs, action: any) => {
  return updateObject(state, { matchedCandidates: action.matchedCandidates });
};

const storeSelectedCandidatesLoad = (state: Matchs, action: any) => {
  return updateObject(state, { selectedCandidates: action.storeSelected });
};

const loadRejectedCandidate = (state: Matchs, action: any) => {
  return updateObject(state, { rejectedCandidates: action.storeSelected });
};

const loadHiredCandidate = (state: Matchs, action: any) => {
  return updateObject(state, { hiredCandidates: action.storeSelected });
};

const loadFavouritesCandidate = (state: Matchs, action: any) => {
  return updateObject(state, { favouritesCandidates: action.storeSelected });
};

const moveToSelectedCandidates = (state: Matchs, action: any) => {
  const selectedCandidates = [...state.selectedCandidates];
  let matchedCandidates = [...state.matchedCandidates];
  const candidate = matchedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  // console.log(candidate);
  matchedCandidates = matchedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  if (!containsObject(candidate, selectedCandidates)) {
    selectedCandidates.push(candidate);
  }

  return updateObject(state, {
    matchedCandidates: matchedCandidates,
    selectedCandidates: selectedCandidates,
  });
};

const moveToHiredCandidates = (state: Matchs, action: any) => {
  const hiredCandidates = [...state.hiredCandidates];
  let selectedCandidates = [...state.selectedCandidates];
  const candidate = selectedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  // console.log(candidate);
  selectedCandidates = selectedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  if (!containsObject(candidate, hiredCandidates)) {
    hiredCandidates.push(candidate);
  }

  return updateObject(state, {
    selectedCandidates: selectedCandidates,
    hiredCandidates: hiredCandidates,
  });
};

const moveToRejectedCandidates = (state: Matchs, action: any) => {
  // console.log("test rejected");
  const rejectedCandidates = [...state.rejectedCandidates];
  let selectedCandidates = [...state.selectedCandidates];
  const candidate = selectedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  // console.log(candidate);
  selectedCandidates = selectedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  if (!containsObject(candidate, rejectedCandidates)) {
    rejectedCandidates.push(candidate);
  }

  return updateObject(state, {
    selectedCandidates: selectedCandidates,
    rejectedCandidates: rejectedCandidates,
  });
};

const moveToMatchedFromSelectedCandidates = (state: Matchs, action: any) => {
  const matchedCandidates = [...state.matchedCandidates];
  let selectedCandidates = [...state.selectedCandidates];
  const candidate = selectedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  selectedCandidates = selectedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  matchedCandidates.push(candidate);

  return updateObject(state, {
    selectedCandidates: selectedCandidates,
    matchedCandidates: matchedCandidates,
  });
};

const movedToSelectedFromRejectedCandidates = (state: Matchs, action: any) => {
  const selectedCandidates = [...state.selectedCandidates];
  let rejectedCandidates = [...state.rejectedCandidates];
  const candidate = rejectedCandidates.find(
    (c: any) => c._id === action.candidateId
  );
  rejectedCandidates = rejectedCandidates.filter(
    (c: any) => c._id !== action.candidateId
  );
  selectedCandidates.push(candidate);

  return updateObject(state, {
    rejectedCandidates: rejectedCandidates,
    selectedCandidates: selectedCandidates,
  });
};

const matchsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_NEW_CANDIDATES:
      return storeNewCandidates(state, action);
    case STORE_LIKED_CANDIDATES:
      return storeLikedCandidates(state, action);
    case STORE_DISLIKED_CANDIDATES:
      return storeDisLikedCandidates(state, action);
    case STORE_MATCHED_CANDIDATES:
      return storeMatchedCandidates(state, action);
    case MOVED_TO_LIKED_CANDIDATES:
      return moveToLikedFromNewCandidates(state, action);
    case MOVED_TO_MATCHED_CANDIDATES:
      return moveToMatchedFromNewCandidates(state, action);
    case MOVED_TO_MATCHED_FROM_DISLIKED_CANDIDATES:
      return MoveToMatchedFromDislikeCandidates(state, action);
    case MOVED_TO_DISLIKED_CANDIDATES:
      return moveToDisLikedFromNewCandidates(state, action);
    case MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES:
      return moveToDisLikedFromLikedCandidates(state, action);
    case MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES:
      return moveToLikedFromDislikedCandidates(state, action);
    case MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES:
      return moveToDisLikedFromMatchedCandidates(state, action);
    case MOVED_TO_MATCHED_FROM_SELECTED_CANDIDATES:
      return moveToMatchedFromSelectedCandidates(state, action);
    case MOVED_TO_SELECTED_FROM_REJECTED_CANDIDATES:
      return movedToSelectedFromRejectedCandidates(state, action);
    case STORE_SELECTED_CANDIDATES:
      return moveToSelectedCandidates(state, action);
    case STORE_SELECTED_CANDIDATES_LOAD:
      return storeSelectedCandidatesLoad(state, action);
    case STORE_REJECTED_CANDIDATES:
      return moveToRejectedCandidates(state, action);
    case LOAD_REJECTED_CANDIDATES:
      return loadRejectedCandidate(state, action);
    case STORE_HIRED_CANDIDATES:
      return moveToHiredCandidates(state, action);
    case LOAD_HIRED_CANDIDATES:
      return loadHiredCandidate(state, action);
    case LOAD_FAVOURITES_CANDIDATES:
      return loadFavouritesCandidate(state, action);
    default:
      return state;
  }
};

export default matchsReducer;
