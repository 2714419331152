import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, notification } from "antd";
import { Fade } from "react-awesome-reveal";
import { useResetPasswordRequestMutation } from "../../../../graphql/generated/graphql";
// import { useValidateEmailMutation } from "../../../../graphql/generated/graphql";

interface Props {
  managerScreenHandler: any;
}

const ValidateEmailForm: React.FC<Props> = ({ managerScreenHandler }) => {
  const [form] = Form.useForm();
  const [hasError, setHasError] = useState(false);
  const [requestResetPassword] = useResetPasswordRequestMutation();

  // const [validateEmailMutation, { loading }] = useValidateEmailMutation();

  const onFinish = (values: any) => {
    requestResetPassword({
      variables: {
        email: values.emailAddress,
      },
    })
      .then(({ data }) => {
        if (data) {
          notification["success"]({
            message: "Password reset email sent successfully",
          });
        } else {
          notification["error"]({
            message: "Unable to verify email address.",
            duration: 3,
            onClose: () => setHasError(false),
          });
        }
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to verify email address.",
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  return (
    <>
      <h4 className="text-muted font-18 mb-3 text-center">
        Recover your password
      </h4>
      <p className="text-muted text-center pt-0 mtp0">
        Remember It?{" "}
        <Link to="/login" className="text-primary">
          Log in here
        </Link>
      </p>
      <Fade>
        <Form
          name="recover-password-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          className="m-t-40"
        >
          <Form.Item
            name="emailAddress"
            label={<span style={{ fontSize: "14px" }}>Email address</span>}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              prefix={<i className="far fa-envelope font-14" />}
              placeholder="Enter your email address"
            />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                disabled={hasError}
                style={{ fontSize: "14px" }}
                // loading={loading}
              >
                Recover password
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Fade>
    </>
  );
};

export default ValidateEmailForm;
