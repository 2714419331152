import {
  notificationsActionTypes,
  STORE_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
} from "./types";

export const StoreNotifications = (
  notifications: Array<Object>
): notificationsActionTypes => {
  return {
    type: STORE_NOTIFICATIONS,
    notifications: notifications,
  };
};

export const AddNotification = (
  notification: Object
): notificationsActionTypes => {
  return {
    type: ADD_NOTIFICATION,
    notification: notification,
  };
};

export const DeleteNotification = (
  notificationId: String
): notificationsActionTypes => {
  return {
    type: DELETE_NOTIFICATION,
    notificationId: notificationId,
  };
};

export const DeleteAllNotifications = (
  notifications: Array<String>
): notificationsActionTypes => {
  return {
    type: DELETE_ALL_NOTIFICATIONS,
    notifications: notifications,
  };
};
