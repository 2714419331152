import { EducationIcon } from "assets/icons/DetailedProfile";
import React from "react";
import { SectionTitle } from "../../Shared";
import { SingleEducation, EducationComment } from "./Index";
import { Button, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

interface PropsType {
  candidate: any;
  nocomments: any;
  isRecruiterPage?: boolean;
  showEducationDrawer?: any;
  handleUpdate?: any;
  handleDeleteItem?: any;
}

const Education: React.FC<PropsType> = ({
  candidate,
  nocomments,
  isRecruiterPage,
  handleUpdate,
  showEducationDrawer,
  handleDeleteItem,
}) => {
  if (isRecruiterPage) candidate.education = candidate?.educations;
  candidate?.education?.sort((a, b): any => {
    if (a.current && !b.current) {
      return -1; // a comes before b
    } else if (!a.current && b.current) {
      return 1; // b comes before a
    } else if (a.current && b.current) {
      return b.endYear - a.endYear; // sort by descending endYear
    } else {
      return b.endYear - a.endYear; // sort by descending endYear
    }
  });

  const Styles = {
    root: {
      padding: isRecruiterPage ? 0 : 15,
      // border: "solid 1px #BC9623",
      boxShadow: isRecruiterPage ? "unset" : "0px 0px 4px #00000040 ",
      borderRadius: 10,
      paddingBottom: 5,
    },
    body: {
      // paddingLeft: 24,
      marginTop: 20,
    },
  };

  function mergeAndSort(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        const schoolNameI = arr[i]?.schoolName?.toLocaleLowerCase();
        const schoolNameJ = arr[j]?.schoolName?.toLocaleLowerCase();
        if (schoolNameI === schoolNameJ) {
          if (!arr[i].mergedData) {
            arr[i].mergedData = [arr[i]];
          }
          arr[i].mergedData.push(arr[j]);
          arr.splice(j, 1);
          j--;
        }
      }
      if (arr[i].mergedData) {
        arr[i].mergedData.sort((a, b) => {
          if (a.startYear !== b.startYear) {
            return b.startYear - a.startYear;
          } else {
            return b.endYear - a.endYear;
          }
        });
      }
    }
    return arr;
  }

  let data: any;

  if (candidate?.education) {
    data = mergeAndSort(candidate.education);
  }

  const handleClick = (experience, i) => {
    handleUpdate({ ...experience, index: i });
    showEducationDrawer(true);
  };

  const handleDelete = (index: any) => {
    handleDeleteItem(index, "edu");
  };

  return (
    <>
      {candidate?.education != null && candidate?.education.length > 0 && (
        <div style={Styles.root}>
          <SectionTitle
            isRecruiterPage={isRecruiterPage}
            title="Education"
            icon={EducationIcon}
            fontSize="16px"
            actionType="add"
            actionText="Add education"
            showstyle={false}
          />
          <div style={Styles.body}>
            {/* {candidate?.education &&
              candidate?.education.map((education: any) => {
                return (
                  <div>
                    <SingleEducation education={education} />
                  </div>
                );
              })} */}
            {data &&
              data.map((education: any, i: any) => {
                return (
                  <div style={{ marginBottom: "32px" }}>
                    <SingleEducation
                      education={education}
                      isRecruiterPage={isRecruiterPage}
                    />
                    {isRecruiterPage && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          marginLeft: "8px",
                          gap: 4,
                        }}
                      >
                        <Tooltip title="edit">
                          <Button
                            onClick={() => {
                              handleClick(education, i);
                            }}
                            className="border-primary"
                            shape="circle"
                            icon={<EditOutlined className="text-primary" />}
                            style={{ float: "right" }}
                          />
                        </Tooltip>
                        <Tooltip title="delete">
                          <Popconfirm
                            title="Confirm you want to delete this education"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDelete(i)}
                          >
                            <Button
                              className="border-primary"
                              shape="circle"
                              icon={<DeleteOutlined className="text-primary" />}
                              style={{ float: "right" }}
                            />
                          </Popconfirm>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                );
              })}
            {nocomments === false ? (
              <></>
            ) : (
              candidate?.educationComment && (
                <EducationComment comment={candidate?.educationComment} />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Education;
