import React, { useEffect, useState } from "react";
import { Button, Tooltip, Table, Space, Avatar, notification } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { Link } from "react-router-dom";
import { LikeOutlined, UserOutlined, WechatOutlined } from "@ant-design/icons";
import MyModal from "components/UI/Modal";
import Buttons from "../../../../components/UI/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-apollo";
import { removeNotification } from "components/TopBar/partials/NotificationDropdown/notifications";
import { DeleteNotification } from "store/notifications/actions";
interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
}
interface CandidateListingsProps {
  jobId: any;
  dislikedCandidates: any;
  candidateLikeOrDisLikeHandler: any;
  setSelectedCandidate: any;
  tabActiveKey: string;
  company: any;
  handleOnchange: any;
}

const DisLikedCandidatesListing: React.FC<CandidateListingsProps> = ({
  jobId,
  dislikedCandidates,
  candidateLikeOrDisLikeHandler,
  setSelectedCandidate,
  tabActiveKey,
  company,
  handleOnchange,
}) => {
  const { notifications } = useSelector((state: any) => state.notifications);
  const [chatNotifications, setchatNotifications] = useState([]); //this will contain all the notifications regarding the chat

  useEffect(() => {
    if (notifications.length) {
      const chatNotifications = notifications.filter(
        (notification: Notification) => notification.type === "chat"
      );
      // we will only save the match notifications in this arrray
      setchatNotifications(chatNotifications);
    }
    return () => {};
  }, [notifications]);

  const dispatch = useDispatch();

  const [setNotificationToRead, { data: any }] =
    useMutation(removeNotification);
  const clickOnNotification = (matchId: string, type: string) => {
    const notificationX = notifications.find(
      (notif: Notification) => notif.type == type && notif.matchId == matchId
    );
    setNotificationToRead({
      variables: {
        notificationId: notificationX?._id,
      },
    }).then((data: any) => {
      dispatch(DeleteNotification(notificationX?._id));
    });
  };

  return (
    <>
      <Table
        scroll={dislikedCandidates?.length > 0 ? { x: 1300 } : {}}
        onChange={handleOnchange}
        showSorterTooltip={false}
        locale={{
          emptyText: (
            <span>
              <p style={{ marginTop: "20px" }}>
                <Avatar icon={<UserOutlined />} />
              </p>
              <p>No candidate yet</p>
            </span>
          ),
        }}
        dataSource={dislikedCandidates}
        size="small"
      >
        <Column
          width="100"
          // fixed="left"
          // sorter={true}
          sortDirections={["descend", "ascend", "descend"]}
          defaultSortOrder="descend"
          sorter={(a: any, b: any) => {
            if (!a.updatedAt) a.updatedAt = "";
            if (!b.updatedAt) b.updatedAt = "";
            return a.updatedAt - b.updatedAt;
          }}
          title="Application"
          dataIndex="updatedAt"
          key="updatedAt"
          render={(updatedAt, record: any) => (
            <>{moment(parseInt(updatedAt)).format("DD/MM/YYYY")}</>
          )}
        />
        <Column
          title="First name"
          // sorter={true}
          sortDirections={["descend", "ascend", "descend"]}
          // defaultSortOrder="descend"
          sorter={(a: any, b: any) => {
            if (!a.candidateListing?.firstName)
              a.candidateListing.firstName = "";
            if (!b.candidateListing?.firstName)
              b.candidateListing.firstName = "";
            return a.candidateListing?.firstName.localeCompare(
              b.candidateListing?.firstName
            );
          }}
          dataIndex="candidateListing"
          key="First name"
          render={(candidate, record: any) => (
            <div className="ellipse">
              <Link
                to={{
                  pathname: `/candidate-listings/view/${candidate?._id}`,
                  state: {
                    company: company,
                    matchId: record?._id,
                    from: dislikedCandidates,
                    jobId: jobId,
                    jobName: record?.jobListing?.title,
                    companyName: record?.company?.name,
                    tabActiveKey,
                  },
                }}
              >
                {candidate?.firstName}
              </Link>
            </div>
          )}
        />
        <Column
          title="Last name"
          // sorter={true}
          sortDirections={["descend", "ascend", "descend"]}
          // defaultSortOrder="descend"
          sorter={(a: any, b: any) => {
            if (!a.candidateListing?.lastName) a.candidateListing.lastName = "";
            if (!b.candidateListing?.lastName) b.candidateListing.lastName = "";
            return a.candidateListing?.lastName.localeCompare(
              b.candidateListing?.lastName
            );
          }}
          dataIndex="candidateListing"
          key="Last name"
          render={(candidate, record: any) => (
            <div className="ellipse">
              <Link
                to={{
                  pathname: `/candidate-listings/view/${candidate?._id}`,
                  state: {
                    company: company,
                    matchId: record?._id,
                    from: dislikedCandidates,
                    jobId: jobId,
                    jobName: record?.jobListing?.title,
                    companyName: record?.company?.name,
                    tabActiveKey,
                  },
                }}
              >
                {candidate?.lastName}
              </Link>
            </div>
          )}
        />
        {/* <Column
          title='Source'
          dataIndex='media'
          key='_id'
          render={media => <>{media}</>}
        /> */}
        <Column
          title="Position"
          width="300px"
          // sorter={true}
          dataIndex="candidateListing"
          key="position"
          sortDirections={["descend", "ascend", "descend"]}
          // defaultSortOrder="descend"
          sorter={(a: any, b: any) => {
            if (!a.candidateListing?.position) a.candidateListing.position = "";
            if (!b.candidateListing?.position) b.candidateListing.position = "";
            return a.candidateListing?.position.localeCompare(
              b.candidateListing?.position
            );
          }}
          render={(candidate) => (
            <div
              className="ellipse"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={candidate?.experiences?.length > 0 && candidate?.position}
            >
              {candidate?.experiences?.length > 0 && candidate?.position}
            </div>
          )}
        />
        <Column
          title="Company"
          width="300px"
          // sorter={true}

          sortDirections={["descend", "ascend", "descend"]}
          // defaultSortOrder="descend"
          sorter={(a: any, b: any) => {
            const companyA =
              a?.candidateListing?.experiences?.[0]?.company || "";
            const companyB =
              b?.candidateListing?.experiences?.[0]?.company || "";

            return companyA.localeCompare(companyB);
          }}
          dataIndex="candidateListing"
          key="company"
          render={(candidate) => (
            <div
              className="ellipse"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={
                candidate?.experiences?.length > 0 &&
                candidate?.experiences[0]?.company
              }
            >
              {candidate?.experiences?.length > 0 &&
                candidate?.experiences[0]?.company}
            </div>
          )}
        />
        {/* <Column
          title="Email"
          dataIndex="candidateListing"
          key="candidateListing._id"
          render={(candidate) => <>{candidate?.user?.email}</>}
        /> */}
        <Column
          title="Actions"
          width="100"
          fixed="right"
          dataIndex="candidateListing"
          key="candidateListing._id"
          render={(candidate, record: any) => (
            <>
              <Space>
                <Buttons
                  type="btn-like"
                  onClick={() => {
                    setSelectedCandidate(candidate);
                    candidateLikeOrDisLikeHandler(
                      record?._id,
                      "like",
                      "fromDisLiked"
                    );
                  }}
                  toolTipTitle="Like"
                />
                {/* <Tooltip title="Like candidate">
                  <Button
                    className="ant-btn-green"
                    onClick={() => {
                      setSelectedCandidate(candidate);
                      candidateLikeOrDisLikeHandler(
                        record._id,
                        "like",
                        "fromDisLiked"
                      );
                    }}
                    shape="circle"
                    icon={<LikeOutlined />}
                  ></Button>
                </Tooltip> */}
                {/* <Buttons
                  type="btn-chat"
                  onClick={() => {
                    setSelectedCandidate(candidate);
                    candidateLikeOrDisLikeHandler(
                      record._id,
                      "like",
                      "fromDisLiked"
                    );
                  }}
                  toolTipTitle="Chat with candidate"
                /> */}
                {record?.lastAction === "match-disliked" && (
                  // <Tooltip title="Chat">
                  <div>
                    <MyModal
                      titleType="button"
                      enableCustomBtnIcon={true}
                      customBtnIcon={
                        chatNotifications.find(
                          (notification: Notification) =>
                            notification.matchId === record?._id
                        ) ? (
                          <div className="blip-parent">
                            <span className="blip"></span>
                            <Buttons
                              type="btn-chat-blip-matched"
                              toolTipTitle="Chat"
                              onClick={() =>
                                clickOnNotification(record?._id, "chat")
                              }
                            />
                          </div>
                        ) : (
                          <Buttons type="btn-chat" toolTipTitle="Chat" />
                        )
                      }
                      onOK={() => {}}
                      width={700}
                      footer={null}
                      ModalTytle="huu"
                      titleBtnClass="ant-btn-info"
                      record={record}
                      candidate={candidate}
                      company={company}
                    >
                      <div
                        style={{
                          paddingTop: 35,
                          paddingBottom: 35,
                        }}
                      ></div>
                    </MyModal>
                  </div>
                  // </Tooltip>
                )}
              </Space>
            </>
          )}
        />
      </Table>
    </>
  );
};

export default DisLikedCandidatesListing;
