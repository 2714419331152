import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, notification, Spin } from "antd";
import CompanyForm from "../partials/CompanyForm";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import {
  useAddCompanyMutation,
  useGetSingleCompanyLazyQuery,
} from "../../../graphql/generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { StoreCompanies, StoreCompany } from "store/companies/actions";
import { CompaniesState } from "store/companies/types";

import useSectors from "../../../hooks/useSectors";

const AddCompany: React.FC = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const sectors = useSectors();
  const location = useLocation();
  const params: any = useParams();
  const [company, setCompany]: any = useState(null);

  const companies = useSelector(
    (state: CompaniesState) => state.companies?.companies
  );
  const [imageData, setImageData] = useState(null);
  const [companyDescription, setCompanyDescription] = useState("");
  // const [companyConfidentialDescription, setCompanyConfidentailDescription] =
  //   useState("");
  const [loader, setLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [uploadLogo, setUploadLogo] = useState({
    loading: false,
    url: null,
    // url: "http://blonk.co/demo/SOBlonk/assets/images/users/user-2.jpg",
    error: "",
  });
  const [uploadBanner, setUploadBanner] = useState({
    loading: false,
    // url: "http://blonk.co/demo/SOBlonk/assets/images/users/user-2.jpg",
    url: null,
    error: "",
  });
  const [companyData, setCompanyData] = useState(Object);
  const [addCompanyMutation, { loading }] = useAddCompanyMutation();

  const editMode = location.pathname.includes("edit");
  // console.log("Edit mode: ", editMode);

  const [getCompany, { loading: loading1, data }] =
    useGetSingleCompanyLazyQuery({
      variables: {
        companyId: params.companyId,
      },
      fetchPolicy: "network-only",
    });

  //handle if image is not changed
  const prevCountRef: any = useRef();
  useEffect(() => {
    prevCountRef.current = imageData;
  });
  const prevImageData = prevCountRef.current;

  useEffect(() => {
    if (!uploadLogo.loading && Object.keys(companyData).length !== 0) {
      const url: any = uploadLogo.url;
      addCompanyMutation({
        variables: {
          ...companyData,
          logoUrl: url,
          banner: uploadBanner.url,
          companyId: params.companyId,
        },
      })
        .then(({ data }) => {
          if (editMode) {
            dispatch(StoreCompany({ ...data?.registerCompany }));
          } else {
            // Add company to state
            dispatch(
              StoreCompanies([...companies, { ...data?.registerCompany }])
            );
          }
          const action = editMode ? "edited" : "created";
          notification["success"]({
            duration: 2.5,
            message:
              action == "created"
                ? "Company registered successfully."
                : "The company information has been updated",
            onClose: () =>
              history.push(`/companies/view/${data?.registerCompany?._id}`),
            description: "",
          });
          setLoader(false);
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification["error"]({
            message: "Unable to create company",
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
            onClose: () => setHasError(false),
          });
        });
    }
  }, [uploadLogo.loading, companyData]);

  useEffect(() => {
    if (params.companyId) {
      getCompany({ variables: { companyId: params.companyId } });
    }
  }, []);

  useEffect(() => {
    if (data && data?.getSingleCompany) {
      // console.log("Inside params..", params.companyId);
      setCompany(data?.getSingleCompany);
    }
  }, [params, data]);

  const onFinishHandler = (values: any) => {
    if (companyDescription?.length == 0) {
      notification["error"]({
        message: "Please Enter Company description",
        duration: 3,
        onClose: () => setHasError(false),
      });
      return;
    }
    if (companyDescription.length > 50000) {
      alert("Company description should be less than 50000 characters.");
      return;
    } else {
      setLoader(true);
      if (editMode) {
        const sectorObj = sectors.find(
          (sector: any) =>
            sector._id == values.sector || sector.title == values.sector
        );
        const data: any = {
          title: values.companyName,
          description: companyDescription
            ? companyDescription
            : values.description,
          // confidentialdescription: companyConfidentialDescription
          //   ? companyConfidentialDescription
          //   : values.confidentialdescription,
          sector: sectorObj._id,
          logoUrl: uploadLogo.url,
          website: values.companyWebsite,
          companySize: values.companySize,
          facebook: values.facebook,
          linkedIn: values.linkedIn,
          twitter: values.twitter,
          banner: uploadBanner.url,
        };
        setCompanyData({ ...data });
      } else {
        const data: any = {
          title: values.companyName,
          description: companyDescription
            ? companyDescription
            : values.description,
          // confidentialdescription: companyConfidentialDescription
          //   ? companyConfidentialDescription
          //   : values.confidentialdescription,
          sector: values.sector,
          website: values.companyWebsite,
          companySize: values.companySize,
          facebook: values.facebook,
          logoUrl: uploadLogo.url,
          linkedIn: values.linkedIn,
          twitter: values.twitter,
          banner: uploadBanner.url,
        };
        setCompanyData({ ...data });
      }
    }
  };
  return (
    <>
      <PageTitle
        title={
          location.pathname.includes("edit")
            ? "Edit company"
            : "Create a new company"
        }
        to={
          location.pathname.includes("edit")
            ? `/companies/view/${params.companyId}`
            : undefined
        }
        back
      />
      <Row>
        <Col span={24}>
          <Card className="mb-4">
            <CompanyForm
              setCompanyDescription={setCompanyDescription}
              // setCompanyConfidentailDescription={
              //   setCompanyConfidentailDescription
              // }
              onFinish={onFinishHandler}
              setUploadLogo={setUploadLogo}
              uploadLogo={uploadLogo}
              company={company}
              sectors={sectors}
              imageData={(data: any) => setImageData(data)}
              isLoading={loading || uploadLogo.loading}
              hasError={hasError}
              setUploadBanner={setUploadBanner}
              uploadBanner={uploadBanner}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddCompany;
