import gql from "graphql-tag";

export const notificationSubscription = gql`
  subscription sendNewNotification {
    sendNewNotification {
      notificationForId
      latestNotification {
        _id
        userId
        companyId
        jobId
        matchId
        isRead
        type
        createdAt
        updatedAt
        notificationHeader
        notificationBody
        job {
          _id
          title
          company {
            _id
            name
          }
        }
        candidate {
          _id
          userId
          firstName
          lastName
          resume
          position
          profilePicture
          experiences {
            position
            company
            startYear
          }
          user {
            email
          }
        }
        tabActivity
      }
    }
  }
`;

export const fetchAllNotifications = gql`
  query getMyNotifications($userId: String) {
    getMyNotifications(userId: $userId) {
      _id
      userId
      companyId
      jobId
      matchId
      isRead
      type
      createdAt
      updatedAt
      notificationHeader
      notificationBody
      job {
        _id
        title
        company {
          _id
          name
        }
      }
      candidate {
        _id
        userId
        firstName
        lastName
        resume
        position
        profilePicture
        experiences {
          position
          company
          startYear
        }
        user {
          email
        }
      }
      tabActivity
    }
  }
`;

export const removeNotification = gql`
  mutation setNotificationToRead($notificationId: String) {
    setNotificationToRead(notificationId: $notificationId) {
      notificationId
    }
  }
`;

export const removeMultipleNotifications = gql`
  mutation setMultipleNotificationsToRead($notificationsArray: [String]) {
    setMultipleNotificationsToRead(notificationsArray: $notificationsArray) {
      message
    }
  }
`;

export const addRecruiterComment = gql`
  mutation sendRecruiterComment(
    $senderId: String
    $matchId: String
    $message: String
    $name: String
  ) {
    sendRecruiterComment(
      senderId: $senderId
      matchId: $matchId
      message: $message
      name: $name
    ) {
      jobId
    }
  }
`;
