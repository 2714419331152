import React, { useContext } from "react";
import { Typography } from "antd";
import { EditIcon } from "assets/icons/DetailedProfile";
import EditButton from "../EditIcon";
import AddButton from "../AddIcon";
import UVDButton from "../UVDIcon";

interface PropsType {
  title?: any;
  fontSize?: any;
  icon?: any;
  actionType?: any;
  actionHandler?: any;
  actionText?: any;
  fileLink?: any;
  showstyle: any;
  lastEndIcon?: any;
  isRecruiterPage?: boolean;
}

const Styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconStyle: {},
  titleStyle: {
    fontWeight: "600",
    marginLeft: 5,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  actionWrapper: {},
};

const SectionTitle: React.FC<PropsType> = ({
  title,
  fontSize,
  icon,
  actionType,
  actionHandler,
  actionText,
  fileLink,
  showstyle,
  isRecruiterPage,
  lastEndIcon,
}) => {
  return (
    <div style={Styles.root}>
      <div
        style={
          showstyle === true
            ? { display: "flex", alignItems: "center", marginLeft: "10px" }
            : Styles.flex
        }
      >
        {!isRecruiterPage && <img src={icon} style={Styles.iconStyle} />}
        <Typography
          style={{ ...Styles.titleStyle, fontSize: fontSize, color: "#012653" }}
        >
          {title}
        </Typography>
        <img src={lastEndIcon} style={{ ...Styles.iconStyle, marginLeft: 5 }} />
      </div>
      <div style={Styles.actionWrapper}>
        {actionType == "uvd" && <UVDButton fileLink={fileLink} />}
      </div>
    </div>
  );
};

export default SectionTitle;
