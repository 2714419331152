import React from "react";
import ReactDOM from "react-dom";
import { split } from "apollo-link";
import { Provider } from "react-redux";
import configureStore from "./store";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, ApolloLink, InMemoryCache } from "apollo-boost";
import { getMainDefinition } from "apollo-utilities";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { SubscriptionClient } from "subscriptions-transport-ws";
import "./index.scss";
import App from "./App";

const { store } = configureStore();

const GRAPHQL_ENDPOINT = process.env.REACT_APP_SUBSCRIPTION_URL;

const clientSocket = new SubscriptionClient(GRAPHQL_ENDPOINT, {
  reconnect: true,
});

const linkT = new WebSocketLink(clientSocket);

// Create an http link:
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

// // Create a WebSocket link:
// const wsLink = new WebSocketLink({
//   uri: `http://localhost:9001`,
//   options: {
//     reconnect: true,
//     lazy: true,
//     inactivityTimeout: 30000,
//   }
// });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem("accessToken");

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const link = split(
  // split based on operation type
  ({ query }) => {
    let definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  linkT,
  httpLink
);

const client = new ApolloClient({
  link: authLink.concat(link), // Chain it with the HttpLink
  cache: new InMemoryCache(),
});

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);

ReactDOM.render(app, document.getElementById("root"));
