import React, { useEffect, useState } from "react";
import "./NotificationDropdown.scss";
import { Link } from "react-router-dom";
import {
  Menu,
  Dropdown,
  Badge,
  Popconfirm,
  Checkbox,
  notification,
} from "antd";
import { useLazyQuery, useMutation, useSubscription } from "react-apollo";
import {
  fetchAllNotifications,
  notificationSubscription,
  removeMultipleNotifications,
  removeNotification,
} from "./notifications";
import firebase from "../../../../firebase";
import {
  StoreNotifications,
  AddNotification,
  DeleteNotification,
  DeleteAllNotifications,
} from "store/notifications/actions";
import { useDispatch, useSelector } from "react-redux";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import "../NotificationDropdown/index.css";
import moment from "moment";
interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
  candidate: any;
  job: any; // job shuld contain the data of companies as well
  tabActivity: string; // this key will be generated by the backend (depending where this job came from)
  // depending on the tabActive key we will choose the redux jobs we will send to the candidates.
}

interface DisplayNotification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
  candidate: any;
  job: any;
  tabActivity: string;
  checkbox: {
    id: string;
    value: boolean;
  };
}
notification.config({
  placement: "topLeft",
  duration: 3,
  rtl: false,
});
const NotificationDropdown: React.FC = () => {
  const stringifyUserObject: any = localStorage.getItem("user");
  const user = JSON.parse(stringifyUserObject);
  const { data, loading, error } = useSubscription(notificationSubscription); //subscription which is listening to server. whenever server publishes something, this data field is populated.
  const [count, setCount] = useState(0); //badge count

  // this is the query for fetching the notifications
  const [
    getNotificationsData,
    {
      data: notificationData,
      loading: notificationLoading,
      error: notificationError,
    },
  ] = useLazyQuery(fetchAllNotifications, {
    fetchPolicy: "network-only",
    onCompleted: (data: any) => {
      if (data?.getMyNotifications) {
        //saving to redux
        const notifications = data?.getMyNotifications;
        // const reversed = reverse(notificationData?.getMyNotifications);
        const reversed = notifications.sort(function compare(
          first: Notification,
          second: Notification
        ) {
          var dateA = first.createdAt;
          var dateB: any = second.createdAt;
          return dateB - dateA;
        });
        dispatch(StoreNotifications(reversed));
      }
    },
  });
  const getNotificationsOfUser = () => {
    getNotificationsData({
      variables: {
        userId: user?._id,
      },
    });
  };

  //redux will be our one source of truth
  const dispatch = useDispatch();
  const notificationsRdx = useSelector((state: any) => state.notifications);
  const [notifications, setNotifications] = useState([]); //local state that holds the notifications

  //first fetch call. We fetch the badge and all notifications and add them to redux.
  useEffect(() => {
    fetchNotificationBadge(); //first we update the badge, then we fetch the documents
    getNotificationsOfUser();
    return () => {};
  }, []);

  //whenever we get a publish from the backend this function runs and we check if the notification is for this user or not.
  //If it is for this user then we push the notification into the notifications array in redux
  useEffect(() => {
    if (data?.sendNewNotification?.notificationForId == user?._id) {
      fetchNotificationBadge();
      //here we will add notification to redux
      if (data?.sendNewNotification?.latestNotification) {
        dispatch(
          AddNotification(data?.sendNewNotification?.latestNotification)
        );
      }
    }
    return () => {};
  }, [data]);

  // one source of truth and that is the redux
  useEffect(() => {
    setNotifications(notificationsRdx?.notifications);
    return () => {};
  }, [notificationsRdx]);

  const fetchNotificationBadge = async () => {
    const query = firebase.collection("notificationsMain")?.doc(user?._id);
    const snapshot = await query.get();
    const notificationDocument = snapshot.data();
    if (notificationDocument) setCount(notificationDocument.badgeCount);
  };

  const resetBadgeCount = async (isOpen: boolean) => {
    if (isOpen) {
      try {
        const query = firebase.collection("notificationsMain").doc(user._id);
        await query.update({
          badgeCount: 0,
          notificationsData: [],
        });
        setCount(0);
      } catch (err) {
        console.log({ err });
      }
    }
  };

  //delete the notification
  const [setNotificationToRead, { data: any }] =
    useMutation(removeNotification);

  const deleteTheNotification = (notificationId: string) => {
    setNotificationToRead({
      variables: {
        notificationId: notificationId,
      },
    }).then((data: any) => {
      dispatch(DeleteNotification(notificationId));
    });
  };

  const getTheRightData = (tabActivity: string) => {
    return [];
    // switch (tabActivity) {
    //   case "1":
    //     return newCandidatesFromStore;
    //   case "2":
    //     return dislikedCandidatesFromStore;
    //   case "3":
    //     return likedCandidatesFromStore;
    //   case "4":
    //     return machesCandidatesFromStore;
    //   case "5":
    //     return selectedCandidatesFromStore;
    //   case "6":
    //     return rejectedCandidates;
    //   case "7":
    //     return hiredCandidates;
    //   default:
    //     break;
    // }
  };

  const cancelDelete = (e: React.MouseEvent<HTMLElement>) => {
    console.log("delete nothing");
  };

  const confirmDeleteOneNotification = (
    e: React.MouseEvent<HTMLElement>,
    notificationId: string
  ) => {
    deleteTheNotification(notificationId);
  };

  /* Notifications Dropdown check boxes and delete all functionality */
  const [hover, setHover] = useState(false);

  //we modified the notification object to incorporate checkboxes fuinctionality
  const [displayNotifications, setdisplayNotifications] = useState<
    DisplayNotification[]
  >([]);
  useEffect(() => {
    setdisplayNotifications(
      notifications.map((notif: Notification) => {
        const date = parseInt(notif.createdAt);
        return {
          ...notif,
          checkbox: { id: notif._id, value: false },
          // createdAt: moment(date).format("HH:mm"),
          createdAt: moment(date).startOf("hour").fromNow(),
        };
      })
    );
    return () => {};
  }, [notifications]);

  // this code block is for incorporating the checking unchecking of the checkboxes
  const [allSelected, setallSelected] = useState(false);
  const [notifyLength, setNotifyLength] = useState<any>([]);
  useEffect(() => {
    if (displayNotifications.length) {
      const areAllCheckBoxesSelected = displayNotifications.some(
        (notif) => notif.checkbox.value == false
      );
      setallSelected(!areAllCheckBoxesSelected);
    }
  }, [displayNotifications]);

  const selectNotification = (
    notificationId: string,
    e: CheckboxChangeEvent
  ) => {
    const updated: DisplayNotification[] = displayNotifications.map((notif) =>
      notif._id == notificationId
        ? { ...notif, checkbox: { id: notif._id, value: e.target.checked } }
        : notif
    );

    if (notifyLength.includes(notificationId)) {
      // If the notificationId already exists in notifyLength, filter it out
      setNotifyLength(notifyLength.filter((noti) => noti !== notificationId));
    } else {
      // Otherwise, add the notificationId to notifyLength
      setNotifyLength([...notifyLength, notificationId]);
    }
    setdisplayNotifications(updated);
  };

  const selectUnselectAllNotifications = (e: CheckboxChangeEvent) => {
    const items: DisplayNotification[] = displayNotifications.map((notif) => ({
      ...notif,
      checkbox: { id: notif._id, value: e.target.checked },
    }));
    console.log(items?.length);

    setdisplayNotifications(items);
    //for Button disable and enable
    const arrayOfId = items
      ?.filter((obj) => obj?.checkbox?.value)
      .map((obj) => obj._id);
    setNotifyLength(arrayOfId);
  };

  //delete multiple notifications

  const [setMultipleNotificationsToRead, { data: data1 }] = useMutation(
    removeMultipleNotifications
  );

  const deleteAllTheNotifications = () => {
    const keys = displayNotifications
      .filter((notif) => notif.checkbox.value === true)
      .map((notif) => notif._id);
    if (keys.length) {
      setMultipleNotificationsToRead({
        variables: {
          notificationsArray: [...keys],
        },
      })
        .then((data: any) => {
          notification["success"]({
            message: `Notifications removed successfully.`,
          });
          dispatch(DeleteAllNotifications(keys));
        })
        .catch((err) => {
          console.log("An error occured while deleting all notifications");
          console.log({ err });
        });
    } else {
      notification["error"]({
        message: "Please select the notifications you want to delete!",
      });
    }
  };

  // confirm dialog functionality
  const confirmDeleteAll = (e: React.MouseEvent<HTMLElement>) => {
    deleteAllTheNotifications();
  };

  const [hoveredElement, setHoveredElement] = useState("");

  /**** Menu item */
  const menu = (
    <span className="top-notification-dd">
      <Menu style={{ width: "410px", height: 500 }}>
        <div
          style={{
            justifyContent: "stretch",
            height: "100%",
            width: "100%",
            backgroundColor: "red",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#ffffff",
              // marginLeft: "20%",
              // marginRight: "20%",
              boxShadow: "",
              overflowY: "scroll",
            }}
          >
            {/** This is the element of the notifications header */}
            <div
              style={{
                paddingTop: 20,
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
                backgroundColor: "#ffffff",
                top: 0,
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Checkbox
                  style={{ width: 22, height: 22, marginLeft: 10 }}
                  checked={allSelected}
                  onChange={selectUnselectAllNotifications}
                />
                <div style={{ marginLeft: 20 }}>
                  <p
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "20px",
                      lineHeight: "24px",
                    }}
                  >
                    Notifications
                  </p>
                </div>
              </div>
              <div style={{ marginRight: 20 }} className="popUpDelte">
                <div
                  style={
                    hover
                      ? {
                          backgroundColor:
                            notifyLength?.length > 0 || allSelected
                              ? "#990000"
                              : "C4C4C4",
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingRight: 16,
                          paddingLeft: 16,
                        }
                      : {
                          backgroundColor:
                            notifyLength?.length > 0 || allSelected
                              ? "#990000"
                              : "rgba(153, 0, 0, 0.25)",
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingRight: 16,
                          paddingLeft: 16,
                        }
                  }
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <div className="pop-up-all">
                    {notifyLength?.length > 0 || allSelected ? (
                      <Popconfirm
                        className="Pop_Up_For_Delete"
                        title="Do you want to delete the selected notifications?"
                        onConfirm={confirmDeleteAll}
                        onCancel={cancelDelete}
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                      >
                        <span style={{ color: "white", cursor: "pointer" }}>
                          Delete
                        </span>
                        <img
                          style={{ height: "12px", marginLeft: 8 }}
                          src={require("../../../../assets/icons/icons/delete-all.png")}
                        />
                      </Popconfirm>
                    ) : (
                      <>
                        <span style={{ color: "white", cursor: "disabled" }}>
                          Delete all
                        </span>
                        <img
                          style={{ height: "12px", marginLeft: 8 }}
                          src={require("../../../../assets/icons/icons/delete-all.png")}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              {displayNotifications?.length ? (
                displayNotifications?.map((notif: DisplayNotification) => (
                  <div
                    key={notif._id}
                    style={
                      hoveredElement == notif._id
                        ? { backgroundColor: "#F2F2F2" }
                        : {}
                    }
                    onMouseEnter={() => setHoveredElement(notif._id)}
                    onMouseLeave={() => setHoveredElement("")}
                  >
                    {notif?.type === "chat" ||
                    notif?.type === "recruiter_comment" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          columnGap: "16px",
                          padding: "10px",
                        }}
                      >
                        <Checkbox
                          style={{ width: 22, height: 22 }}
                          className="my-checkbox"
                          checked={notif.checkbox.value}
                          onChange={(e) => selectNotification(notif._id, e)}
                        />

                        <Link
                          style={{ width: "100%" }}
                          to={{
                            pathname: `/candidate-listings/view/${notif?.candidate?._id}`,
                            state: {
                              company: notif.job?.company,
                              matchId: notif?.matchId,
                              from: getTheRightData(notif?.tabActivity),
                              jobId: notif?.jobId,
                              jobName: notif?.job?.title,
                              companyName: notif?.job?.company?.name,
                              tabActiveKey: notif?.tabActivity,
                            },
                          }}
                          onClick={
                            notif?.type === "recruiter_comment"
                              ? () => deleteTheNotification(notif._id)
                              : () =>
                                  console.log(
                                    "dont delete the chat notificatio"
                                  )
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "20px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {notif?.type == "chat" ? (
                                <img
                                  style={{ width: 35, height: 35 }}
                                  src={require("../../../../assets/icons/icons/chat-notify.svg")}
                                />
                              ) : (
                                <img
                                  style={{ width: 35, height: 35 }}
                                  src={require("../../../../assets/icons/icons/comment-notif.png")}
                                />
                              )}
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Lato",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "19.2px",
                                    color: "#000000",
                                  }}
                                >
                                  {notif?.notificationHeader}{" "}
                                </span>
                                <span
                                  style={{
                                    fontFamily: "Lato",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                    lineHeight: "16.8px",
                                    color: "#495057",
                                  }}
                                >
                                  {notif?.notificationBody}
                                </span>
                                <p
                                  style={{
                                    font: "Lato",
                                    fontWeight: "600",
                                    fontSize: "10px",
                                    lineHeight: "12px",
                                    color: "#000000",
                                    opacity: 0.25,
                                  }}
                                >
                                  {notif.createdAt}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Popconfirm
                            title="Are you sure to delete this notification?"
                            onConfirm={(e) =>
                              confirmDeleteOneNotification(e, notif._id)
                            }
                            onCancel={cancelDelete}
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                          >
                            <div style={{ alignSelf: "flex-end" }}>
                              <img
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  opacity: 0.25,
                                }}
                                src={require("../../../../assets/icons/profileIcons/cross.png")}
                              />
                            </div>
                          </Popconfirm>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          columnGap: "16px",
                          padding: "10px",
                        }}
                      >
                        <Checkbox
                          style={{ width: 22, height: 22 }}
                          checked={notif.checkbox.value}
                          onChange={(e) => selectNotification(notif._id, e)}
                        />

                        <Link
                          style={{ width: "100%" }}
                          to={{
                            pathname: `/candidate-listings/${notif?.jobId}`,
                            // search: "?notification=true",
                            state: { tabActiveKey: notif.tabActivity },
                          }}
                          onClick={() => {
                            window.location.href = `/candidate-listings/${notif?.jobId}`;
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "20px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <img
                                style={{ width: 36, height: 36 }}
                                src={require("../../../../assets/icons/icons/match-notify.svg")}
                              />
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Lato",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "19.2px",
                                    color: "#000000",
                                  }}
                                >
                                  {notif?.notificationHeader}{" "}
                                </span>
                                <span
                                  style={{
                                    fontFamily: "Lato",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "16.8px",
                                    color: "#495057",
                                  }}
                                >
                                  {notif?.notificationBody}
                                </span>
                                <p
                                  style={{
                                    font: "Lato",
                                    fontWeight: "600",
                                    fontSize: "10px",
                                    lineHeight: "12px",
                                    color: "#000000",
                                    opacity: 0.25,
                                  }}
                                >
                                  {notif.createdAt}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Popconfirm
                            title="Do you want to delete this notification?"
                            onConfirm={(e) =>
                              confirmDeleteOneNotification(e, notif._id)
                            }
                            onCancel={cancelDelete}
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                          >
                            <div style={{ alignSelf: "center" }}>
                              <img
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  opacity: 0.25,
                                }}
                                src={require("../../../../assets/icons/profileIcons/cross.png")}
                              />
                            </div>
                          </Popconfirm>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80%",
                  }}
                >
                  <p
                    style={{
                      fontStyle: "Lato",
                      fontWeight: "700",
                      fontSize: "20px",
                      lineHeight: "24px",
                      color: "#0000004",
                      opacity: 0.25,
                    }}
                  >
                    No Notification
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <Menu.Item>
        <Link
          to={{
            pathname: `/notifications`,
          }}
        >
          <p style={{ textAlign: "center", alignItems: "center" }}>View All</p>
        </Link>
      </Menu.Item> */}
      </Menu>
    </span>
  );

  const menuNoItem = (
    <Menu style={{ width: "410px", height: 500 }}>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          backgroundColor: "#ffffff",
          top: 0,
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ marginLeft: 20 }}>
            <p
              style={{
                color: "#000000",
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Notifications ({notifications.length})
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80%",
        }}
      >
        <p
          style={{
            fontStyle: "Lato",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#0000004",
            opacity: 0.25,
          }}
        >
          No Notification
        </p>
      </div>
    </Menu>
  );

  return (
    <span className="top-bar-notification-dropdown">
      {!!notifications.length ? (
        <Dropdown
          trigger={["click"]}
          onVisibleChange={(isOpen) => resetBadgeCount(isOpen)}
          overlay={menu}
        >
          <span style={{ float: "right", cursor: "pointer" }}>
            <Badge count={count} className="burgundy">
              <img
                style={{
                  marginLeft: "28px",
                  marginBottom: "8px",
                  width: "24px",
                  height: "24px",
                }}
                src={require("../../../../assets/icons/profilesvgs/notify.svg")}
                alt="Notifications"
              />
              <span className="fs-top-bar" style={{ color: "#495057" }}>
                Notifications
              </span>
            </Badge>
          </span>
        </Dropdown>
      ) : (
        <Dropdown
          trigger={["click"]}
          onVisibleChange={(isOpen) => resetBadgeCount(isOpen)}
          overlay={menuNoItem}
        >
          <span style={{ float: "right", cursor: "pointer" }}>
            <Badge count={count} className="burgundy">
              <img
                style={{
                  marginLeft: "28px",
                  marginBottom: "8px",
                  width: "24px",
                  height: "24px",
                }}
                src={require("../../../../assets/icons/profilesvgs/notify.svg")}
                alt="Notifications"
              />
              <span className="fs-top-bar">Notifications</span>
            </Badge>
          </span>
        </Dropdown>
      )}
    </span>
  );
};

export default NotificationDropdown;
