import { FlagIcon } from "assets/icons/DetailedProfile";
import React, { useEffect, useState } from "react";
import { SectionTitle } from "../../Shared";
import FileViewer from "react-file-viewer";
import "./Resume.scss";
interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #BC9623",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 10,
  },
  fileWrapper: {
    height: 600,
    border: "solid 1px #ececec",
    marginBottom: 10,
  },
};

const Resume: React.FC<PropsType> = ({ candidate }) => {
  const [docs, setDocs] = useState<any>([]);

  const [fileAdded, setFileAdded] = useState(true);

  // function get_url_extension(url: string) {
  //   return url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
  // }

  useEffect(() => {
    setFileAdded(false);
    setTimeout(() => {
      setFileAdded(true);
    }, 1000);
  }, [candidate?.resume]);
  return (
    <>
      {candidate?.resume != null && candidate?.resume != "" && (
        <div style={Styles.root}>
          <SectionTitle
            fileLink={candidate?.resume}
            title="Resume"
            fontSize="16px"
            actionType="uvd"
            showstyle={false}
          />
          <div className="resume-preview">
            <div style={Styles.body}>
              <div style={Styles.fileWrapper}>
                {fileAdded && candidate?.resume && (
                  <iframe
                    src={
                      candidate?.resume?.includes(".pdf")
                        ? candidate?.resume +
                          "#toolbar=0&scrollbar=0&view=fitH&view=fitV"
                        : `https://docs.google.com/gview?url=${candidate?.resume}&embedded=true`
                    }
                    scrolling="auto"
                    width="100%"
                    height="100%"
                    allowFullScreen={true}
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Resume;
