import React, { useEffect, useState } from "react";
import {
  Drawer,
  Input,
  Form,
  Button,
  Divider,
  Col,
  Row,
  Upload,
  message,
  Modal,
} from "antd";
import ImgCrop from "antd-img-crop";
import uploadSvg from "../../../../assets/icons/icons/upload.svg";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { compressImage } from "utils";
const { TextArea } = Input;
interface Props {
  show: boolean;
  hide: any;
  fullName: any;
  setFullName: any;
  title: any;
  settitle: any;
  loading: any;
  updateRecruiter: any;
  recruiter: any;
}

const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

const GeneralFormDrawer: React.FC<Props> = ({
  show,
  hide,
  fullName,
  setFullName,
  title,
  settitle,
  loading,
  updateRecruiter,
  recruiter,
}) => {
  const [data, setData] = useState<any>({});
  const [imgLoad, setImgLoad] = useState(false);
  const [fileListBanner, setFileListBanner] = useState<any>([]);
  const [form] = Form.useForm();
  const [imagePreviewBanner, setImagePreviewBanner] = useState("");
  const [imagePreviewModelBanner, setImagePreviewModalBanner] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [uploadBanner, setUploadBanner] = useState<any>({});
  const [videoPreview, setVideoPreview] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string>();

  // console.log('FullName: ', fullName)

  useEffect(() => {
    setIsDisabled(true);
    form.resetFields();
    setFileListBanner([
      {
        uid: recruiter?._id,
        name: "No image",
        status: "done",
        url: recruiter?.coverPhoto ? recruiter?.coverPhoto : "",
      },
    ]);
    setImagePreviewBanner(recruiter?.coverPhoto);
  }, [recruiter?.coverPhoto, show]);

  useEffect(() => {
    setVideoUrl("");
    if (recruiter?.profileVideo) setVideoUrl(recruiter?.profileVideo);
  }, [recruiter]);

  const handleChange = (e) => {
    setIsDisabled(false);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const beforeUpload = (file: any) => {
    setImgLoad(true);
    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
      "image/svg+xml",
    ];
    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error("You can only upload gif, jpeg, png, svg file!");
    }

    return isImg;
  };

  const handleOnChangeBanner = async (e: any) => {
    setImgLoad(true);

    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
      "image/svg+xml",
    ];

    const isImg = img.includes(e.file.type);

    if (isImg) {
      try {
        const compressedFile = await compressImage(e.file.originFileObj);

        const updatedFileList = e.fileList.map((file: any) =>
          file.uid === e.file.uid
            ? { ...file, originFileObj: compressedFile }
            : file
        );

        setFileListBanner(updatedFileList);
        form.setFieldsValue({
          jobBanner: updatedFileList,
        });


        if (e.file.status === "done") {
          setIsDisabled(false);
          setImagePreviewBanner(e.file.response.secure_url);
          setUploadBanner({
            ...uploadBanner,
            loading: false,
            url: e.file.response.secure_url,
          });
          setData((s) => ({ ...s, coverPhoto: e.file.response.secure_url }));
          console.log(uploadBanner, "--banner");
        }
      } catch (error) {
        console.error("Image compression failed:", error);

        form.setFieldsValue({
          coverPhoto: e.file.response.secure_url,
        });
        setData((s) => ({ ...s, coverPhoto: e.file.response.secure_url }));

      }
    } else {
      setFileListBanner([]);
      form.setFieldsValue({
        coverPhoto: null,
      });
    }

    // setDefaultFileListBanner(e.fileList);
  };

  const onPreviewBanner = async (file: any) => {
    setImagePreviewModalBanner(true);
  };

  const onRemoveBanner = (file: any) => {
    // console.log(file);
    if (file) {
      setFileListBanner([]);
      setUploadBanner(null);
    }
  };

  const validateUrl = (_: any, value: string) => {
    if (value && !value.startsWith("https://")) {
      return Promise.reject('URL must start with "https://"');
    }
    return Promise.resolve();
  };

  const uploadButton = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ fontSize: 18 }} />
      )}
      <div style={{ marginLeft: 10, fontSize: 16 }}>Upload logo</div>
    </div>
  );

  const videoCancel = () => setVideoPreview(false);

  const videoProps = {
    name: "file",
    multiple: true,
    accept: "video/mp4, video/mkv, video/mov, video/webm",
    action: `${process.env.REACT_APP_BASE_REST_URL}/upload/recrutier/${recruiter?._id}`,
    onChange(info: any) {
      const { status } = info.file;
      if (status) {
        if (status !== "uploading") {
          setVideoUrl(info?.file.response.secure_url);
          setIsDisabled(false);
          setData((s) => ({
            ...s,
            profileVideo: info?.file.response.secure_url,
          }));
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
          setUploadBanner({
            ...uploadBanner,
            loading: false,
            url: info.file.response.secure_url,
          });
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    beforeUpload(file: any) {
      if (file.size > MAX_VIDEO_SIZE) {
        message.error("File size exceeds the limit (10MB)!");
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      const img = ["video/mp4", "video/mov", "video/mkv", "video/webm"];
      const isImg = img.includes(file.type);
      if (!isImg) {
        setImgLoad(false);
        message.error("You can only upload video file!");
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      return true;
    },
  };

  return (
    <>
      <Drawer
        title="Recruiter's General Info"
        placement="right"
        closable={true}
        onClose={() => {
          form.resetFields();
          if (recruiter?.profileVideo) setVideoUrl(recruiter?.profileVideo);
          hide();
        }}
        visible={show}
        width={500}
        className="menu-drawer"
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          justify="center"
          className="pt-4 m-0"
        >
          <Col md={22}>
            <Form layout="vertical" name="recruiter-general-info" form={form}>
              <Form.Item
                label="Full name"
                name="fullname"
                initialValue={fullName}
                rules={[
                  {
                    required: true,
                    message: "Please input  full name!",
                  },
                ]}
              >
                <Input onChange={handleChange} name="fullName" />
              </Form.Item>
              <Form.Item
                label="Title"
                name="title"
                initialValue={title}
                rules={[
                  {
                    required: true,
                    message: "Please input  title!",
                  },
                ]}
              >
                <Input onChange={handleChange} name="title" />
              </Form.Item>
              <Form.Item
                label="Facebook"
                name="facebook"
                initialValue={recruiter?.facebook}
                rules={[
                  {
                    required: true,
                    message: "Please input facebook url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="facebook" />
              </Form.Item>
              <Form.Item
                label="Twitter"
                name="twitter"
                initialValue={recruiter?.twitter}
                rules={[
                  {
                    required: true,
                    message: "Please input twitter url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="twitter" />
              </Form.Item>
              {/* <Form.Item
                label="Website"
                name="website"
                initialValue="www.website.com"
                rules={[
                  {
                    required: true,
                    message: 'Please input website url!',
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                label="LinkedIn"
                name="linkedin"
                initialValue={recruiter?.linkedIn}
                rules={[
                  {
                    required: true,
                    message: "Please input linkedin url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="linkedIn" />
              </Form.Item>
              <Form.Item
                label="github"
                name="github"
                initialValue={recruiter?.github}
                rules={[
                  {
                    required: false,
                    message: "Please input github url!",
                  },
                  {
                    validator: validateUrl,
                  },
                ]}
              >
                <Input onChange={handleChange} name="github" />
              </Form.Item>
              <Form.Item
                name="coverPhoto"
                label="Cover Photo"
                initialValue={recruiter?.coverPhoto || uploadBanner?.url}
                rules={[
                  {
                    required: true,
                    message: "Upload the cover photo",
                  },
                ]}
                validateStatus={
                  fileListBanner.length >= 1 ? "success" : "error"
                }
              >
                {/* <ImgCrop
                  beforeCrop={beforeUpload}
                  rotate
                  grid
                  aspect={3 / 1}
                  minZoom={0}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                > */}
                <ImgCrop beforeCrop={beforeUpload} rotate grid>
                  <Upload
                    accept="image/*"
                    // customRequest={uploadImageBanner}
                    beforeUpload={beforeUpload}
                    onChange={handleOnChangeBanner}
                    listType="picture-card"
                    onPreview={onPreviewBanner}
                    onRemove={onRemoveBanner}
                    action={`${process.env.REACT_APP_BASE_REST_URL}/upload/recrutier/${recruiter?._id}`}
                    data={{
                      upload_preset: "cqdbrmkw",
                    }}
                    fileList={fileListBanner}
                    className="image-upload-grid"
                  >
                    {fileListBanner.length >= 1 ? null : (
                      <div className="fonts_weight">
                        <span className="fonts_weight_bold">
                          <img src={uploadSvg} alt="+" />
                          <br /> Click to upload
                        </span>{" "}
                        or drag and drop <br />
                        Authorized formats: PNG, JPG, or GIF - max size 2MB min
                        256KB
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>

              <div style={{ marginTop: "15px" }} className="pf-video">
                <Form.Item name="profileVideo" label="Profile Video">
                  {videoUrl && (
                    <>
                      {videoUrl || recruiter?.profileVideo ? (
                        <>
                          <div
                            className="hover-video"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <video
                              width={220}
                              height={140}
                              style={{ borderRadius: "10px" }}
                              // controls
                            >
                              <source src={`${videoUrl}`} />
                            </video>
                            <span
                              className="video-view-icon z-2"
                              style={{ zIndex: 2 }}
                              onClick={() => setVideoPreview(true)}
                            ></span>
                            <span
                              className="video-delete-icon z-2"
                              style={{ zIndex: 2 }}
                              onClick={() => {
                                setVideoUrl("");
                                setData((s) => ({ ...s, profileVideo: "" }));
                                setIsDisabled(false);
                              }}
                            ></span>
                            <div className="image-overlay"></div>
                          </div>
                        </>
                      ) : (
                        uploadButton
                      )}
                    </>
                  )}
                  <Modal
                    open={videoPreview}
                    title={"My video"}
                    footer={null}
                    onCancel={videoCancel}
                  >
                    <video width={470} height={240} controls>
                      <source src={videoUrl} />
                    </video>
                  </Modal>
                  {(videoUrl == "" || videoUrl == null) && (
                    <Dragger
                      {...videoProps}
                      data={{
                        upload_preset: "taz3l4yj",
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <img src={uploadSvg} alt="" />
                      </p>
                      <p className="large-text-drawer-color">
                        Upload your video
                      </p>
                      <p className="small-text-drawer-color">
                        Max size 10MB, Authorized formats: mp4, mov, mkv and
                        webm.
                      </p>
                    </Dragger>
                  )}
                </Form.Item>
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  // htmlType="submit"
                  className="ant-btn-teal float-right"
                  shape="round"
                  size="middle"
                  disabled={isDisabled}
                  loading={loading}
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => {
                        updateRecruiter(data);
                      })
                      .catch((error) => {
                        console.error("Form validation failed", error);
                      });
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
              <Modal
                className="preview-modal"
                centered
                visible={imagePreviewModelBanner}
                title={"Preview image"}
                footer={null}
                onCancel={() => {
                  setImagePreviewModalBanner(false);
                }}
              >
                <img
                  alt="example"
                  style={{ width: "100%", height: 475 }}
                  src={imagePreviewBanner}
                />
              </Modal>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default GeneralFormDrawer;
// 6d484a9
