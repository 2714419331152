import React from "react";
import { Avatar } from "antd";

interface PropsType {
  experience: any;
  isRecruiterPage: boolean;
}

const convertToYear = (year: string | null): number => {
  if (!year) return new Date().getFullYear();
  if (year === "Present") return new Date().getFullYear();
  return parseInt(year.split("-")[0], 10);
};

const calculateTotalYearsSpent = (experience: any): any => {
  const experiences = [experience, ...(experience.mergedData || [])];
  experiences.sort(
    (a: any, b: any) => convertToYear(a.startYear) - convertToYear(b.startYear)
  );

  const earliestStartYear = convertToYear(experiences[0].startYear);
  const latestEndYear = convertToYear(
    experiences[experiences.length - 1].endYear || "Present"
  );

  let val = latestEndYear - earliestStartYear;
  return val == 0 ? "" : val;
};

const SingleExperience: React.FC<PropsType> = ({
  experience,
  isRecruiterPage,
}) => {
  const Styles = {
    root: {
      paddingLeft: isRecruiterPage ? 4 : 0,
      display: "inline-block",
      maxWidth: 400,
      flex: "85%",
    },
    wrapper: {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      marginBottom: 26,
      marginTop: "-8px",
    },
    year: {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    position: {
      fontWeight: 500,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "black",
    },
    company: {
      fontWeight: 600,
      fontSize: 16,
      marginTop: 0,
      marginBottom: 0,
      color: "black",
    },
    location: {
      fontWeight: 400,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    avatar: {
      backgroundColor: "#F5F7F9",
    },
    totalYears: {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgba(0,0,0,.5)",
      marginTop: "-1px",
    },
  };

  const totalYearsSpent: any = calculateTotalYearsSpent(experience);

  if (experience?.current) {
    experience.endYear = "Present";
  }

  if (experience?.mergedData) {
    experience.mergedData = experience.mergedData.sort((s: any) =>
      s?.current ? -1 : 1
    );
  }

  return (
    <div style={Styles.wrapper}>
      <div style={{ ...Styles.root, wordWrap: "break-word" }}>
        <div style={{ display: "flex", justifyContent: "start", gap: "16px" }}>
          <Avatar
            style={{
              ...Styles.avatar,
              backgroundImage: `url(${experience?.logo})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3px",
              flexShrink: "0",
            }}
            size={isRecruiterPage ? 66 : 48}
          />
          <div style={{ marginLeft: "-6px" }}>
            <p style={Styles.company}>{experience?.company}</p>
            {!experience?.mergedData && (
              <>
                <p style={Styles.position}>{experience?.position}</p>
                <div className="">
                  <span
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {experience.startYear} {experience.endYear && "-"}{" "}
                    {experience?.endYear}
                  </span>
                  &nbsp;&nbsp;
                  <span
                    style={{
                      color: "#424952",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {experience?.location?.city && experience?.location?.country
                      ? `${experience?.location?.city}, ${experience?.location?.country}`
                      : experience?.location?.city &&
                        !experience?.location?.country
                      ? ` | ${experience?.location?.city}`
                      : ""}
                  </span>
                </div>
              </>
            )}
            {totalYearsSpent && experience?.mergedData && (
              <p style={Styles.totalYears}>{totalYearsSpent} years</p>
            )}

            {experience?.mergedData?.length > 0 &&
              experience.mergedData.map((d: any, i: any) => {
                if (d?.current) {
                  d.endYear = "Present";
                }
                if (d.position !== experience.position || true) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "20px",
                        marginLeft: "-40px",
                      }}
                      key={i}
                    >
                      <div>
                        <div
                          style={{
                            height: "9px",
                            width: "9px",
                            backgroundColor: "#418394",
                            borderRadius: "100%",
                          }}
                        />
                        <div
                          style={{
                            height: "36px",
                            width: "1.2px",
                            backgroundColor: "#418394",
                            marginTop: "-4px",
                            marginBottom: "-4px",
                            marginLeft: "4.1px",
                          }}
                        />
                        <div
                          style={{
                            height: "9px",
                            width: "9px",
                            backgroundColor: "#418394",
                            borderRadius: "100%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                          marginTop: "1px",
                          marginLeft: "16px",
                        }}
                      >
                        <div style={Styles.position}>{d.position}</div>
                        <div className="">
                          <span
                            style={{
                              color: "rgba(0,0,0,0.5)",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {d.startYear} - {d.endYear}
                          </span>
                          &nbsp;&nbsp;
                          <span
                            style={{
                              color: "#424952",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {d?.location?.city && d?.location?.country
                              ? `${d?.location?.city}, ${d?.location?.country}`
                              : d?.location?.city && !d?.location?.country
                              ? ` | ${d?.location?.city}`
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
};

export default SingleExperience;
