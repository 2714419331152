import * as React from "react";

export const TabActvContext = React.createContext<any | null>({});

function TabActvContextProvider(props: any) {
  const [tabActiveKey, setTabActiveKey] = React.useState<string>("1");
  const [searchData, setSearchData] = React.useState([]);
  const [companyData, setCompanyData] = React.useState({});

  return (
    <TabActvContext.Provider
      value={{
        tabActiveKey,
        setTabActiveKey,
        searchData,
        setSearchData,
        companyData,
        setCompanyData,
      }}
    >
      {props.children}
    </TabActvContext.Provider>
  );
}
export default TabActvContextProvider;
