import React, { useState } from "react";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";

import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Space,
} from "antd";

import { Fade } from "react-awesome-reveal";
import AddSkillsTable from "./partials/AddSkillsTable";
const { Option } = Select;

interface Props {}

interface Section {
  key: string;
  sectionName: JSX.Element;
}

const ManageAssessment: React.FC<Props> = () => {
  const [hardSkills, setHardSkill] = useState<Section[]>([]);
  const [softSkills, setSoftSkills] = useState<Section[]>([]);
  const [modalHardSkillVisible, setModalHardSkillVisible] = useState(false);
  const [modalSoftSkillVisible, setModalSoftSkillVisible] = useState(false);

  const noSection = (
    <Card>
      <p className="text-center p-4 my-4">No section added yet.</p>
    </Card>
  );

  const handleSoftSkillSection = (values: any) => {
    const newData = {
      key: values.section,
      sectionName: <AddSkillsTable name={values.section} />,
    };
    setSoftSkills([...softSkills, newData]);
    setModalSoftSkillVisible(false);
  };

  const handleHardSkillSection = (values: any) => {
    const newData = {
      key: values.section,
      sectionName: <AddSkillsTable name={values.section} />,
    };
    setHardSkill([...hardSkills, newData]);
    setModalHardSkillVisible(false);
  };

  return (
    <>
      <PageTitle
        back
        title="Manage Assessments"
        btnActive={true}
        to="#"
        btnText="Tutorial"
      />
      <Row justify="center" className="pb-4" gutter={24}>
        <Col span={12}>
          <Fade>
            <Row justify="center">
              <Col span={16}>
                <h2 className=" pl-2 font-18">Hard skills</h2>
              </Col>
              <Col span={8}>
                <div className="text-right">
                  <Button
                    className="ant-btn-default"
                    htmlType="submit"
                    shape="round"
                    size="small"
                    onClick={() => setModalHardSkillVisible(true)}
                  >
                    Add Section
                  </Button>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                {hardSkills.length !== 0
                  ? hardSkills.map((sec) => sec.sectionName)
                  : noSection}
              </Col>
            </Row>
          </Fade>
        </Col>
        <Col span={12}>
          <Fade>
            <Row justify="center">
              <Col span={16}>
                <h2 className="font-18">Soft skills</h2>
              </Col>
              <Col span={8}>
                <div className="text-right">
                  <Button
                    className="ant-btn-default"
                    htmlType="submit"
                    shape="round"
                    size="small"
                    onClick={() => setModalSoftSkillVisible(true)}
                  >
                    Add Section
                  </Button>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                {softSkills.length !== 0
                  ? softSkills.map((sec) => sec.sectionName)
                  : noSection}
              </Col>
            </Row>
          </Fade>
        </Col>
      </Row>
      {/* softskills modal */}
      <Modal
        title="Add soft skill's section"
        visible={modalSoftSkillVisible}
        onCancel={() => setModalSoftSkillVisible(false)}
        footer={null}
      >
        <div>
          <Form
            name="softskill_section"
            className="softskill_section"
            layout="vertical"
            // onFieldsChange={() => setHasError(false)}
            onFinish={handleSoftSkillSection}
          >
            <Form.Item
              name="section"
              label="Section"
              rules={[
                {
                  required: true,
                  message: "Please enter section name!",
                },
              ]}
            >
              <Input type="text" placeholder="Enter section" />
            </Form.Item>

            <div className="text-right">
              <Space>
                <Button
                  className="ant-btn-default"
                  shape="round"
                  onClick={() => setModalSoftSkillVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="ant-btn-primary"
                  htmlType="submit"
                  shape="round"
                >
                  Add section
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </Modal>
      {/* hardskills modal */}
      <Modal
        title="Add hard skill's section"
        visible={modalHardSkillVisible}
        onCancel={() => setModalHardSkillVisible(false)}
        footer={null}
      >
        <div>
          <Form
            name="hardskill_section"
            className="hardskill_section"
            layout="vertical"
            // onFieldsChange={() => setHasError(false)}
            onFinish={handleHardSkillSection}
          >
            <Form.Item
              name="section"
              label="Section"
              rules={[
                {
                  required: true,
                  message: "Please enter section name!",
                },
              ]}
            >
              <Input type="text" placeholder="Enter section" />
            </Form.Item>

            <div className="text-right">
              <Space>
                <Button
                  className="ant-btn-default"
                  shape="round"
                  onClick={() => setModalHardSkillVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="ant-btn-primary"
                  htmlType="submit"
                  shape="round"
                >
                  Add section
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ManageAssessment;
