import React from "react";
import Logo from "../UI/Logo/Logo";
import "./TopBar.scss";
import { Link, useLocation } from "react-router-dom";
import NotificationDropdown from "./partials/NotificationDropdown/NotificationDropdown";
import ProfileDropdown from "./partials/ProfileDropdown/ProfileDropdown";
import { Menu, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSidbarExpand } from "../../store/settings/actions";
import { GeneralStates } from "../../store/settings/types";

const TopBar: React.FC = () => {
  const { pathname } = useLocation();

  const { sidebarExpanded } = useSelector(
    (state: GeneralStates) => state.settings
  );

  const dispatch = useDispatch();
  const toggleSidebar = () => dispatch(setSidbarExpand(!sidebarExpanded));
  return (
    <>
      <div className="topbar">
        <div className="topbar-left">
          <Link to="/companies" className="logo">
            <i>
              <img
                src="https://res.cloudinary.com/blonk-group/image/upload/v1676550863/blonk/logo/Asset_5_1.png"
                alt=""
                height="22"
              />
            </i>
          </Link>
        </div>
        <Menu className="navbar-custom" mode="horizontal">
          <Menu.Item className="button-menu-mobile" onClick={toggleSidebar}>
            <i className="mdi mdi-menu"></i>
          </Menu.Item>
          <Menu.Item className="logo">
            <Logo height="25" />
          </Menu.Item>

          <Menu.Item style={{ float: "right" }} className="modified-item">
            <Link
              to={{ pathname: `/job-listings/add-job`, state: "clearFields" }}
            >
              <Button
                type="primary"
                shape="round"
                size="middle"
                style={{ fontSize: "14px" }}
              >
                Post a new job
              </Button>
            </Link>
          </Menu.Item>
          <NotificationDropdown />
          <ProfileDropdown />
        </Menu>
      </div>
    </>
  );
};

export default TopBar;
