import React from "react";
import { Row, Col, Typography, Avatar, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
const { Title, Paragraph } = Typography;

interface JobDescriptionProps {}

const JobDescription: React.FC<JobDescriptionProps> = () => {
  return (
    <>
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <Title
            level={3}
            style={{ marginBottom: "0px" }}
            className="text-navy"
          >
            Trainers in Information and Communication Technologies
            <Tooltip placement="top" title="Edit summary">
              <span
                className="ml-2 text-burgundy clickable-span font-18"
                //   onClick={() => setDisplaySummaryDrawer(true)}
              >
                <i className="fas fa-edit" />
              </span>
            </Tooltip>
          </Title>
          <div className="companyConatiner pt-2 pb-4 border-bottom-grey">
            <Avatar size={32} icon={<UserOutlined />} />

            <span className="pl-1 font-16 font-500 text-black">Blonk</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Paragraph>
            As an integral part of Dathena’s Sales &amp; Operations Team, you
            are responsible for leading and managing the delivery of Dathena's
            client-facing engagements, managing the customer relationship and
            coordinating teams across Dathena, the customer and third-parties
            involved in the implementation of our solutions. You act as a
            trusted subject matter expert to our partners and clients to ensure
            they realise the full value of their investment in Dathena's
            products.
            <div className="job-description">
              <h5 className="py-1">Responsibilities</h5>
              <strong>
                <u>
                  1. Project management: Solutions deployment &amp; operation{" "}
                </u>
              </strong>
              <p className="pt-2">
                You manage the end to end deployment and ongoing operation of
                Dathena's projects to ensure timely delivery to the customer and
                smooth ongoing operations:
              </p>
              <ul>
                <li>
                  Pre-implementation information gathering, deployment planning,
                  interfacing with customer teams, monitoring implementation and
                  ensuring timely delivery &amp; customer satisfaction.{" "}
                </li>
                <li>
                  Manage &amp; coordinate delivery resources (internal team
                  members as well as customer and third-parties such as systems
                  integrators, professional services firms etc.)
                </li>
                <li>
                  Ensure key client stakeholders are aligned and focused on
                  driving Dathena’s implementation to completion
                </li>
              </ul>
              <div className="pt-2"></div>
              <strong>
                <u>2. Sales &amp; account management</u>
              </strong>
              <p className="pt-2">
                Identify upsell opportunities in customer accounts:
              </p>
              <ul>
                <li>
                  Engage with clients in “consultative selling” mode at CxO
                  levels to determine new pain points and use cases that Dathena
                  can address.
                </li>
                <li>
                  Act as a trusted business matter expert in Dathena’s solutions
                  by constantly providing effective operational guidance around
                  our technologies to customers and partners, and encourage
                  optimal utilisation.
                </li>
                <li>
                  Help channel partners in their day-to-day sales activities and
                  delivering innovative and scalable solutions to meet their
                  business needs.
                </li>
              </ul>
              <div className="pt-2"></div>
              <strong>
                <u>3. Drive Product &amp; Services Improvement</u>
              </strong>
              <p className="pt-2">
                Help improve Dathena’s products &amp; services by channelling
                structured feedback to the Product &amp; R&amp;D teams:
              </p>
              <ul>
                <li>
                  Gather customer &amp; partner feedback and communicate it to
                  Dathena’s R&amp;D &amp; Product teams to improve features
                  &amp; products.
                </li>
                <li>
                  Assist in prioritizing product requirements and platform
                  improvements based on customer needs &amp; potential economic
                  value.
                </li>
              </ul>
              <div className="pt-2"></div>
              <h5>Desired Profile</h5>
              <ul>
                <li>
                  At least 5 years of experience in IT and/or information risk
                  management.
                </li>
                <li>
                  Knowledge of cybersecurity &amp; privacy a definite plus.
                </li>
                <li>
                  Education: Bachelor's or Master’s degree with focuses on
                  Finance/Accounting/Economics, Management Information Systems,
                  Business Administration, Statistics Mathematics and/or other
                  business fields of study.{" "}
                </li>
              </ul>
              <div className="pt-2"></div>
            </div>
          </Paragraph>
        </Col>
      </Row>
    </>
  );
};

export default JobDescription;
