import { useState, useEffect } from "react";
import { useLikesAgainstAJobQuery } from "../graphql/generated/graphql";

const useLikesAgainstAJob = (jobId: any) => {
  const [matchs, setMatchs]: any = useState(null);
  const { data, loading, error, refetch } = useLikesAgainstAJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: "network-only",
  });
  const loadMatches = () => {
    if (!loading && data) {
      // console.log(data.likesAgainstAJob)
      setMatchs(data.likesAgainstAJob);
    }
  };

  useEffect(() => {
    loadMatches();
  }, [data]);

  // return matchs;
  return { matchs, refetch };
};

export default useLikesAgainstAJob;
