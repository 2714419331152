import { useState, useEffect } from "react";
import { useContractTypesQuery } from "../graphql/generated/graphql";

const useContractTypes = () => {
  const [contractTypes, setContractTypes]: any = useState([]);
  const { data, loading, error } = useContractTypesQuery();

  const loadContractTypes = () => {
    if (!loading && data) {
      // console.log(data.contracts)
      setContractTypes([...data.contracts]);
    }
  };

  useEffect(() => {
    loadContractTypes();
  }, [data]);

  return contractTypes;
};

export default useContractTypes;
