import { useState, useEffect } from "react";
import { useJobFieldsQuery } from "../graphql/generated/graphql";

const useJobFields = () => {
  const [contractTypes, setContractTypes]: any = useState([]);
  const { data, loading, error } = useJobFieldsQuery();

  const loadSectors = () => {
    if (!loading && data) {
      // console.log(data.jobFields)
      setContractTypes([...data.jobFields]);
    }
  };

  useEffect(() => {
    loadSectors();
  }, [data]);

  return contractTypes;
};

export default useJobFields;
