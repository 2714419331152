import { useState, useEffect } from "react";
import { useCompaniesQuery } from "../graphql/generated/graphql";

const useCompanies = () => {
  const [companies, setCompanies]: any = useState(null);
  const { data, loading, error } = useCompaniesQuery({
    fetchPolicy: "network-only",
  });

  const loadCompanies = () => {
    if (!loading && data) {
      setCompanies([...data.userCompanies]);
    }
  };

  useEffect(() => {
    loadCompanies();
  }, [data]);

  return companies;
};

export default useCompanies;
