import {
  jobsActionTypes,
  STORE_JOBS,
  STORE_JOB,
  DELETE_JOB
} from "./types";

export const StoreJobs = (jobs: Array<Object>): jobsActionTypes => {
  return {
    type: STORE_JOBS,
    jobs
  };
};

export const StoreJob = (job: Object): jobsActionTypes => {
  return {
    type: STORE_JOB,
    job: job
  };
};

export const DeleteJob = (jobId: String): jobsActionTypes => {
  return {
    type: DELETE_JOB,
    jobId: jobId
  };
};

