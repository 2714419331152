import {
  DeleteOutlined,
  EditOutlined,
  FacebookFilled,
  FacebookOutlined,
  GithubFilled,
  LinkedinFilled,
  PlusOutlined,
  TwitterSquareFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Timeline,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import ErrorCard from "components/UI/ErrorCard/ErrorCard";
import PageTitle from "components/UI/PageTitle/PageTitle";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  EducationInput,
  ExperienceInput,
  useGetSingleRecruiterQuery,
  useUpdateRecruiterMutation,
} from "../../../graphql/generated/graphql";
import ActivityLogDrawer from "./partials/ActivityLogDrawer";
import EducationDrawer from "./partials/EducationDrawer";
import ExperienceDrawer from "./partials/ExperienceDrawer";
import GeneralFormDrawer from "./partials/GeneralFormDrawer";
import ResetPasswordFormDrawer from "./partials/ResetPasswordFormDrawer";
import SummaryFormDrawer from "./partials/SummaryFormDrawer";
import { Education, Experience } from "containers/Candidates/Profile";
import { Link } from "react-router-dom";
import linkedIn from "../../../assets/icons/social/linkedIn.svg";
import facebook from "../../../assets/icons/social/Facebook.svg";
import github from "../../../assets/icons/social/Github.svg";
import twitter from "../../../assets/icons/social/Twitter.svg";
import play from "../../../assets/icons/profileIcons/play.png";
import { viewProfile } from "assets/icons/DetailedProfile";
import ProfileVideo from "containers/Candidates/Profile/PersonalInfo/Component/ProfileVideo";
import "./styles.scss";

const { Title, Paragraph } = Typography;

interface ViewRecruiterProps {}

const ViewRecruiter: React.FC<ViewRecruiterProps> = () => {
  //temp for UI
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // const data = urlParams.get("data");

  const { data, loading, error, refetch }: any = useGetSingleRecruiterQuery({
    fetchPolicy: "network-only",
  });

  const [Recruiter, setRecruiter]: any = useState(null);
  const [fullName, setfullName]: any = useState(null);
  const [title, settitle]: any = useState(null);
  const [displaySummaryDrawer, setDisplaySummaryDrawer] = useState(false);
  const [summary, setSummary] = useState(null);
  const [displayGeneralDrawer, setDisplayGeneralDrawer] = useState(false);
  const [displayEducationDrawer, setDisplayEducationDrawer] = useState(false);
  const [educations, setEducations] = useState<EducationInput[]>([]);
  const [selectedEducation, setSelectedEducation] = useState({});
  const [displayExperienceDrawer, setDisplayExperienceDrawer] = useState(false);
  const [experiences, setExperiences] = useState<ExperienceInput[]>([]);
  const [selectedExperience, setSelectedExperience] = useState({});
  const [displayActivityDrawer, setDisplayActivityDrawer] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [update, setUpdate] = useState({});
  const [displayResetPasswordDrawer, setDisplayResetPasswordDrawer] =
    useState(false);
  const [upload, setUpload] = useState({
    loading: false,
    url: Recruiter?.profilePicture
      ? Recruiter?.profilePicture
      : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg",
    error: "",
  });

  const [imgLoad, setImgLoad] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [updateRecruiterMutation, { loading: loading1 }] =
    useUpdateRecruiterMutation();

  const beforeUpload = (file: any) => {
    setImgLoad(true);
    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
    ];
    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error("You can only upload images file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      setImgLoad(false);
      message.error("Image must be smaller than 2MB!");
    }
    return isImg && isLt5M;
  };

  const onChange = (e: any) => {
    // console.log(e.file.status);
    if (imgLoad) {
      setFileList(e.fileList);
      // e.fileList.length !== 0 && imageData(e.fileList[0].originFileObj);
    }
    if (e.file.status === "done") {
      // console.log(e.file);
      setUpload({
        ...upload,
        loading: false,
        url: e.file.response.secure_url,
      });
    }
  };

  useEffect(() => {
    // console.log({data})
    if (data && data.getSingleRecruiter) {
      setRecruiter(data.getSingleRecruiter);
      setfullName(data.getSingleRecruiter?.fullName);
      settitle(data.getSingleRecruiter?.title);
      setSummary(data.getSingleRecruiter?.description);
      !!data.getSingleRecruiter?.educations &&
        setEducations(
          data.getSingleRecruiter?.educations.map(
            ({ __typename, ...e }: any) => e
          )
        );
      !!data.getSingleRecruiter?.experiences &&
        setExperiences(
          data.getSingleRecruiter?.experiences.map(
            ({ __typename, ...e }: any) => e
          )
        );
      setUpload({
        loading: false,
        error: "",
        url: data.getSingleRecruiter?.profilePic
          ? data.getSingleRecruiter?.profilePic
          : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg",
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      !upload.loading &&
      upload.url !== Recruiter?.profilePic &&
      Recruiter !== null
    ) {
      console.log("Calling API ... ");
      updateRecruiter({ profilePic: upload.url });
    }
  }, [upload.url]);

  const updateRecruiter = (data: any) => {
    // console.log(data,'----update')
    updateRecruiterMutation({
      variables: {
        recruiterId: Recruiter._id,
        recruiter: {
          fullName: fullName,
          profilePic: upload.url,
          title: title,
          description: summary,
          experiences: [...experiences],
          educations: [...educations],
          ...data,
        },
        // recruiter: {...Recruiter, ...data}
      },
    })
      .then(({ data }) => {
        setRecruiter({ ...data?.updateRecruiter });
        setDisplayGeneralDrawer(false);
        refetch();
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        console.log(errors);
      });
  };

  const handleDeleteItem = (index: number, type: "exp" | "edu") => {
    switch (type) {
      case "exp":
        let tempExperiences = experiences;
        tempExperiences.splice(index, 1);
        updateRecruiter({ experiences: [...tempExperiences] });
        break;
      case "edu":
        let tempEducations = educations;
        tempEducations.splice(index, 1);
        updateRecruiter({ educations: [...tempEducations] });
        break;
      default:
        break;
    }
  };

  const socialProfileLinks = [
    { Icon: linkedIn, src: Recruiter?.linkedIn },
    { Icon: github, src: Recruiter?.github },
    { Icon: twitter, src: Recruiter?.twitter },
    { Icon: facebook, src: Recruiter?.facebook },
  ];

  const handleNavigation = (link) => {
    window.open(link, "_blank");
  };

  const closeVideoModal = () => {
    setOpenVideo(false);
  };
  const openVideoModal = () => {
    setOpenVideo(true);
  };

  const styles = {
    overlay: {
      // position: 'absolute',
      right: "-16px",
      bottom: "0px",
      zIndex: 8,
      cursor: "pointer",
      transition: "ease-in",
      msTransitionDuration: "0.3s",
      opacity: isHovered ? 1 : 0,
      height: "150px",
      width: "150px",
      borderRadius: "50%",
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  };

  const handleUpdate = (obj: any) => {
    setUpdate(obj);
  };

  const showExperienceDrawer = (show: any) => {
    setDisplayExperienceDrawer(show);
  };
  const showEducationDrawer = (show: any) => {
    setDisplayEducationDrawer(show);
  };

  console.log(Recruiter, "------Recruiter----Data----");

  return (
    <>
      <PageTitle
        back
        title="My profile"
        btnText="Edit profile"
        btnAddionalText="Next"
        // to={`/add-job`}
        btnActive={false}
        btnAddional={false}
      />

      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <Row>
          <Col span={24}>
            <Fade>
              <Card>
                <Skeleton
                  active
                  paragraph={{ rows: 20 }}
                  className="p-4"
                  loading={loading}
                >
                  {Recruiter !== null && (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col md={8} lg={8} xl={8}>
                        <Card>
                          <img
                            src={Recruiter?.coverPhoto}
                            style={{
                              objectFit: "cover",
                              height: "200px",
                              width: "100%",
                              zIndex: "3",
                              borderTopLeftRadius: "8px",
                              borderTopRightRadius: "8px",
                            }}
                          />
                          <Tooltip title="edit" style={{}}>
                            <Button
                              style={{
                                zIndex: "5",
                                position: "relative",
                                marginTop: "4px",
                              }}
                              className="border-primary"
                              shape="circle"
                              icon={<EditOutlined className="text-primary" />}
                              onClick={() => setDisplayGeneralDrawer(true)}
                            />
                          </Tooltip>
                          <div
                            style={{
                              marginTop: "-210px",
                              paddingBottom: "8px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  maxWidth: "134px",
                                }}
                              >
                                {/* <div className="" 
                                style={{ position: 'absolute', ...styles.overlay }} onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}></div>{" "} */}
                                <Image
                                  className="user-profile-avatar"
                                  // size={64}
                                  // src="http://blonk.co/demo/SOBlonk/assets/images/users/user-0.jpg"
                                  src={upload.url}
                                  style={{
                                    zIndex: "5",
                                    borderRadius: "50%",
                                    height: "64px",
                                    width: "64px",
                                    // position: "relative",
                                    marginTop: "110px",
                                    border: "8px solid white",
                                  }}
                                />

                                {/* Overlay element for darkness */}
                                {/* <div className="preview-text" style={{ height:'134px',width:'134px' }}>
                                  {" "}
                                  <img src={`${viewProfile}`} alt="" style={{ height:'134px',width:'134px' }} /> Preview
                                </div> */}
                                {Recruiter?.profileVideo && (
                                  <img
                                    src={play}
                                    alt="video-icon"
                                    height={38}
                                    width={38}
                                    style={{
                                      position: "absolute",
                                      right: "-10px",
                                      bottom: "11px",
                                      zIndex: 8,
                                      cursor: "pointer",
                                      transition: "ease-in",
                                      msTransitionDuration: "0.3s",
                                      opacity: 1,
                                    }}
                                    onClick={openVideoModal}
                                  />
                                )}
                                {Recruiter?.profileVideo && (
                                  <ProfileVideo
                                    open={openVideo}
                                    src={Recruiter?.profileVideo}
                                    // src={candidate?.profileVideo}
                                    handleClose={closeVideoModal}
                                    // candidate={candidate?.firstName + " " + candidate?.lastName}
                                  />
                                )}
                              </div>
                            </div>
                            {/* <div className="set-img-upload-btn">
                            <Upload
                              onChange={onChange}
                              // onRemove={onRemove}
                              // action="https://api.cloudinary.com/v1_1/blonk-group/image/upload"
                              action={`${process.env.REACT_APP_BASE_REST_URL}/upload/recrutier/${Recruiter?._id}`}
                              data={{
                                upload_preset: "cqdbrmkw",
                              }}
                              // onPreview={onPreview}
                              accept="image/*"
                              multiple={false}
                              beforeUpload={beforeUpload}
                            >
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </div> */}
                            <Title
                              className="m-t-10 user-avatar-title mb-0"
                              level={5}
                              style={{ color: "#012653", fontSize: 18 }}
                            >
                              {Recruiter?.fullName}
                            </Title>
                            <Paragraph
                              className=" text-center mb-0"
                              style={{ fontWeight: "500", fontSize: 16 }}
                            >
                              {Recruiter?.title}
                            </Paragraph>
                            {Recruiter?.company && (
                              <Paragraph
                                className=" text-center mb-0"
                                style={{ fontWeight: "600", fontSize: 14 }}
                              >
                                @{Recruiter?.company}{" "}
                                <span style={{ fontWeight: "500" }}>
                                  {Recruiter?.location?.city && (
                                    <>
                                      <span style={{ fontWeight: "600" }}>
                                        |{" "}
                                      </span>
                                      {Recruiter?.location?.city}
                                    </>
                                  )}
                                  {Recruiter?.location?.country && (
                                    <>, {Recruiter?.location?.country}</>
                                  )}
                                </span>
                              </Paragraph>
                            )}
                            <Paragraph
                              className="text-center mb-0 "
                              style={{
                                color: "#012653",
                                fontWeight: 500,
                                fontSize: 16,
                              }}
                            >
                              {Recruiter?.user && Recruiter?.user?.email}
                            </Paragraph>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {socialProfileLinks.map((link) => {
                                return link.src?.length > 3 ? (
                                  <span
                                    className="mt-2"
                                    onClick={() => handleNavigation(link.src)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    <img src={link.Icon} alt={link.src} />
                                  </span>
                                ) : null;
                              })}
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col span={16}>
                        <Card bordered>
                          <div className="summary mt-0 mb-0 pb-2">
                            <Title
                              level={4}
                              className="mt-0 mb-0 primary-text"
                              style={{ fontSize: "16px" }}
                            >
                              Summary{" "}
                              <Tooltip title="edit">
                                <Button
                                  onClick={() => setDisplaySummaryDrawer(true)}
                                  className="border-primary"
                                  shape="circle"
                                  icon={
                                    <EditOutlined className="text-primary" />
                                  }
                                  style={{ float: "right" }}
                                />
                              </Tooltip>
                            </Title>
                            {data && !Recruiter?.description ? (
                              <Button
                                type="dashed"
                                onClick={() => setDisplaySummaryDrawer(true)}
                                block
                              >
                                <PlusOutlined /> Add summary
                              </Button>
                            ) : (
                              <Paragraph
                                style={{ marginBottom: "0px", color: "black" }}
                              >
                                {Recruiter?.description}
                              </Paragraph>
                            )}
                          </div>
                        </Card>
                        <Card>
                          {/* <div className="work-history mb-4 pb-4">
                            <Title
                              className="mt-0 mb-4 text-teal header-title"
                              level={4}
                            >
                              Experience
                            </Title>
                            <Timeline className="activity-feed">
                              {Recruiter?.experiences?.length &&
                                Recruiter?.experiences?.map(
                                  (exp: any, index: number) => {
                                    return (
                                      <Timeline.Item
                                        className="feed-item"
                                        key={index}
                                      >
                                        <div
                                          className="feed-item-list"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div>
                                            <span className="date">
                                              {moment(exp.startYear).year()} -{" "}
                                              {!!exp.current
                                                ? "Present"
                                                : moment(exp.endYear).year()}
                                            </span>
                                            <span className="activity-text text-navy">
                                              {exp.position}
                                            </span>
                                            <p className="inbox-item-text text-amber mb-0">
                                              {exp.company}
                                            </p>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: 3,
                                            }}
                                          >
                                            <Tooltip title="edit">
                                              <Button
                                                onClick={() => {
                                                  setSelectedExperience({
                                                    ...exp,
                                                    index,
                                                  });
                                                  setTimeout(() => {
                                                    setDisplayExperienceDrawer(
                                                      true
                                                    );
                                                  });
                                                }}
                                                className="border-primary"
                                                shape="circle"
                                                icon={
                                                  <EditOutlined className="text-primary" />
                                                }
                                                style={{ float: "right" }}
                                              />
                                            </Tooltip>
                                            <Tooltip title="delete">
                                              <Popconfirm
                                                title="Confirm you want to delete this experience"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={() =>
                                                  handleDeleteItem(index, "exp")
                                                }
                                              >
                                                <Button
                                                  className="border-primary"
                                                  shape="circle"
                                                  icon={
                                                    <DeleteOutlined className="text-primary" />
                                                  }
                                                  style={{ float: "right" }}
                                                />
                                              </Popconfirm>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </Timeline.Item>
                                    );
                                  }
                                )}
                              <Button
                                type="dashed"
                                onClick={() => {
                                  const newLength = experiences.push({});
                                  setSelectedExperience({
                                    ...experiences[newLength - 1],
                                    index: newLength - 1,
                                    position: "",
                                    company: "",
                                  });
                                  setDisplayExperienceDrawer(true);
                                }}
                                block
                              >
                                <PlusOutlined /> Add experience
                              </Button>
                            </Timeline>
                          </div>
                          <div className="education-history mb-4 pb-4">
                            <Title
                              className="mt-0 mb-4 text-teal header-title"
                              level={4}
                            >
                              Education
                            </Title>
                            <Timeline className="activity-feed">
                              {Recruiter?.educations?.length &&
                                Recruiter?.educations?.map(
                                  (edu: any, index: number) => {
                                    return (
                                      <Timeline.Item
                                        className="feed-item"
                                        key={index}
                                      >
                                        <div
                                          className="feed-item-list"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div>
                                            <span className="date">
                                              {moment(edu.startYear).year()} -{" "}
                                              {!!edu.current
                                                ? "Present"
                                                : moment(edu.endYear).year()}
                                            </span>
                                            <span className="activity-text text-navy">
                                              {edu.degree}
                                            </span>
                                            <p className="inbox-item-text text-amber mb-0">
                                              {edu.schoolName}
                                            </p>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: 3,
                                            }}
                                          >
                                            <Tooltip title="edit">
                                              <Button
                                                onClick={() => {
                                                  setSelectedEducation({
                                                    ...edu,
                                                    index,
                                                  });
                                                  setTimeout(() => {
                                                    setDisplayEducationDrawer(
                                                      true
                                                    );
                                                  });
                                                }}
                                                className="border-primary"
                                                shape="circle"
                                                icon={
                                                  <EditOutlined className="text-primary" />
                                                }
                                                style={{ float: "right" }}
                                              />
                                            </Tooltip>
                                            <Tooltip title="delete">
                                              <Popconfirm
                                                title="Confirm you want to delete this education"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={() =>
                                                  handleDeleteItem(index, "edu")
                                                }
                                              >
                                                <Button
                                                  className="border-primary"
                                                  shape="circle"
                                                  icon={
                                                    <DeleteOutlined className="text-primary" />
                                                  }
                                                  style={{ float: "right" }}
                                                />
                                              </Popconfirm>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </Timeline.Item>
                                    );
                                  }
                                )}
                              <Button
                                type="dashed"
                                onClick={() => {
                                  const newLength = educations.push({});
                                  setSelectedEducation({
                                    ...educations[newLength - 1],
                                    index: newLength - 1,
                                    degree: "",
                                    schoolName: "",
                                  });
                                  setDisplayEducationDrawer(true);
                                }}
                                block
                              >
                                <PlusOutlined /> Add education
                              </Button>
                            </Timeline>
                          </div> */}
                          <div style={{ marginTop: 0 }}>
                            <Experience
                              isRecruiterPage={true}
                              candidate={Recruiter}
                              handleDeleteItem={handleDeleteItem}
                              handleUpdate={handleUpdate}
                              showExperienceDrawer={showExperienceDrawer}
                              // nocomments={location?.state?.comments}
                              nocomments={false}
                            />
                          </div>
                          <Button
                            type="dashed"
                            onClick={() => {
                              const newLength = experiences.push({});
                              setUpdate({
                                ...experiences[newLength - 1],
                                index: newLength - 1,
                                position: "",
                                company: "",
                              });
                              setDisplayExperienceDrawer(true);
                            }}
                            block
                          >
                            <PlusOutlined /> Add experience
                          </Button>
                        </Card>
                        <Card>
                          {/* Education */}
                          <div style={{ marginTop: 0 }}>
                            <Education
                              isRecruiterPage={true}
                              candidate={Recruiter}
                              handleDeleteItem={handleDeleteItem}
                              showEducationDrawer={showEducationDrawer}
                              handleUpdate={handleUpdate}
                              // nocomments={location?.state?.comments}
                              nocomments={false}
                            />
                          </div>
                          <Button
                            type="dashed"
                            onClick={() => {
                              const newLength = educations.push({});
                              setUpdate({
                                ...educations[newLength - 1],
                                index: newLength - 1,
                                degree: "",
                                schoolName: "",
                              });
                              setDisplayEducationDrawer(true);
                            }}
                            block
                          >
                            <PlusOutlined /> Add education
                          </Button>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Skeleton>
              </Card>
            </Fade>
          </Col>
        </Row>
      )}

      <SummaryFormDrawer
        setSummary={setSummary}
        loading={loading1}
        summary={summary}
        show={displaySummaryDrawer}
        hide={() => setDisplaySummaryDrawer(false)}
        updateRecruiter={updateRecruiter}
      />
      <ExperienceDrawer
        experience={update}
        show={displayExperienceDrawer}
        loading={loading1}
        hide={() => setDisplayExperienceDrawer(false)}
        updateExperience={(newExperience) => {
          const { __typename, index, ...rest } = newExperience;
          experiences[index] = rest;
          updateRecruiter({ experiences: [...experiences] });
        }}
      />
      <EducationDrawer
        show={displayEducationDrawer}
        hide={() => setDisplayEducationDrawer(false)}
        education={update}
        loading={loading1}
        updateEducation={(newEducation) => {
          const { __typename, index, ...rest } = newEducation;
          educations[index] = rest;
          updateRecruiter({ educations: [...educations] });
        }}
      />
      <GeneralFormDrawer
        recruiter={Recruiter}
        show={displayGeneralDrawer}
        fullName={fullName}
        setFullName={setfullName}
        title={title}
        settitle={settitle}
        loading={loading1}
        updateRecruiter={updateRecruiter}
        hide={() => setDisplayGeneralDrawer(false)}
      />
      <ActivityLogDrawer
        show={displayActivityDrawer}
        hide={() => setDisplayActivityDrawer(false)}
      />
      <ResetPasswordFormDrawer
        show={displayResetPasswordDrawer}
        hide={() => setDisplayResetPasswordDrawer(false)}
      />
    </>
  );
};

export default ViewRecruiter;
