import {
  removeNotification,
  removeMultipleNotifications,
} from "components/TopBar/partials/NotificationDropdown/notifications";
import PageTitle from "components/UI/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteNotification,
  DeleteAllNotifications,
} from "store/notifications/actions";
import { Checkbox, Popconfirm, notification } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Link } from "react-router-dom";

interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
  candidate: any;
  job: any; // job shuld contain the data of companies as well
  tabActivity: string; // this key will be generated by the backend (depending where this job came from)
  // depending on the tabActive key we will choose the redux jobs we will send to the candidates.
}
interface DisplayNotification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
  candidate: any;
  job: any;
  tabActivity: string;
  checkbox: {
    id: string;
    value: boolean;
  };
}

function NotificationsMain() {
  const dispatch = useDispatch();
  const notificationsRdx = useSelector((state: any) => state.notifications);
  const [notifications, setNotifications] = useState([]); //local state that holds the notifications

  // one source of truth and that is the redux
  console.log("Checking for notification bar");
  useEffect(() => {
    setNotifications(notificationsRdx?.notifications);

    return () => {};
  }, [notificationsRdx]);

  //when redireccting to the candidate listing page we are also sending the candidate list inside the from object.
  //This array of candidates is used to display previous and next candidates in the list.
  //but we have disabled the next previous in case its an empty object
  const getTheRightData = (tabActivity: string) => {
    return [];
  };

  // this is for delete all button
  const [hover, setHover] = useState(false);

  //we modified the notification object to incorporate checkboxes fuinctionality
  const [displayNotifications, setdisplayNotifications] = useState<
    DisplayNotification[]
  >([]);
  useEffect(() => {
    setdisplayNotifications(
      notifications.map((notif: Notification) => {
        return { ...notif, checkbox: { id: notif._id, value: false } };
      })
    );
    return () => {};
  }, [notifications]);

  // this code block is for incorporating the checking unchecking of the checkboxes
  const [allSelected, setallSelected] = useState(false);
  useEffect(() => {
    if (displayNotifications.length) {
      const areAllCheckBoxesSelected = displayNotifications.some(
        (notif) => notif.checkbox.value == false
      );
      setallSelected(!areAllCheckBoxesSelected);
    }
  }, [displayNotifications]);

  const selectNotification = (
    notificationId: string,
    e: CheckboxChangeEvent
  ) => {
    const updated: DisplayNotification[] = displayNotifications.map((notif) =>
      notif._id == notificationId
        ? { ...notif, checkbox: { id: notif._id, value: e.target.checked } }
        : notif
    );
    setdisplayNotifications(updated);
  };

  const selectUnselectAllNotifications = (e: CheckboxChangeEvent) => {
    const items: DisplayNotification[] = displayNotifications.map((notif) => ({
      ...notif,
      checkbox: { id: notif._id, value: e.target.checked },
    }));
    setdisplayNotifications(items);
  };

  //delete one notification
  const [setNotificationToRead, { data: any }] =
    useMutation(removeNotification);

  const deleteTheNotification = (notificationId: string) => {
    setNotificationToRead({
      variables: {
        notificationId: notificationId,
      },
    }).then((data: any) => {
      dispatch(DeleteNotification(notificationId));
    });
  };

  //delete multiple notifications

  const [setMultipleNotificationsToRead, { data: data1 }] = useMutation(
    removeMultipleNotifications
  );

  const deleteAllTheNotifications = () => {
    const keys = displayNotifications
      .filter((notif) => notif.checkbox.value === true)
      .map((notif) => notif._id);
    if (keys.length) {
      setMultipleNotificationsToRead({
        variables: {
          notificationsArray: [...keys],
        },
      })
        .then((data: any) => {
          notification["success"]({
            message: `Notifications removed successfully.`,
          });
          dispatch(DeleteAllNotifications(keys));
        })
        .catch((err) => {
          console.log("An error occured while deleting all notifications");
          console.log({ err });
        });
    } else {
      notification["error"]({
        message: "Please select the notifications you want to delete!",
      });
    }
  };

  // confirm dialog functionality
  const confirmDeleteAll = (e: React.MouseEvent<HTMLElement>) => {
    deleteAllTheNotifications();
  };

  const cancelDelete = (e: React.MouseEvent<HTMLElement>) => {
    console.log("delete nothing");
  };

  const confirmDeleteOneNotification = (
    e: React.MouseEvent<HTMLElement>,
    notificationId: string
  ) => {
    deleteTheNotification(notificationId);
  };

  return (
    <>
      <PageTitle back title="Notifications" />
      <div style={{ justifyContent: "center", height: 640, width: "100%" }}>
        <div
          style={{
            height: "100%",
            width: "60%",
            backgroundColor: "#ffffff",
            marginLeft: "20%",
            marginRight: "20%",
            boxShadow: "",
            overflowY: "scroll",
          }}
        >
          {/** This is the element of the notifications header */}
          <div
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              backgroundColor: "#ffffff",
              top: 0,
              zIndex: 999,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Checkbox
                style={{ width: 22, height: 22, marginLeft: 20 }}
                checked={allSelected}
                onChange={selectUnselectAllNotifications}
              />
              <div style={{ marginLeft: 20 }}>
                <p
                  style={{
                    color: "#000000",
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                >
                  Notifications
                </p>
              </div>
            </div>
            <div style={{ marginRight: 20 }}>
              <div
                style={
                  hover
                    ? {
                        backgroundColor: "#990000",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 20,
                        paddingLeft: 20,
                      }
                    : {
                        backgroundColor: "#C4C4C4",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 20,
                        paddingLeft: 20,
                      }
                }
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Popconfirm
                  title="Are you sure to delete these notifications?"
                  onConfirm={confirmDeleteAll}
                  onCancel={cancelDelete}
                  okText="Yes"
                  cancelText="No"
                >
                  <span style={{ color: "white", cursor: "default" }}>
                    Delete all
                  </span>
                  <img
                    style={{ height: "12px", marginLeft: 8 }}
                    src={require("../../assets/icons/icons/delete-all.png")}
                  />
                </Popconfirm>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", height: "100%", marginTop: 10 }}>
            {displayNotifications?.length ? (
              displayNotifications?.map((notif: DisplayNotification) => (
                <div key={notif._id}>
                  {notif?.type === "chat" ||
                  notif?.type === "recruiter_comment" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "12px 24px",
                      }}
                    >
                      <Checkbox
                        style={{ width: 22, height: 22 }}
                        checked={notif.checkbox.value}
                        onChange={(e) => selectNotification(notif._id, e)}
                      />

                      <Link
                        style={{ width: "100%" }}
                        to={{
                          pathname: `/candidate-listings/view/${notif?.candidate?._id}`,
                          state: {
                            company: notif.job?.company,
                            matchId: notif?.matchId,
                            from: getTheRightData(notif?.tabActivity),
                            jobId: notif?.jobId,
                            jobName: notif?.job?.title,
                            companyName: notif?.job?.company?.name,
                            tabActiveKey: notif?.tabActivity,
                          },
                        }}
                        onClick={
                          notif?.type === "recruiter_comment"
                            ? () => deleteTheNotification(notif._id)
                            : () =>
                                console.log("dont delete the chat notificatio")
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "20px",
                          }}
                        >
                          <div>
                            {notif?.type == "chat" ? (
                              <img
                                style={{ width: 36, height: 36 }}
                                src={require("../../assets/icons/icons/chat-notif.png")}
                              />
                            ) : (
                              <img
                                style={{ width: 36, height: 36 }}
                                src={require("../../assets/icons/icons/comment-notif.png")}
                              />
                            )}
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Lato",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "19.2px",
                                  color: "#000000",
                                }}
                              >
                                {notif?.notificationHeader}{" "}
                              </span>
                              <span
                                style={{
                                  fontFamily: "Lato",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  lineHeight: "16.8px",
                                  color: "#495057",
                                }}
                              >
                                {notif?.notificationBody}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Popconfirm
                        title="Are you sure to delete this notifications?"
                        onConfirm={(e) =>
                          confirmDeleteOneNotification(e, notif._id)
                        }
                        onCancel={cancelDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div style={{ alignSelf: "center" }}>
                          <img
                            style={{
                              width: "16px",
                              height: "16px",
                              opacity: 0.25,
                            }}
                            src={require("../../assets/icons/profileIcons/cross.png")}
                          />
                        </div>
                      </Popconfirm>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "12px 24px",
                      }}
                    >
                      <Checkbox
                        style={{ width: 22, height: 22 }}
                        checked={notif.checkbox.value}
                        onChange={(e) => selectNotification(notif._id, e)}
                      />

                      <Link
                        style={{ width: "100%" }}
                        to={{
                          pathname: `/candidate-listings/${notif?.jobId}`,
                          search: "?notification=true",
                          state: { tabActiveKey: notif.tabActivity },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "20px",
                          }}
                        >
                          <div>
                            <img
                              style={{ width: 36, height: 36 }}
                              src={require("../../assets/icons/icons/match-notif.png")}
                            />
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Lato",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                  lineHeight: "19.2px",
                                  color: "#000000",
                                }}
                              >
                                {notif?.notificationHeader}{" "}
                              </span>
                              <span
                                style={{
                                  fontFamily: "Lato",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "16.8px",
                                  color: "#495057",
                                }}
                              >
                                {notif?.notificationBody}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Popconfirm
                        title="Are you sure to delete this notifications?"
                        onConfirm={(e) =>
                          confirmDeleteOneNotification(e, notif._id)
                        }
                        onCancel={cancelDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div style={{ alignSelf: "center" }}>
                          <img
                            style={{
                              width: "16px",
                              height: "16px",
                              opacity: 0.25,
                            }}
                            src={require("../../assets/icons/profileIcons/cross.png")}
                          />
                        </div>
                      </Popconfirm>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80%",
                }}
              >
                <p
                  style={{
                    fontStyle: "Lato",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#0000004",
                    opacity: 0.25,
                  }}
                >
                  No Notifications
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationsMain;
