import React from "react";

interface PropsType {
  experience: any;
}

const Styles = {
  root: {},
  commentBox: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "rgba(188, 150, 35, 0.03)",
    marginTop: 10,
    borderRadius: 9,
    marginBottom: 17,
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.14)",
    // border: "solid 1px #DADADA",
  },
  body: {
    margin: 0,
    color: "#495057",
    fontSize: 12,
    fontStyle: "italic",
  },
  heading: {
    margin: 0,
    color: "#012653",
    fontSize: 14,
    fontWeight: 500,
  },
};

const ExperienceComment: React.FC<PropsType> = ({ experience }) => {
  // console.log(experience);
  const { comments, reasonForChange } = experience;
  //\n replace with br tag
  const formattedComment = comments?.replace(/\n/g, "<br />");
  const formattedReasaonFroChange = reasonForChange?.replace(/\n/g, "<br />");

  return (comments != null && comments != "") ||
    (reasonForChange != null && reasonForChange != "") ? (
    <div style={Styles.commentBox}>
      {comments != null && comments != "" && (
        <>
          <p style={Styles.heading}>Comments:</p>
          <div
            style={Styles.body}
            dangerouslySetInnerHTML={{ __html: formattedComment }}
          />
          {/* <p style={Styles.body}>{comments}</p> */}
        </>
      )}
      {reasonForChange != null && reasonForChange != "" && (
        <>
          {comments == null || comments == "" ? null : (
            <span
              style={{
                display: "flex",
                border: "1px #00000040 dashed",
                margin: 8,
              }}
            ></span>
          )}

          <p style={Styles.heading}>Reasons for change:</p>
          <div
            style={Styles.body}
            dangerouslySetInnerHTML={{ __html: formattedReasaonFroChange }}
          />

          {/* <p style={Styles.body}>{reasonForChange}</p> */}
        </>
      )}
    </div>
  ) : null;
};

export default ExperienceComment;
