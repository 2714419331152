import React, { useContext, useState } from "react";
import { SectionTitle } from "../Shared";
import { LocationIcon } from "assets/icons/DetailedProfile";
import { Typography } from "antd";
// import { TargetLocationDrawer } from '../Edit'

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #364972",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};

const TargetLocations: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);
  const handleEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* <TargetLocationDrawer onUpdate={handleClose} open={open} candidate={candidate} handleClose={handleClose} /> */}
      {candidate?.targetLocations.length > 0 && (
        <div style={Styles.root}>
          <SectionTitle
            actionHandler={handleEdit}
            title="Target locations"
            icon={LocationIcon}
            fontSize="16px"
            actionType="edit"
            showstyle={false}
          />
          <div style={Styles.body}>
            {candidate?.targetLocations != null &&
              candidate?.targetLocations.map(
                (location: any, index: any) =>
                  index < 5 && (
                    <Typography
                      style={{
                        marginBottom:
                          index == candidate?.targetLocations.length - 1
                            ? 0
                            : 8,
                      }}
                    >
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        {location.city && <>{location.city},</>}
                      </span>{" "}
                      <span style={{ fontWeight: 500, fontSize: 14 }}>
                        {location.name}
                      </span>
                    </Typography>
                  )
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default TargetLocations;
