import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import useNewCandidatesAgainstAJob from "../../../hooks/useNewCandidatesAgainstAJob";
import useLikesAgainstAJob from "../../../hooks/useLikesAgainstAJob";
import useDisLikesAgainstAJob from "../../../hooks/useDisLikesAgainstAJob";
import useMatchesAgainstAJob from "../../../hooks/useMatchesAgainstAJob";
import useSelectedAgainstAJob from "../../../hooks/useSelectedAgainstAJob";
import useRejectedAgainstAJob from "../../../hooks/useRejectedAgainstAJob";
import useHiredAgainstAJob from "../../../hooks/useHiredAgainstAJob";
// import useCandidateFavouritesAgainstAJob from "../../../hooks/useFavouritesAgainstAJob";
import { TabActvContext } from "./context/tabActvContext";
import MatchesTabs from "./Components/MatchesTabs";
import Searchbar from "./Components/Searchbar";
import PageTitle from "components/UI/PageTitle/PageTitle";
import { Skeleton } from "antd";
interface CandidateListingsProps {}
type StateType = {
  fromURL: string; // Replace with the actual type of 'fromURL'
};
const CandidateListings: React.FC<CandidateListingsProps> = () => {
  const params: any = useParams();
  const { searchData, companyData } = useContext(TabActvContext);
  const {
    matchs: newCandidatesAgainstAJob,
    refetch: refetchnewCandidatesAgainstAJob,
  } = useNewCandidatesAgainstAJob(params.jobId);
  const { matchs: likesAgainstAJob, refetch: refetchlikesAgainstAJob } =
    useLikesAgainstAJob(params.jobId);
  const { matchs: disLikesAgainstAJob, refetch: refetchdisLikesAgainstAJob } =
    useDisLikesAgainstAJob(params.jobId);
  const { matchs: matchesAgainstAJob, refetch: refetchmatchesAgainstAJob } =
    useMatchesAgainstAJob(params.jobId);
  const {
    matchs: selectedCandidatesAgainstAJob,
    refetch: refetchselectedCandidatesAgainstAJob,
  } = useSelectedAgainstAJob(params.jobId);
  const {
    matchs: rejectedCandidatesAgainstAJob,
    refetch: refetchrejectedCandidatesAgainstAJob,
  } = useRejectedAgainstAJob(params.jobId);
  const {
    matchs: hiredCandidatesAgainstAJob,
    refetch: refetchiredCandidatesAgainstAJob,
  } = useHiredAgainstAJob(params.jobId);
  // const favouritesCandidatesAgainstAJob = useCandidateFavouritesAgainstAJob(
  //   params.jobId
  // );

  const location = useLocation();

  useEffect(() => {
    //here we will refetch the data
    refetchnewCandidatesAgainstAJob();
    refetchlikesAgainstAJob();
    refetchmatchesAgainstAJob();
    refetchdisLikesAgainstAJob();
    refetchselectedCandidatesAgainstAJob();
    refetchrejectedCandidatesAgainstAJob();
    refetchiredCandidatesAgainstAJob();
  }, [location.key]);
  let isJobListings: StateType | undefined =
    (location?.state as StateType) || undefined;
  if (isJobListings?.fromURL) {
    localStorage.setItem("keytab", "1");
  }

  return (
    <>
      <span className="font-size-custom">
        <PageTitle
          to={
            isJobListings?.fromURL
              ? "/job-listings"
              : `/companies/view/${companyData?.getCompanyFromJob?._id}`
          }
          back
          title={
            companyData?.getCompanyFromJob
              ? `List of candidates for ${companyData?.getCompanyFromJob?.jobTitle} at ${companyData?.getCompanyFromJob?.name}`
              : "List of candidates"
          }
          btnActive={true}
        />
      </span>
      <Searchbar jobId={params.jobId} />
      {newCandidatesAgainstAJob &&
      likesAgainstAJob &&
      disLikesAgainstAJob &&
      matchesAgainstAJob &&
      selectedCandidatesAgainstAJob &&
      rejectedCandidatesAgainstAJob &&
      hiredCandidatesAgainstAJob ? (
        // favouritesCandidatesAgainstAJob &&
        <MatchesTabs
          jobId={params.jobId}
          newCandidatesAgainstAJob={[...newCandidatesAgainstAJob]}
          likesAgainstAJob={[...likesAgainstAJob]}
          disLikesAgainstAJob={[...disLikesAgainstAJob]}
          matchesAgainstAJob={[...matchesAgainstAJob]}
          selectedCandidates={[...selectedCandidatesAgainstAJob]}
          rejectedCandidatesLoaded={[...rejectedCandidatesAgainstAJob]}
          hiredCandidatesLoaded={[...hiredCandidatesAgainstAJob]}
          // favouritesCandidatesLoaded={[...favouritesCandidatesAgainstAJob]}
        />
      ) : (
        <Skeleton active />
      )}
    </>
  );
};

export default CandidateListings;
