export interface Jobs {
  jobs: any;
}
export interface JobsState {
  jobs: Jobs;
}

// Describing the different ACTION NAMES available
export const STORE_JOBS = 'STORE_JOBS';
export const STORE_JOB = 'STORE_JOB';
export const DELETE_JOB = 'DELETE_JOB';

interface storeJobs {
  type: typeof STORE_JOBS;
  jobs: Array<object>;
}
interface storeJob {
  type: typeof STORE_JOB;
  job: object;
}
interface deleteJob {
  type: typeof DELETE_JOB;
  jobId: String
}


export type jobsActionTypes = storeJobs | storeJob | deleteJob