import {
  matchsActionTypes,
  STORE_NEW_CANDIDATES,
  STORE_LIKED_CANDIDATES,
  STORE_DISLIKED_CANDIDATES,
  STORE_MATCHED_CANDIDATES,
  MOVED_TO_LIKED_CANDIDATES,
  MOVED_TO_DISLIKED_CANDIDATES,
  MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES,
  MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES,
  MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES,
  MOVED_TO_MATCHED_CANDIDATES,
  MOVED_TO_MATCHED_FROM_DISLIKED_CANDIDATES,
  MOVED_TO_MATCHED_FROM_SELECTED_CANDIDATES,
  MOVED_TO_SELECTED_FROM_REJECTED_CANDIDATES,
  STORE_SELECTED_CANDIDATES,
  STORE_SELECTED_CANDIDATES_LOAD,
  STORE_REJECTED_CANDIDATES,
  LOAD_REJECTED_CANDIDATES,
  STORE_HIRED_CANDIDATES,
  LOAD_HIRED_CANDIDATES,
  STORE_FAVOURITES_CANDIDATES,
  LOAD_FAVOURITES_CANDIDATES,
} from "./types";

export const StoreNewCandidates = (
  newCandidates: Array<Object>
): matchsActionTypes => {
  return {
    type: STORE_NEW_CANDIDATES,
    newCandidates,
  };
};

export const StoreLikedCandidates = (
  likedCandidates: Array<Object>
): matchsActionTypes => {
  return {
    type: STORE_LIKED_CANDIDATES,
    likedCandidates,
  };
};

export const MoveToLikedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_LIKED_CANDIDATES,
    candidateId,
  };
};

export const MoveToMatchedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_MATCHED_CANDIDATES,
    candidateId,
  };
};

export const MoveToMatchedFromDislikeCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_MATCHED_FROM_DISLIKED_CANDIDATES,
    candidateId,
  };
};

export const MoveToDisLikedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_DISLIKED_CANDIDATES,
    candidateId,
  };
};
export const MoveToDisLikedFromLikedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES,
    candidateId,
  };
};
export const MoveToLikedFromDisLikedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES,
    candidateId,
  };
};
export const MoveToDisLikedFromMatchedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES,
    candidateId,
  };
};
export const MoveToSelectedFromRejectedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_SELECTED_FROM_REJECTED_CANDIDATES,
    candidateId,
  };
};
export const MoveToMatchedFromSelectedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: MOVED_TO_MATCHED_FROM_SELECTED_CANDIDATES,
    candidateId,
  };
};
export const StoreDislikedCandidates = (
  disLikedCandidates: Array<Object>
): matchsActionTypes => {
  return {
    type: STORE_DISLIKED_CANDIDATES,
    disLikedCandidates,
  };
};

export const StoreMatchedCandidates = (
  matchedCandidates: Array<Object>
): matchsActionTypes => {
  return {
    type: STORE_MATCHED_CANDIDATES,
    matchedCandidates,
  };
};

export const StoreSelectedCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: STORE_SELECTED_CANDIDATES,
    candidateId,
  };
};

export const StoreRejectedCandidates = (
  candidateId: String
): matchsActionTypes => {
  // console.log("test candidate reject");
  return {
    type: STORE_REJECTED_CANDIDATES,
    candidateId,
  };
};

export const StoreHiredCandidates = (
  candidateId: String
): matchsActionTypes => {
  return {
    type: STORE_HIRED_CANDIDATES,
    candidateId,
  };
};

export const StoreFavouritesCandidates = (
  candidateId: String
): matchsActionTypes => {
  // console.log("test candidate reject");
  return {
    type: STORE_FAVOURITES_CANDIDATES,
    candidateId,
  };
};

export const StoreSelectedCandidatesLoad = (
  storeSelected: Array<Object>
): matchsActionTypes => {
  return {
    type: STORE_SELECTED_CANDIDATES_LOAD,
    storeSelected,
  };
};

export const LoadRejectedCandidates = (
  storeSelected: Array<Object>
): matchsActionTypes => {
  return {
    type: LOAD_REJECTED_CANDIDATES,
    storeSelected,
  };
};

export const LoadHiredCandidates = (
  storeSelected: Array<Object>
): matchsActionTypes => {
  return {
    type: LOAD_HIRED_CANDIDATES,
    storeSelected,
  };
};

export const LoadFavouritesCandidates = (
  storeSelected: Array<Object>
): matchsActionTypes => {
  return {
    type: LOAD_FAVOURITES_CANDIDATES,
    storeSelected,
  };
};
