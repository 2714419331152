import React, { useContext } from "react";
import { Button } from "antd";
import { UploadIcon, ViewIcon, DeleteIcon } from "assets/icons/DetailedProfile";

interface PropsTypes {
  fileLink?: string;
}

const Styles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    cursor: "pointer",
    marginLeft: 5,
  },
};

const UVDButton: React.FC<PropsTypes> = ({ fileLink }) => {
  const viewResume = () => {
    window.open(
      fileLink?.includes(".pdf")
        ? `${fileLink}#toolbar=0&scrollbar=0&view=fitH&view=fitV`
        : `https://docs.google.com/gview?url=${fileLink}&embedded=true`
    );
  };

  return (
    <div style={Styles.root}>
      <img onClick={viewResume} style={Styles.btn} src={ViewIcon} />
    </div>
  );
};

export default UVDButton;
