import React, { useState, useEffect } from "react";
import { Card, Tooltip, Button, Space } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import validURL from "../../../helpers/validURL";
import styled from "styled-components";
import "./CompanyCard.scss";

interface Props {
  company?: any;
  trimText?: boolean;
}

const CompanyCard: React.FC<Props> = ({ company, trimText }) => {
  let { url } = useRouteMatch();
  const [imgURL, setImgURL] = useState(company.logoUrl);

  // useEffect(() => {
  //   if (!validURL(company.logoUrl)) {
  //     setImgURL("http://blonk.co/demo/SOBlonk/assets/images/users/user-2.jpg");
  //   }
  // }, []);
  return (
    <>
      {/* {console.log("CCc===> ", company)} */}
      {company && (
        <OverLayImageBackground
          banner={company?.banner}
          className="text-center mb-3"
        >
          <div className="directory-overlay">
            <Link to={`${url}/view/${company._id}`}>
              <img
                src={imgURL}
                className="thumb-xl img-thumbnail rounded-circle fit-to-screen"
                alt="Company logo"
              />
            </Link>
          </div>
        </OverLayImageBackground>
      )}
      {/* <div className="bg-primary text-center mb-3">
        <div className="directory-overlay">
          <Link to={`${url}/view/${company._id}`}>
            <img
              src={imgURL}
              className="thumb-xl img-thumbnail rounded-circle"
              alt="Company logo"
            />
          </Link>
        </div>
      </div> */}
      <div className="directory-content text-center p-4 mt-4">
        <Link to={`${url}/view/${company._id}`}>
          <h5 className="font-16 mb-1 mt-4 text-primary">{company.name}</h5>
        </Link>
        {company.users.length === "" && (
          <p className=" my-0 text-dark">4 jobs - 3 Recruiters</p>
        )}
        <span className="font-10 px-2 m-2 badge badge-amber badge-pill">
          {company.sector.title}
        </span>
        <span>
          <p
            className="text-muted text-justify font-12 trim-description"
            dangerouslySetInnerHTML={{
              __html: trimText
                ? company?.description?.substr(0, 200)
                : company?.description,
            }}
          ></p>
        </span>
        {/* <Space>
          {company?.confidentialdescription && (
            <div className="directory-content text-center mt-4">
              <span className="font-18 mb-2 px-4">
                Confidential description
              </span>
              <span>
                <p
                  className="text-muted text-justify font-12"
                  dangerouslySetInnerHTML={{
                    __html: trimText
                      ? company.confidentialdescription.substr(0, 80)
                      : company.confidentialdescription,
                  }}
                ></p>
              </span>
            </div>
          )}
        </Space> */}
        <Space className="card-align-links">
          {company.facebook && (
            <Link to={{ pathname: company.facebook }} target="_blank">
              <Button
                className="ant-btn-burgundy"
                shape="circle"
                icon={<i className="fab fa-facebook-f font-12"></i>}
              />
            </Link>
          )}
          {company.twitter && (
            <Link to={{ pathname: company.twitter }} target="_blank">
              <Button
                className="ant-btn-burgundy"
                shape="circle"
                icon={<i className="fab fa-twitter font-12"></i>}
              />
            </Link>
          )}
          {company.website && (
            <Link to={{ pathname: company.website }} target="_blank">
              <Button
                className="ant-btn-burgundy"
                shape="circle"
                icon={<i className="fas fa-link font-12"></i>}
              />
            </Link>
          )}
          {company.linkedIn && (
            <Link to={{ pathname: company.linkedIn }} target="_blank">
              <Button
                className="ant-btn-burgundy"
                shape="circle"
                icon={<i className="fab fa-linkedin-in font-12"></i>}
              />
            </Link>
          )}
        </Space>
      </div>
    </>
  );
};

const OverLayImageBackground: any = styled.div`
  /* background-color: ${(p: any) => (p.banner ? "white" : "#2e4a79")} ; */
  background-color: #2e4a79;
  background-image: url(${(p: any) => p.banner});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export default CompanyCard;
