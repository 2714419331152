import {
  Companies,
  companiesActionTypes,
  STORE_COMPANIES,
  STORE_COMPANY,
} from "./types";
import _ from "lodash";

import { updateObject } from "../../helpers/updateObject";

const initialState: Companies = {
  companies: [],
};

const storeCompanies = (state: Companies, action: any) => {
  return updateObject(state, { companies: action.companies });
};

const storeCompany = (state: Companies, action: any) => {
  const findIndex = state.companies.findIndex(
    (company: any) => company._id == action.company._id
  );
  if (findIndex > -1) {
    const updatedObject = _.merge(state.companies[findIndex], action.company);
    // console.log('Updated : ', updatedObject);
    const companies = state.companies;
    companies[findIndex] = updatedObject;
    return updateObject(state, { companies: companies });
  } else return state;
};

const companiesReducer = (
  state = initialState,
  action: companiesActionTypes
) => {
  switch (action.type) {
    case STORE_COMPANIES:
      return storeCompanies(state, action);
    case STORE_COMPANY:
      return storeCompany(state, action);
    default:
      return state;
  }
};

export default companiesReducer;
