import React, { useEffect, useState } from "react";
import { Activity, BasicInfo, Status } from "./Index";
import { Avatar, Image, Modal } from "antd";
import TopBanner from "assets/topBanner.png";
import { PlayIcon, viewProfile } from "assets/icons/DetailedProfile";
import { SectionTitle } from "../Shared";
// import { PersonalInfoDrawer } from '../Edit';
import ProfileVideo from "./Component/ProfileVideo";
import Social from "./Component/Social";
import Tags from "../Summary/Components/Tags";
import Benchmark from "../Summary/Components/Benchmark";
import { Summary } from "../Summary/Index";
import Skills from "../Summary/Components/Skills";
import SkeletonProfile from "./Component/Skeleton";

const Styles = {
  root: {
    minHeight: "100%",
    borderRadius: 10,
    // border: "solid 1px #5B626B",
    boxShadow: "0px 0px 4px #00000040 ",
    overflow: "hidden",
  },
  basicInfo: {},
  horizontalBar: {
    marginTop: 15,
    marginBottom: 0,
  },
  topWrapper: {
    height: "120px",
    backgroundSize: "cover",
    paddingRight: 15,
    paddingTop: 15,
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: -33,
    width: 156.8,
    height: 156.8,
    // position: 'relative'
  },
  Benchmark: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: 156.8,
    height: 156.8,
    // position: 'relative'
  },
  playIcon: {
    bottom: 15,
    right: 11,
    cursor: "pointer",
  },
  actionsWrapper: {},
};

interface PropsType {
  candidate: any;
  showActions: boolean;
  showSocial: boolean;
  match?: any;
  showVideo: boolean;
  loading?: any;
  jobData?: any;
}

const PersonalInfo: React.FC<PropsType> = ({
  candidate,
  showActions,
  match,
  showSocial,
  showVideo,
  loading,
  jobData,
}) => {
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [candidate]);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const handleEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeVideoModal = () => {
    setOpenVideo(false);
  };

  const openVideoModal = () => {
    setOpenVideo(true);
  };
  const handleImageClick = () => setImagePreview(true);
  const imagePreviewCancel = () => setImagePreview(false);
  const github = candidate?.github == "" ? null : candidate?.github;
  const facebook = candidate?.facebook == "" ? null : candidate?.facebook;
  const linkedIn = candidate?.linkedIn == "" ? null : candidate?.linkedIn;
  const twitter = candidate?.twitter == "" ? null : candidate?.twitter;

  return (
    <>
      {/* <PersonalInfoDrawer candidate={candidate} open={open} handleClose={handleClose} /> */}

      {candidate?.profileVideo && (
        <ProfileVideo
          open={openVideo}
          src={candidate?.profileVideo}
          // src={candidate?.profileVideo}
          handleClose={closeVideoModal}
          candidate={candidate?.firstName + " " + candidate?.lastName}
        />
      )}
      {!isLoading && candidate ? (
        <div
          style={
            showActions === true
              ? Styles.root
              : { ...Styles.root, minHeight: "30%" }
          }
        >
          <div
            style={{
              ...Styles.topWrapper,
              backgroundImage: `url(${
                candidate?.coverPhoto ? candidate?.coverPhoto : TopBanner
              })`,
            }}
          >
            <SectionTitle
              actionHandler={handleEdit}
              showstyle={false}
              actionType="edit"
            />
          </div>
          <div style={{ display: "flex", marginLeft: "2em" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ ...Styles.avatar, position: "relative" }}>
                {candidate?.profilePicture ? (
                  <div
                    style={{
                      border: "10px solid white",
                      borderRadius: "80px",
                      width: "100%",
                      height: "100%",
                      backgroundSize: "cover",
                      overflow: "hidden",
                      backgroundPosition: "center",
                      backgroundImage: `url(${candidate?.profilePicture})`,
                    }}
                    className="img-preview-set"
                    onClick={handleImageClick}
                  >
                    <Image
                      style={{
                        borderRadius: 220,
                        backgroundSize: "cover",
                        overflow: "hidden",
                        visibility: "hidden",
                        height: "100%",
                        width: "100%",
                      }}
                      preview={false}
                      width={156.8}
                      height={156.8}
                      src={candidate?.profilePicture}
                    />
                    <div className="overlay"></div>{" "}
                    {/* Overlay element for darkness */}
                    <div className="preview-text">
                      {" "}
                      <img src={viewProfile} alt="" /> Preview
                    </div>
                  </div>
                ) : (
                  <Avatar
                    src={
                      "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg"
                    }
                    size={156.8}
                  />
                )}

                <div style={{ ...Styles.playIcon, position: "absolute" }}>
                  {showVideo === false ? (
                    <></>
                  ) : (
                    candidate?.profileVideo && (
                      <img onClick={openVideoModal} src={PlayIcon} />
                    )
                  )}
                </div>
                <div></div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {match && (
                  <Status
                    match={match}
                    candidate={candidate}
                    jobData={jobData}
                  />
                )}
              </div>
            </div>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "2em",
                marginTop: "1em",
                // width:"300px"
              }}
            >
              <BasicInfo candidate={candidate} />
              {showSocial === false ? (
                <></>
              ) : (
                <>{candidate && <Social candidate={candidate} />}</>
              )}
            </span>
            {/* <span style={{ marginLeft: "1em", marginTop: "1.5em" }}>
            <Tags candidate={candidate} onAdd={undefined} />
          </span> */}

            <Benchmark candidate={candidate} match={match} />
          </div>

          <div
            style={{
              marginTop: "3em",
              paddingLeft: "2.5em",
              paddingRight: "2em",
            }}
          >
            <Summary candidate={candidate} match={match} />
          </div>
          <div
            style={{
              paddingLeft: "2.5em",
              paddingRight: "2em",
              paddingBottom: "2em",
            }}
          >
            <Skills
              candidate={candidate}
              inDrawer={undefined}
              showskillstyle={undefined}
            />
          </div>
        </div>
      ) : (
        <SkeletonProfile />
      )}
      <Modal
        className="image-preview-modal"
        open={imagePreview}
        title={candidate?.firstName + " " + candidate?.lastName}
        footer={null}
        onCancel={imagePreviewCancel}
        centered
      >
        <img
          src={candidate?.profilePicture}
          style={{
            width: "100%",
            height: "50vh",
            objectFit: "contain",
            marginTop: "5px",
          }}
        />
      </Modal>
    </>
  );
};

export default PersonalInfo;
