export interface Notifications {
  notifications: any;
}

export interface NotificationsState {
  notifications: Notifications;
}

// Describing the different ACTION NAMES available
export const STORE_NOTIFICATIONS = "STORE_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_ALL_NOTIFICATIONS = "DELETE_ALL_NOTIFICATIONS";

interface storeNotifications {
  type: typeof STORE_NOTIFICATIONS;
  notifications: Array<object>;
}

interface addNotification {
  type: typeof ADD_NOTIFICATION;
  notification: object;
}
interface deletNotifcation {
  type: typeof DELETE_NOTIFICATION;
  notificationId: String;
}

interface deleteAllNotifications {
  type: typeof DELETE_ALL_NOTIFICATIONS;
  notifications: Array<String>;
}

export type notificationsActionTypes =
  | storeNotifications
  | addNotification
  | deletNotifcation
  | deleteAllNotifications;
