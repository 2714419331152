import { useState, useEffect } from "react";
import { useSectorsQuery } from "../graphql/generated/graphql";

const useSectors = () => {
  const [contractTypes, setContractTypes]: any = useState([]);
  const { data, loading, error } = useSectorsQuery();

  const loadSectors = () => {
    if (!loading && data) {
      // console.log(data.sectors)
      setContractTypes([...data.sectors]);
    }
  };

  useEffect(() => {
    loadSectors();
  }, [data]);

  return contractTypes;
};

export default useSectors;
