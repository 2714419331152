import React from "react";
import "./Footer.scss";

const Footer: React.FC<{ footer: boolean }> = ({ footer }) => {
  return (
    <footer className={footer ? "footer" : "m-t-40 text-center footer-login"}>
      <p className="py-0 my-0 text-burgundy" style={{ fontSize: "14px" }}>
        © {new Date().getFullYear()} Blonk Group. All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
