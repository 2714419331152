import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import IconCard from "../../../components/UI/IconCard/IconCard";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ErrorCard from "../../../components/UI/ErrorCard/ErrorCard";
import CompanyCard from "../partials/CompanyCard";
import { Row, Col, Card, Skeleton, Empty, Button } from "antd";
import { Fade } from "react-awesome-reveal";
import {
  useGetSingleCompanyQuery,
  useCompanyJobsQuery,
} from "../../../graphql/generated/graphql";
import moment from "moment";
import JobCard from "../partials/JobCard";
import { StoreCompany } from "store/companies/actions";
import { useDispatch } from "react-redux";

// const history = useHistory()

const ViewCompany = () => {
  const location = useLocation();

  let { companyId }: any = useParams();
  const dispatch = useDispatch();

  //handle view
  const [hasData, setHasData] = useState(true);

  const { data, loading, error } = useGetSingleCompanyQuery({
    variables: {
      companyId: companyId,
    },
    fetchPolicy: "network-only",
  });

  const companyJobs = useCompanyJobsQuery({
    variables: {
      companyId: companyId,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    data && data?.getSingleCompany === null && setHasData(false);
    dispatch(StoreCompany({ ...data?.getSingleCompany }));
  }, [data]);
  const editMode = location.pathname.includes("view");

  return (
    <>
      <PageTitle
        title="Company information"
        btnText="Edit company"
        to={`/companies/edit/${companyId}`}
        btnActive={true}
        // btnActive={hasData}
        back
        backURL={
          location.pathname.includes("view")
            ? "/companies"
            : `/companies/edit/${companyId}`
        }
      />

      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <Row justify="center" className="pb-4" gutter={16}>
          <Col span={18}>
            <Fade>
              <Card className="directory-card m-b-20">
                <Skeleton
                  active
                  paragraph={{ rows: 10 }}
                  className="p-4"
                  loading={loading}
                >
                  {data && data.getSingleCompany !== null ? (
                    <CompanyCard company={data?.getSingleCompany} />
                  ) : (
                    <div className="p-4 m-t-40 m-b-40">
                      <Empty description={<span>No company found</span>}>
                        <Link to={"/companies/new"}>
                          <Button
                            className="ant-btn-amber"
                            shape="round"
                            size="middle"
                          >
                            Create a new company
                          </Button>
                        </Link>
                      </Empty>
                    </div>
                  )}
                </Skeleton>
              </Card>
            </Fade>
          </Col>
          {hasData && (
            <Col span={6}>
              <IconCard
                color="primary"
                icon="far fa-calendar-alt"
                label="Company created"
                loading={loading}
              >
                {/* {company && company.createdAt} */}
                {data &&
                  data?.getSingleCompany &&
                  moment(
                    parseInt(data?.getSingleCompany?.createdAt)
                    //"Sun Jul 05 2020 23:25:04 GMT+0500 (Pakistan Standard Time)"
                  ).format("MMMM DD, YYYY")}
              </IconCard>
              <IconCard
                color="teal"
                icon="dripicons-briefcase"
                label="Total jobs"
                loading={loading}
              >
                {/* {data &&
                  data?.getSingleCompany &&
                  data?.getSingleCompany?.jobs?.length} */}
                {companyJobs?.data?.getJobs?.length}
              </IconCard>
              <IconCard
                color="amber"
                icon="dripicons-user"
                label="Total recruiters"
                loading={loading}
              >
                {data &&
                  data?.getSingleCompany &&
                  data?.getSingleCompany.users?.length}
              </IconCard>
            </Col>
          )}
        </Row>
      )}
      <Row justify="center" className="pb-4" gutter={16}>
        {companyJobs.data && <JobCard jobs={companyJobs.data?.getJobs} />}
      </Row>
    </>
  );
};

export default ViewCompany;
