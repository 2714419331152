import React, { useState, useEffect } from "react";
import {
  Card,
  Tooltip,
  Button,
  Space,
  Skeleton,
  Col,
  Empty,
  Tag,
  Row,
} from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import validURL from "../../../helpers/validURL";
import { Fade } from "react-awesome-reveal";

interface Props {
  jobs?: any;
}

const JobCard: React.FC<Props> = ({ jobs }) => {
  let { url } = useRouteMatch();

  return (
    <>
      {jobs.length > 0 ? (
        jobs.map((job: any, index: any) => {
          return (
            <Col span={8} key={index}>
              <Fade>
                <Card
                  title={
                    <Link to={`/job-listings/view/${job._id}`}>
                      {job.title}
                    </Link>
                  }
                  headStyle={{ padding: "5px" }}
                  className="directory-card directory-job-card-height-fixed m-b-2 p-4"
                >
                  <Row className="m-t-10">
                    <Col span={10} key={index}>
                      <p className="text-muted m-2">Job Fields:</p>
                    </Col>
                    <Col span={14} key={index}>
                      {job.fields.map((field: any) => (
                        <Tag
                          style={{ borderRadius: 10, marginTop: 2 }}
                          key={field._id}
                          color="#2e4a79"
                        >
                          {field.title}
                        </Tag>
                      ))}
                    </Col>
                  </Row>
                  <Row className="m-t-10">
                    <Col span={10} key={index}>
                      <p className=" text-muted m-2">Location:</p>
                    </Col>
                    <Col span={14} key={index}>
                      {job.countries.map((country: any) => (
                        <Tag
                          style={{
                            borderRadius: 10,
                            marginTop: 2,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "break-spaces",
                          }}
                          key={country._id}
                          color="#bc9623"
                        >
                          {country.name}
                        </Tag>
                      ))}
                    </Col>
                  </Row>
                  <Row className="m-t-10">
                    <Col span={10} key={index}>
                      <p className=" text-muted m-2">Contract type:</p>
                    </Col>
                    <Col span={14} key={index}>
                      <Tag
                        color="#dcdcdc;"
                        style={{
                          borderRadius: 10,
                          marginTop: 5,
                          color: "black",
                        }}
                      >
                        {job.contractType.name}
                      </Tag>
                    </Col>
                  </Row>
                  <Row className="m-t-10">
                    <Col span={10} key={index}>
                      <p className=" text-muted m-2">Total candidates:</p>
                    </Col>
                    <Col span={14} key={index}>
                      <Link to={`/candidate-listings/${job._id}`}>
                        <Button className="ant-btn-teal">
                          {/* {candidates} Candidates */}
                          {job.candidates ? job.candidates.length : 0}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Fade>
            </Col>
          );
        })
      ) : (
        <div className="p-4 m-t-40 m-b-40">
          <Empty description={<span>You did not post any job yet</span>}>
            <Link to={"/job-listings/add-job"}>
              <Button className="ant-btn-amber" shape="round" size="middle">
                Post a job
              </Button>
            </Link>
          </Empty>
        </div>
      )}
    </>
  );
};
export default JobCard;
