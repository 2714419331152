import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { useRouteMatch, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Input,
  Select,
  Table,
  Button,
  Popconfirm,
  List,
  Avatar,
  Tag,
  Tooltip,
  Space,
  Progress,
} from "antd";
import { Fade } from "react-awesome-reveal";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Column, ColumnGroup } = Table;
const { Option } = Select;

interface Props {}

const CandidateReport: React.FC<Props> = () => {
  const mustHave = (
    <Tooltip title="Must have">
      <span className="bg-teal indicitor"></span>
    </Tooltip>
  );

  const niceToHave = (
    <Tooltip title="Nice to have">
      <span className="bg-amber indicitor"></span>
    </Tooltip>
  );

  const noKnowledge = (
    <Tooltip title="No Knowledge">
      <span className="bg-burgundy indicitor"></span>
    </Tooltip>
  );
  const basic = (
    <Tooltip title="Basic Knowledge">
      <span className="bg-amber indicitor"></span>
    </Tooltip>
  );
  const intermediate = (
    <Tooltip title="Intermediate Knowledge">
      <span className="bg-teal indicitor"></span>
    </Tooltip>
  );

  const expert = (
    <Tooltip title="Expert">
      <span className="bg-primary indicitor"></span>
    </Tooltip>
  );

  const info = (
    <Tooltip title="Some comments.........">
      <span>
        <InfoCircleOutlined />
      </span>
    </Tooltip>
  );

  return (
    <>
      <PageTitle back title="Interview report" btnActive={false}>
        <h3 className="p-0 m-0">Muhammad Mustafa</h3>
        <h4 className="p-0 m-0">Mobile developer (Remote) position</h4>
      </PageTitle>
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <div className="text-right">
            <Space>
              <Button
                className="ant-btn-primary"
                htmlType="submit"
                shape="round"
                size="small"
                // onClick={() => setModalHardSkillVisible(true)}
              >
                Hire this candidate
              </Button>
              <Button
                className="ant-btn-defualt"
                htmlType="submit"
                shape="round"
                size="small"
                // onClick={() => setModalHardSkillVisible(true)}
              >
                Reject this candidate
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={12}>
          <Fade>
            <Card>
              <table>
                <tr>
                  <td>
                    <table>
                      <tr>
                        <td>
                          <table className="mb-2">
                            <tr>
                              <td>
                                <b className="pr-4">Interview date</b>
                              </td>
                              <td>&nbsp;</td>
                              <td className="pr-3 w-150">02 Sept 2020</td>
                              <td className="pr-3 w-150">12 Sept 2020</td>
                              <td className="w-150">21 Sept 2020</td>
                            </tr>
                            <tr>
                              <td className="w-150">
                                <b className="pr-4">Interviewer</b>
                              </td>
                              <td>&nbsp;</td>
                              <td className="pr-3 w-150">Waqas Mumtaz</td>
                              <td className="pr-3 w-150">Ahmad Ali</td>
                              <td className="w-150">Vincent Mailard</td>
                            </tr>
                            <tr>
                              <td className="w-150">
                                <b className="pr-4">Interview type</b>
                              </td>
                              <td>&nbsp;</td>
                              <td className="pr-3">Introductory call</td>
                              <td className="pr-3">Technical call</td>
                              <td>Final call</td>
                            </tr>
                            <tr>
                              <td className="w-150">
                                <b className="pr-4">Overall assessment</b>
                              </td>
                              <td className="pr-3">&nbsp;</td>

                              <td className="pr-3">{expert}</td>
                              <td>{intermediate}</td>
                              <td>{noKnowledge}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="ptb-2">&nbsp;</td>
                      </tr>
                      <tr className="skill-cat">
                        <td colSpan={4}>
                          <b>Hard skills</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Graphic Design</td>
                                <td colSpan={3}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Adobe Photopshop</td>
                                <td>{mustHave}</td>
                                <td className="w-150">{intermediate}</td>
                                <td className="w-150">{expert}</td>
                                <td className="w-150">{intermediate}</td>
                              </tr>
                              <tr>
                                <td>Adobe Illustrator</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{basic}</td>
                                <td>{noKnowledge}</td>
                                <td>{intermediate}</td>
                              </tr>
                              <tr>
                                <td>Adobe Xd</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{noKnowledge}</td>
                                <td>{noKnowledge}</td>
                                <td>{noKnowledge}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Frontend </td>
                                <td colSpan={3}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Html /CSS</td>
                                <td>{mustHave}</td>
                                <td className="w-150">{intermediate}</td>
                                <td className="w-150">{expert}</td>
                                <td className="w-150">{basic}</td>
                              </tr>
                              <tr>
                                <td>Bootstrap</td>
                                <td>{niceToHave}</td>
                                <td className="w-150">{intermediate}</td>
                                <td className="w-150">{basic}</td>
                                <td className="w-150">{intermediate}</td>
                              </tr>
                              <tr>
                                <td>React Js</td>
                                <td>{niceToHave}</td>
                                <td className="w-150">{intermediate}</td>
                                <td className="w-150">{basic}</td>
                                <td className="w-150">{basic}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Backend </td>
                                <td colSpan={3}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Node js</td>
                                <td>{mustHave}</td>
                                <td className="w-150">{intermediate}</td>
                                <td className="w-150">{noKnowledge}</td>
                                <td className="w-150">{basic}</td>
                              </tr>
                              <tr>
                                <td>Express js</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{intermediate}</td>
                                <td>{basic}</td>
                                <td>{basic}</td>
                              </tr>
                              <tr>
                                <td>REST</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{basic}</td>
                                <td>{intermediate}</td>
                                <td>{expert}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                      <tr>
                        <td className="ptb-2">&nbsp;</td>
                      </tr>
                      <tr className="skill-cat">
                        <td colSpan={4}>
                          <b>Soft skills</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Execution </td>
                                <td colSpan={3}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Team Management</td>
                                <td>{mustHave}</td>
                                <td className="w-150">{expert}</td>
                                <td className="w-150">{intermediate}</td>
                                <td className="w-150">{noKnowledge}</td>
                              </tr>
                              <tr>
                                <td>Project Management</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{intermediate}</td>
                                <td>{intermediate}</td>
                                <td>{basic}</td>
                              </tr>
                              <tr>
                                <td>Creativity</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{intermediate}</td>
                                <td>{intermediate}</td>
                                <td>{expert}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                    </table>
                    {/* inner table ends */}
                  </td>
                  {/* requirements td ends */}
                </tr>
              </table>
              {/* main table ends */}
            </Card>
          </Fade>
        </Col>
        <Col span={12}>
          <Fade>
            <Card>
              <h4>Waqas Mumtaz's comments</h4>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ratione velit sunt nisi fugiat vero porro.{" "}
                </li>
                <li>
                  Soluta itaque unde dicta cum error obcaecati omnis ullam,
                  aperiam maiores, quos eos ut id.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptates illo tenetur ex corrupti nihil omnis numquam
                  necessitatibus quo.{" "}
                </li>
                <li>
                  Ipsa molestias saepe accusamus nostrum vitae in inventore
                  error alias libero vero!
                </li>
              </ul>
            </Card>
            <Card>
              <h4>Ahmad Ali's comments</h4>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ratione velit sunt nisi fugiat vero porro.{" "}
                </li>
                <li>
                  Soluta itaque unde dicta cum error obcaecati omnis ullam,
                  aperiam maiores, quos eos ut id.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptates illo tenetur ex corrupti nihil omnis numquam
                  necessitatibus quo.{" "}
                </li>
                <li>
                  Ipsa molestias saepe accusamus nostrum vitae in inventore
                  error alias libero vero!
                </li>
              </ul>
            </Card>
            <Card>
              <h4>Vincent Mailard's comments</h4>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ratione velit sunt nisi fugiat vero porro.{" "}
                </li>
                <li>
                  Soluta itaque unde dicta cum error obcaecati omnis ullam,
                  aperiam maiores, quos eos ut id.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptates illo tenetur ex corrupti nihil omnis numquam
                  necessitatibus quo.{" "}
                </li>
                <li>
                  Ipsa molestias saepe accusamus nostrum vitae in inventore
                  error alias libero vero!
                </li>
              </ul>
            </Card>
          </Fade>
        </Col>
      </Row>
    </>
  );
};

export default CandidateReport;
