import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";

const MainApp: React.FC = () => {
  const { path } = useRouteMatch();
// Singular OCmponent
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Redirect to="/companies" />
        </Route>
      </Switch>
    </>
  );
};

export default MainApp;
