import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import JobDescription from "../ViewJob/partials/JobDescription";
import { useRouteMatch, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Input,
  Select,
  Button,
  Table,
  Typography,
  Popconfirm,
  List,
  Avatar,
  Form,
  Tooltip,
  Space,
  Progress,
} from "antd";
import { Fade } from "react-awesome-reveal";
const { Column, ColumnGroup } = Table;
const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

interface Props {}

const CandidateInterview: React.FC<Props> = () => {
  const select = (
    <Select defaultValue="No knowledge" className="interview-asses">
      <Option value="No knowledge">No knowledge</Option>
      <Option value="Basic">Basic</Option>
      <Option value="Intermediate">Intermediate</Option>
      <Option value="Expert">Expert</Option>
    </Select>
  );
  const comment = (
    <Input className="interview-comments" placeholder="add comments" />
  );
  const mustHave = (
    <Tooltip title="Must have">
      <span className="bg-teal indicitor"></span>
    </Tooltip>
  );
  const niceToHave = (
    <Tooltip title="Nice to have">
      <span className="bg-amber indicitor"></span>
    </Tooltip>
  );
  return (
    <>
      <PageTitle back title="Muhammad Mustafa" btnActive={false}>
        <h4 className="p-0 m-0">
          Technical Interiview call for the position of Mobile develoepr
        </h4>
        <p className="p-0 m-0">
          <span className="text-primary">Interview date:</span> 23 September
          2020 @ 4:15 PM
        </p>
        <p className="p-0 m-0">
          <span className="text-primary">Interviewer:</span> Ahmad Ali
        </p>
      </PageTitle>
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <div className="text-right">
            <Space>
              <Button
                className="ant-btn-primary"
                htmlType="submit"
                shape="round"
                size="small"
                // onClick={() => setModalHardSkillVisible(true)}
              >
                Save Data
              </Button>
              <Button
                className="ant-btn-defualt"
                htmlType="submit"
                shape="round"
                size="small"
                // onClick={() => setModalHardSkillVisible(true)}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={10}>
          <Fade>
            <Card>
              <table>
                <tr>
                  <td>
                    <table>
                      <tr>
                        <td>
                          <table className="mb-2">
                            <tr>
                              <td colSpan={2}>
                                <b className="pr-4">Overall assessment</b>
                              </td>

                              <td className="pr-2">{select}</td>
                              <td>{comment}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="ptb-2">&nbsp;</td>
                      </tr>
                      <tr className="skill-cat">
                        <td colSpan={4}>
                          <b>Hard skills</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Graphic Design</td>
                                <td colSpan={2}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Adobe Photopshop</td>
                                <td>{mustHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>Adobe Illustrator</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>Adobe Xd</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Frontend </td>
                                <td colSpan={2}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Html /CSS</td>
                                <td>{mustHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>Bootstrap</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>React Js</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Backend </td>
                                <td colSpan={2}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Node js</td>
                                <td>{mustHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>Express js</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>REST</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                      <tr>
                        <td className="ptb-2">&nbsp;</td>
                      </tr>
                      <tr className="skill-cat">
                        <td colSpan={4}>
                          <b>Soft skills</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table className="skills-table">
                            <thead>
                              <tr>
                                <td colSpan={2}>Execution </td>
                                <td colSpan={2}>Assessment</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Team Management</td>
                                <td>{mustHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>Project Management</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                              <tr>
                                <td>Creativity</td>
                                <td>{niceToHave}</td>
                                <td className="pr-2">{select}</td>
                                <td>{comment}</td>
                              </tr>
                            </tbody>
                          </table>
                          {/* skills table ends */}
                        </td>
                      </tr>
                    </table>
                    {/* inner table ends */}
                  </td>
                  {/* requirements td ends */}
                </tr>
              </table>
              {/* main table ends */}
            </Card>
          </Fade>
        </Col>
        <Col span={14}>
          <Row>
            <Col span={24}>
              <Fade>
                <Card>
                  <Form layout="vertical">
                    <Form.Item label="Notes:" name="requiredMark">
                      <TextArea rows={4} />
                    </Form.Item>
                  </Form>
                </Card>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Fade>
                <Card>
                  <div>
                    <Title className="mt-0 text-primary header-title" level={4}>
                      Resume
                    </Title>

                    <iframe
                      src="https://drive.google.com/viewerng/viewer?embedded=true&amp;url=http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&amp;scrollbar=0"
                      scrolling="auto"
                      height="380px"
                      width="100%"
                    ></iframe>
                  </div>
                </Card>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Fade>
                <Card style={{ height: "400px", overflowY: "scroll" }}>
                  <JobDescription />
                </Card>
              </Fade>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CandidateInterview;
