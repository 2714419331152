import React, { useEffect, useState } from "react";
import { Tooltip, Typography } from "antd";
import { ResetIcon } from "assets/icons/DetailedProfile";

import { useLocation } from "react-router-dom";
// import { ResetPassword } from '../../Edit';

const { Title } = Typography;

interface PropsType {
  candidate: any;
}

const Styles = {
  firstLastName: {
    fontSize: 18,
    fontWeight: 400,
    // textAlign: "center",
  },
  resetIcon: {
    cursor: "pointer",
    marginLeft: 10,
  },
};

const BasicInfo: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openDrawer = () => {
    setOpen(true);
  };
  let last = candidate?.experiences?.length - 1;
  let title = candidate?.experiences[last];

  return (
    <>
      {/* <ResetPassword handleClose={handleClose} open={open} candidate={candidate} /> */}
      <div
        style={{
          marginTop: 10,
          marginLeft: 15,
          width: "100%",
          // maxWidth: 228,
          wordWrap: "break-word",
        }}
      >
        <Title
          style={{
            marginTop: 0,
            fontSize: 20,
            fontWeight: 600,
            textAlign: "start",
            color: "#012653",
          }}
        >
          {candidate?.firstName} {candidate?.lastName}
        </Title>
        <Title
          style={{
            marginTop: 0,
            fontSize: 16,
            fontWeight: 600,
            textAlign: "start",
            color: "#000000D9",
            // paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <>{candidate?.position && candidate?.position}</>
        </Title>
        <Title
          style={{
            marginTop: -7,
            fontSize: 14,
            fontWeight: 400,
            textAlign: "start",
            color: "#000000D9",
            // paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {candidate?.company != null && (
            <span style={{ color: "rgba(0, 0, 0, 0.85)" }}></span>
          )}
          <span
            style={{
              fontWeight: 400,
              color: "#000000",
              fontSize: 14,
              textAlign: "start",
            }}
          >
            {candidate?.company && (
              <span
                style={{ color: "#000000D9", fontSize: 14, fontWeight: 700 }}
              >
                @{candidate?.company}
              </span>
            )}{" "}
            {candidate?.currentLocation?.city &&
            candidate?.currentLocation?.country
              ? "| " +
                candidate?.currentLocation?.city +
                ", " +
                candidate?.currentLocation?.country
              : (candidate?.currentLocation?.city == null ||
                  candidate?.currentLocation?.city == undefined ||
                  candidate?.currentLocation?.city == "") &&
                candidate?.currentLocation?.country
              ? "| " + candidate?.currentLocation?.country
              : ""}
          </span>
        </Title>
      </div>
    </>
  );
};

export default BasicInfo;
