import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { Row, Col, Card, Skeleton } from "antd";
import { Fade, Bounce } from "react-awesome-reveal";
import CompanyCard from "../partials/CompanyCard";
import ErrorCard from "../../../components/UI/ErrorCard/ErrorCard";
import { useCompaniesQuery } from "../../../graphql/generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { StoreCompanies } from "../../../store/companies/actions";
import NotificationModal from "components/NotificationMatchModal/NotificationModal";
import firebase from "../../../firebase";
import { useGetMatchPopUpQuery } from "../../../graphql/generated/graphql";
interface Notification {
  type: string;
}
const CompanyList: React.FC = () => {
  const [isMatchModal, setIsMatchModal] = useState<any>(true);
  const [matchNotify, setMatchNotify]: any = useState([]);
  const [candidateListings, setCandidateListing] = useState([]);
  const [chatNotifications, setchatNotifications] = useState([]); //this will contain all the notifications regarding the chat

  const handleCancel = () => {
    setIsMatchModal(false);
  };
  const { notifications } = useSelector((state: any) => state.notifications);
  useEffect(() => {
    const chatNotifications = notifications.filter(
      (notification: Notification) => notification.type === "chat"
    );
    setchatNotifications([...chatNotifications]);
    // we will only save the match notifications in this arrray
    return () => {};
  }, [notifications]);
  // data from end point
  const [modalVisibilities, setModalVisibilities] = useState([]);

  let history = useHistory();
  //getting user from localStorage
  const stringifyUserObject: any = localStorage.getItem("user");
  const user = JSON.parse(stringifyUserObject);
  const dispatch = useDispatch();

  //fetching data

  //getting notification data from firebase for showing modal
  const fetchNotificationBadge = async () => {
    const query = firebase?.collection("notificationsMain")?.doc(user?._id);
    const snapshot = await query.get();
    const notificationDocument = snapshot.data();
    if (snapshot?.data()?.notificationsData) {
      setMatchNotify(snapshot?.data()?.notificationsData);
    }
  };
  useEffect(() => {
    fetchNotificationBadge();
  }, [notifications]);
  useEffect(() => {
    const candidateListings = matchNotify
      ?.filter((cd: any) => cd?.senderId !== user?._id) // Filtering based on senderId
      .map((cd: any) => ({
        candidateId: cd?.candidateId,
        companyId: cd?.companyId,
        jobId: cd?.jobId,
        matchId: cd?.matchId,
      })); // Create new objects

    setCandidateListing(candidateListings);
  }, [matchNotify]);

  const {
    data: matchData,
    loading: matchLoading,
    error: m_error,
  } = useGetMatchPopUpQuery({
    variables: {
      candidateId: candidateListings,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (matchData?.getMatchPopUp) {
      // Initialize modal visibilities with 'true' for each modal
      const initialVisibilities = new Array(
        matchData.getMatchPopUp.length
      ).fill(true);
      setModalVisibilities(initialVisibilities);
    }
  }, [matchData]);
  const handleOpenModal = (index) => {
    const updatedVisibilities = [...modalVisibilities];
    updatedVisibilities[index] = true;
    setModalVisibilities(updatedVisibilities);
  };

  const handleCloseModal = (index) => {
    const updatedVisibilities = [...modalVisibilities];
    updatedVisibilities[index] = false;
    setModalVisibilities(updatedVisibilities);
  };

  const { data, loading, error } = useCompaniesQuery({
    fetchPolicy: "network-only",
  });

  const [displayContent, setDisplayContent]: any = useState(
    <Col span={24}>
      <Bounce>
        <Card>
          <Skeleton active paragraph={{ rows: 7 }} />
        </Card>
      </Bounce>
    </Col>
  );

  useEffect(() => {
    if (!loading && error) {
      setDisplayContent(
        <Col span={12}>
          <ErrorCard />
        </Col>
      );
    }

    if (!loading && data) {
      if (data.userCompanies.length === 0) {
        history.push("/companies/new");
      }
      dispatch(StoreCompanies(data.userCompanies));
      const result = data.userCompanies.map((company, index) => (
        <Col span={7} key={index}>
          <Fade>
            <Card className="directory-card directory-card-height-fixed m-b-20">
              <CompanyCard trimText={true} company={company} />
            </Card>
          </Fade>
        </Col>
      ));
      setDisplayContent(result);
    }
  }, [data]);

  return (
    <>
      <PageTitle
        title="List of companies"
        btnText="Add company"
        to="/companies/new"
        btnActive={true}
      />
      {matchData?.getMatchPopUp?.map((cd, index) => (
        <div key={index}>
          <NotificationModal
            showMatchModal={modalVisibilities[index]}
            handleCancel={() => handleCloseModal(index)}
            selectedCandidate={cd}
            showChatModal={true} // You might need to determine this based on the specific case
            chatNotifications={chatNotifications}
            data={cd}
            clickOnNotification={cd}
          />
        </div>
      ))}

      <Row justify="center" className="pb-4" gutter={16}>
        {displayContent}
      </Row>
    </>
  );
};

export default CompanyList;
