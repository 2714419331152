import React, { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Logo from "../../../components/UI/Logo/Logo";
import Footer from "../../../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";

import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useResetPasswordMutation } from "../../../graphql/generated/graphql";

const Login = () => {
  //error state to control form
  const [hasError, setHasError] = useState(false);
  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();
  const params: { email: string } = useParams();
  // console.log(params);
  const navigate = useHistory();

  //on submit login form
  const onFinish = (values: any) => {
    resetPasswordMutation({
      variables: {
        email: params.email.toLowerCase(),
        password: values.password,
      },
    })
      .then(({ data }) => {
        notification["success"]({
          message: "Your password has been successfully changed.",
        });
        navigate.push("/login"); // Redirect to the login page
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to Login",
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  return (
    <>
      <div className="bg-primary" style={{ height: "100vh" }}>
        <div className="wrapper">
          <Row>
            <Col span={14}>
              <div className="text-center m-t-40">
                <img
                  src="http://blonk.co/demo/SOBlonk/assets/images/login-image.png"
                  className="img-fluid m-t-10"
                  alt=""
                />
              </div>
            </Col>
            <Col span={10}>
              <Card
                style={{
                  minHeight: "100vh",
                  marginBottom: "0px",
                  borderRadius: "0",
                }}
              >
                <div className="pb-4">
                  <h3 className="text-center m-t-40">
                    <Logo height="40" />
                  </h3>
                  <div className="p-3">
                    <p className="text-muted text-center pt-0 mtp0">
                      Do you remember your password ?{" "}
                      <Link to="/login" className="text-primary">
                        Login now
                      </Link>
                    </p>
                    <Fade>
                      <Form
                        name="normal_login"
                        className="login-form"
                        layout="vertical"
                        onFieldsChange={() => setHasError(false)}
                        onFinish={onFinish}
                      >
                        <Form.Item
                          name="password"
                          label="Password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your new password!",
                            },
                            {
                              whitespace: true,
                              message: "No whitespaces allowed",
                            },
                            {
                              min: 4,
                              message:
                                "Passwords must contain at least 4 characters!",
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            type="password"
                            placeholder="Enter your password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="confirm"
                          label="Confirm Password"
                          dependencies={["password"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            {
                              whitespace: true,
                              message: "No whitespaces allowed",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("The two passwords do not match!")
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            type="password"
                            placeholder="Confirm your password"
                          />
                        </Form.Item>
                        <Form.Item>
                          <div className="text-right">
                            <Button
                              htmlType="submit"
                              className="ant-btn-amber"
                              shape="round"
                              disabled={hasError}
                              loading={loading}
                            >
                              Reset password
                            </Button>
                          </div>
                        </Form.Item>
                      </Form>
                    </Fade>
                  </div>
                </div>
                <Footer footer={false} />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Login;
