import { useState, useEffect } from "react";
import { useSkillsQuery } from "../graphql/generated/graphql";

const useSkills = () => {
  const [skills, setskills]: any = useState([]);
  const { data, loading, error } = useSkillsQuery({
    variables: {
      status: "Approved",
    },
  });

  const loadskills = () => {
    if (!loading && data) {
      // console.log(data.skills);
      setskills([...data.skills]);
    }
  };

  useEffect(() => {
    loadskills();
  }, [data]);

  return skills;
};

export default useSkills;
