import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AuthState } from "../../../store/auth/types";
import { Redirect } from "react-router-dom";
import { Card, Row, Col, Affix, notification } from "antd";
import RegistrationForm from "./partials/RegistrationForm";
import RegistrationSuccess from "./partials/RegistrationSuccess";
import Logo from "../../../components/UI/Logo/Logo";
import Footer from "../../../components/Footer/Footer";
import { useRecruiterSignupMutation } from "../../../graphql/generated/graphql";
import Signup from "assets/signup.svg";
import "./styles.scss";
const Registration: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: AuthState) => state.auth.accessToken !== null
  );

  //error state to control form
  const [hasError, setHasError] = useState(false);
  const [signupMutation, { data, loading, error }] =
    useRecruiterSignupMutation();
  const [fetchData, setFetchData] = useState(Object);
  const [location, setLocation] = useState<any>(Object);
  const { authRedirectPath } = useSelector((state: AuthState) => state.auth);

  const onFinishHandler = (values: any, location: any) => {
    // let { location } = values;
    let countryVal = location?.split(",");
    location = {
      country:
        countryVal && countryVal.length > 1 ? countryVal[1] : countryVal[0],
      city: countryVal && countryVal.length >= 2 ? countryVal[0] : "",
    };
    setLocation({
      country:
        countryVal && countryVal.length > 1 ? countryVal[1] : countryVal[0],
      city: countryVal && countryVal.length >= 2 ? countryVal[0] : "",
    });
    values.location = location;
    console.log("----Location is here", values);

    signupMutation({
      variables: {
        fullName: values.firstName + " " + values.lastName,
        email: values.emailAddress.toLowerCase(),
        password: values.password,
        title: values.title,
        company: values?.companyName,
        location: values?.location,
      },
    })
      .then(({ data }) => {
        setFetchData(data?.recruiterSignup?._id);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to Login",
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  return (
    <>
      {isAuthenticated && <Redirect to={authRedirectPath} />}
      <div className="bg-primary" style={{ minHeight: "100vh" }}>
        <div className="wrapper">
          <Row>
            <Col span={12}>
              <Affix offsetTop={40}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20%",
                    marginRight: "10%",
                  }}
                >
                  <img src={Signup} alt="Registration" />
                </div>
              </Affix>
            </Col>
            <Col span={12}>
              <Card
                style={{
                  minHeight: "100vh",
                  marginBottom: "0px",
                  borderRadius: "0",
                }}
              >
                <div className="pb-4">
                  <div className="p-3">
                    {data && data.recruiterSignup?._id ? (
                      <RegistrationSuccess
                        fullName={data?.recruiterSignup?.fullName}
                        userId={data?.recruiterSignup?.userId}
                      />
                    ) : (
                      <RegistrationForm
                        onFinish={onFinishHandler}
                        isLoading={loading}
                        hasError={hasError}
                      />
                    )}
                  </div>
                </div>
                <Footer footer={false} />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Registration;
