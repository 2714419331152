import React from "react";

import {
  Row,
  Col,
  Drawer,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
} from "antd";

interface Props {
  show: boolean;
  hide: any;
}

const ScheduleInterview: React.FC<Props> = ({ show, hide }) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  return (
    <>
      <Drawer
        title="Schedule an Interview"
        placement="right"
        closable={true}
        onClose={hide}
        visible={show}
        width={450}
        className="menu-drawer"
      >
        <Row justify="center" className="mt-3">
          <Col span={22}>
            <Form
              name="registration-form"
              layout="vertical"
              form={form}
              scrollToFirstError
            >
              <Form.Item
                name="interviewTitle"
                label="Interview title"
                rules={[
                  {
                    required: true,
                    message: "Please enter interview title!",
                  },
                ]}
                hasFeedback
              >
                <Input type="text" placeholder="Enter interview title" />
              </Form.Item>
              <Form.Item
                name="interviewType"
                label="Interview Type"
                rules={[
                  {
                    required: true,
                    message: "Please select interview type!",
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  placeholder="Select interview type"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="initial">Initial</Option>
                  <Option value="technical">Technical</Option>
                  <Option value="final">Final</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="candidate"
                label="Candidate"
                rules={[
                  {
                    required: true,
                    message: "Please enter interviewer!",
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  placeholder="Select candidate"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="jack">Mustafa</Option>
                  <Option value="lucy">Shaheer</Option>
                  <Option value="tom">Areeb Vohra</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="interviewer"
                label="Select interviewer"
                rules={[
                  {
                    required: true,
                    message: "Please enter interviewer!",
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  placeholder="Select interviewer"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="jack">Vincent</Option>
                  <Option value="lucy">Ahmad</Option>
                  <Option value="tom">Waqas</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="interviewTime"
                label="Interview time"
                rules={[
                  {
                    required: true,
                    message: "Please enter interview time!",
                  },
                ]}
                hasFeedback
              >
                <DatePicker showTime />
              </Form.Item>
              <Form.Item
                name="googleLink"
                label="Google Meet"
                rules={[
                  {
                    required: true,
                    message: "Please enter google meet link!",
                  },
                ]}
                hasFeedback
              >
                <Input type="text" placeholder="Enter google meet link" />
              </Form.Item>
              <div className="text-right py-2">
                <Button className="ant-btn-primary" shape="round">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default ScheduleInterview;
