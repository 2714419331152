import React, { useContext, useRef, useState } from "react";
import { StarIcon } from "assets/icons/DetailedProfile";
import { SectionTitle } from "../../Shared";
import { Tag, Tooltip, Typography } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import "../../styles.scss";

interface PropsType {
  candidate: any;
  inDrawer: any;
  onAdd?: any;
  showSkills?: any;
  showSeeMore?: any;
  showskillstyle: any;
}

const Skills: React.FC<PropsType> = ({
  candidate,
  inDrawer,
  onAdd,
  showSkills = true,
  showSeeMore = true,
  showskillstyle,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [wrapperHeight, setWrapperHeight] = React.useState<any>(0);

  const Skill = ({ title }: any) => {
    return (
      <Tag
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
        onClose={(e) => e.preventDefault()}
        style={{
          border: "none",
          marginRight: 10,
          marginBottom: 10,
          backgroundColor: "rgba(18, 100, 121, 0.8)",
          borderRadius: 20,
          color: "white",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "Poppins",
        }}
      >
        {title}
      </Tag>
    );
  };

  const SeeMore = () => {
    return (
      <Tag onClick={() => setIsSeeMore(!isSeeMore)} className="see-more-btn">
        {isSeeMore ? "See less" : "See more"}
      </Tag>
    );
  };

  React.useEffect(() => {
    setWrapperHeight(wrapperRef.current?.clientHeight);
  });

  return (
    <>
      {candidate?.skills?.length > 0 && (
        <div>
          {showSkills && candidate && (
            <>
              <Typography
                style={{
                  fontWeight: "600",
                  color: "#012653",
                  fontSize: "16px",
                }}
              >
                Skills
              </Typography>
              {candidate?.skills != null ? (
                candidate?.skills.length != 0 ? (
                  <div
                    ref={wrapperRef}
                    style={
                      showskillstyle === true
                        ? {
                            // paddingLeft: 25,
                            paddingTop: 10,
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            // marginLeft:'10px'
                          }
                        : {
                            // paddingLeft: 25,
                            paddingTop: 10,
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }
                    }
                  >
                    {candidate?.skills != null &&
                      candidate?.skills.map((skill: any, index: any) => (
                        <>
                          {/* {showSeeMore == true ? (
                        <>
                          {index < 5 || isSeeMore ? (
                            <Skill title={skill.title} />
                          ) : null}
                        </>
                      ) : ( */}
                          <>
                            {
                              // index < 5 || isSeeMore ? (
                              <Skill title={skill.title} />
                              // ) : null
                            }
                          </>
                          {/* )} */}
                        </>
                      ))}
                    {/* {showSeeMore == true ? (
                  <>
                    {candidate?.skills.length > 5 && !inDrawer ? (
                      <SeeMore />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>{candidate?.skills.length > 5 && !inDrawer ? "" : ""}</>
                )} */}
                  </div>
                ) : (
                  <Typography style={{ color: "gray", marginLeft: 25 }}>
                    No skills provided by the candidate
                  </Typography>
                )
              ) : (
                <Typography style={{ color: "gray" }}>
                  No skills provided by the candidate
                </Typography>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Skills;
