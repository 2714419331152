import { useState, useEffect } from "react";
import { useCurrenciesQuery } from "../graphql/generated/graphql";

const useCurrencies = () => {
  const [currencies, setCurrencies]: any = useState([]);
  const { data, loading, error } = useCurrenciesQuery();

  const loadCurrencies = () => {
    if (!loading && data) {
      // console.log(data.currencies)
      setCurrencies([...data.currencies]);
    }
  };

  useEffect(() => {
    loadCurrencies();
  }, [data]);

  return currencies;
};

export default useCurrencies;
