import { Card, Divider, Typography } from "antd";
import { CompensationIcon } from "assets/icons/DetailedProfile";
import React, { useContext, useState } from "react";
import { CompensationIcon2 } from "assets/icons/DetailedProfile";
const { Title } = Typography;

interface PropsType {
  candidate: any;
  nocomments: any;
  match?: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #364972",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
  subBody: {
    paddingLeft: 16,
    marginTop: 10,
  },
  smallText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#495057",
  },
  primaryText: {
    color: "#344976",
  },
  CompensationColor: {
    color: "#2E4A79",
  },
  commentBox: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: "#E7F1F9",
    marginTop: 12,
    borderRadius: 9,
    marginBottom: 10,
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.14)",
    border: "solid 0.5px #DADADA",
  },
};

const Fields = [
  { key: "base", value: "Base" },
  { key: "onTargetEarnings", value: "On target earnings" },
  { key: "shortTermIncentives", value: "Short term incentives" },
  { key: "longTermIncentives", value: "Long term incentives" },
  { key: "other", value: "Other" },
];

const YearlyCompensation: React.FC<PropsType> = ({
  candidate,
  nocomments,
  match,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = () => {
    setOpen(true);
  };
  //conversion function
  const conversion = (value: any) => {
    if (value > 1000 && value != undefined) {
      return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return value?.toString();
    }
  };
  const formattedCurrentComment = candidate?.comments?.replace(/\n/g, "<br />");

  return (
    <>
      {/* <YearlyCompensationDrawer open={open} candidate={candidate} handleClose={handleClose} /> */}
      {candidate != null && (
        <Card className="mb-2 displayCards">
          <Title className="mt-0 text-primary" style={{ fontSize: "18px" }}>
            <img
              src={CompensationIcon2}
              alt="compensation icon"
              style={{ marginRight: "10px" }}
            />
            Yearly compensation offer
          </Title>
          <Divider style={{ margin: "10px 0px" }} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {candidate?.total != null && (
              <>
                <span style={Styles.smallText}>
                  Total
                  <span
                    style={{ fontWeight: "400" }}
                  >{`(in ${candidate?.currency})`}</span>
                </span>
                <span
                  style={{
                    ...Styles.smallText,
                    // paddingRight: 40,
                    display: "block",
                    width: 115,
                    ...Styles.CompensationColor,
                  }}
                >
                  {conversion(candidate?.total) + "k"}
                  {/* {candidate?.currency} */}
                </span>
              </>
            )}
          </div>
          <div style={Styles.subBody}>
            {Fields.map((field: any, index: any) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <>
                  {candidate[field.key] != null && (
                    <>
                      <span style={Styles.smallText}>
                        {candidate[field.key] > "0" ? field.value : ""}
                      </span>
                      <span
                        style={{
                          ...Styles.smallText,
                          ...Styles.CompensationColor,
                        }}
                      >
                        {candidate[field.key] > "0" && field?.value !== "Other"
                          ? conversion(candidate[field.key]) + "k"
                          : field?.value == "Other"
                          ? conversion(candidate[field.key])
                          : ""}

                        {/* for number only */}
                        {/* {candidate?.current[
                              field?.key
                            ] > "0" && field?.value
                              ? conversion(
                                  candidate?.current[
                                    field?.key
                                  ]
                                ) + "k"
                              : ""} */}
                      </span>
                    </>
                  )}
                </>
              </div>
            ))}
          </div>

          {nocomments === false ? (
            <></>
          ) : (
            candidate?.comments != null && (
              <>
                {candidate?.comments?.length > 0 ? (
                  <div style={Styles.commentBox}>
                    <p
                      style={{
                        fontSize: 14,
                        marginTop: 0,
                        fontWeight: 400,
                        color: "#012653",
                        marginBottom: 3,
                      }}
                    >
                      Comments
                    </p>
                    <span
                      style={{
                        fontSize: 12,
                        color: "#495057",
                        fontWeight: 400,
                        fontStyle: "italic",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: formattedCurrentComment,
                      }}
                    >
                      {/* {candidate?.comments} */}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            )
          )}
        </Card>
      )}
    </>
  );
};

export default YearlyCompensation;
