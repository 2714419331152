import React from "react";
import { Drawer, Input, Form, Button, Divider, Col, Row } from "antd";
const { TextArea } = Input;
interface Props {
  show: boolean;
  hide: any;
  setSummary: any;
  summary: any;
  loading: any;
  updateRecruiter: any;
}

const SummaryFormDrawer: React.FC<Props> = ({
  show,
  hide,
  summary,
  setSummary,
  updateRecruiter,
  loading,
}) => {
  // console.log('Summary: ', summary)
  return (
    <>
      <Drawer
        title="My summary"
        placement="right"
        closable={true}
        onClose={hide}
        visible={show}
        width={500}
        className="menu-drawer"
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          justify="center"
          className="pt-4"
        >
          <Col md={22}>
            <Form layout="vertical" name="recruiter-summary">
              <Form.Item label="Self-presentation" name="summary">
                <TextArea
                  rows={12}
                  defaultValue={summary}
                  onChange={(e) => setSummary(e.target.value)}
                ></TextArea>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ant-btn-teal float-right"
                  shape="round"
                  size="middle"
                  loading={loading}
                  onClick={() => updateRecruiter({ description: summary })}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default SummaryFormDrawer;
