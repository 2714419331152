import React from "react";
import "./Buttons.scss";
import { Tooltip } from "antd";

interface Props {
  type: string;
  enableToolTip?: boolean;
  toolTipTitle?: string;
  onClick?: Function;
}

const Buttons: React.FC<Props> = ({
  type,
  enableToolTip = true,
  toolTipTitle,
  onClick,
}) => {
  return (
    <>
      {enableToolTip ? (
        <Tooltip title={toolTipTitle}>
          <div
            className={`${type}`}
            onClick={() => (onClick ? onClick() : null)}
          ></div>
        </Tooltip>
      ) : (
        <div
          className={`${type}`}
          onClick={() => (onClick ? onClick() : null)}
        ></div>
      )}
    </>
  );
};

export default Buttons;
