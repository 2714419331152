import React from "react";
import { Avatar } from "antd";

interface PropsType {
  education: any;
  isRecruiterPage: boolean;
}

const calculateYearsSpent = (
  startYear: string,
  endYear: string | null,
  isCurrent: boolean
): number => {
  if (!startYear) return 0;
  const start = parseInt(startYear.split("-")[0], 10);
  const end = isCurrent
    ? new Date().getFullYear()
    : endYear
    ? parseInt(endYear.split("-")[0], 10)
    : start;
  return end - start;
};

const calculateTotalYearsSpent = (experience: any): number => {
  let totalYears = calculateYearsSpent(
    experience.startYear,
    experience.endYear,
    experience.current
  );

  // if (experience.mergedData && experience.mergedData.length > 0) {
  //   experience.mergedData.forEach((d: any) => {
  //     totalYears += calculateYearsSpent(d.startYear, d.endYear, false);
  //   });
  // }

  return totalYears;
};

const SingleEducation: React.FC<PropsType> = ({
  education,
  isRecruiterPage,
}) => {
  const Styles = {
    root: {
      paddingLeft: isRecruiterPage ? 4 : 0,
      display: "inline-block",
      maxWidth: 400,
      flex: "85%",
    },
    wrapper: {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      marginBottom: 26,
      marginTop: "-8px",
    },
    year: {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    position: {
      fontWeight: 500,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "black",
    },
    company: {
      fontWeight: 600,
      fontSize: 16,
      marginTop: 0,
      marginBottom: 0,
      color: "black",
    },
    location: {
      fontWeight: 400,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    avatar: {
      backgroundColor: "#F5F7F9",
    },
    totalYears: {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgba(0,0,0,.5)",
      marginTop: "-1px",
    },
  };

  const totalYearsSpent = calculateTotalYearsSpent(education);

  if (!education?.mergedData) {
    education.mergedData = [education];
  }
  if (education?.mergedData) {
    education.mergedData = education.mergedData.sort((s: any) =>
      s?.current ? -1 : 1
    );
  }

  if (education.current) {
    education.endYear = "Present";
  }

  return (
    <>
      {education?.mergedData?.map((education: any) => (
        <div style={Styles.wrapper}>
          <div style={{ ...Styles.root, wordWrap: "break-word" }}>
            <div
              style={{ display: "flex", justifyContent: "start", gap: "16px" }}
            >
              <Avatar
                style={{
                  ...Styles.avatar,
                  backgroundImage: `url(${education?.logo})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "3px",
                  flexShrink: "0",
                }}
                size={isRecruiterPage ? 66 : 48}
              />
              <div style={{ marginLeft: "-6px" }}>
                <p style={Styles.company}>{education?.schoolName}</p>
                {
                  <>
                    <p style={Styles.position}>{education?.degree}</p>
                    <div className="">
                      <span
                        style={{
                          color: "rgba(0,0,0,0.5)",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {education.startYear} {education.endYear && "-"}{" "}
                        {education.endYear}
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          color: "#424952",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {education?.location?.city &&
                        education?.location?.country
                          ? `${education?.location?.city}, ${education?.location?.country}`
                          : education?.location?.city &&
                            !education?.location?.country
                          ? ` | ${education?.location?.city}`
                          : ""}
                      </span>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }} />
        </div>
      ))}
    </>
  );
};

export default SingleEducation;
