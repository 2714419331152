import React, { useRef } from "react";
import { Modal } from "antd";

interface PropsType {
  src: any;
  handleClose: any;
  open: boolean;
  candidate?: any;
}

const ProfileVideo: React.FC<PropsType> = ({
  src,
  handleClose,
  open,
  candidate,
}) => {
  const ref = useRef<any>();
  const close = () => {
    ref.current.pause();
    handleClose();
  };

  return (
    <Modal
      className="profile-video-modal"
      onCancel={close}
      open={open}
      footer={null}
      centered
    >
      <video
        ref={ref}
        style={{ width: "100%", height: "50vh", marginTop: 20 }}
        controls
        autoPlay
      >
        <source src={src} />
      </video>
    </Modal>
  );
};

export default ProfileVideo;
