import { useState, useEffect } from "react";
import { useInspiringCompaniesQuery } from "../graphql/generated/graphql";

const useInspiringCompanies = () => {
  const [InspiringCompanies, setInspiringCompanies]: any = useState([]);
  const { data, loading, error } = useInspiringCompaniesQuery();

  const loadInspiringCompanies = () => {
    if (!loading && data) {
      // console.log(data.inspiringCompanies)
      setInspiringCompanies([...data.inspiringCompanies]);
    }
  };

  useEffect(() => {
    loadInspiringCompanies();
  }, [data]);

  return InspiringCompanies;
};

export default useInspiringCompanies;
