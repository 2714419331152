import React from "react";
import "./ProfileDropdown.scss";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Menu, Avatar, Dropdown, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { authLogout } from "../../../../store/auth/actions";
import { useApolloClient } from "@apollo/react-hooks";

const ProfileDropdown: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const client = useApolloClient();

  const onLogout = () => {
    // call your auth logout code then reset store
    client.resetStore();
    dispatch(authLogout());
    history.push("/");
  };
  const menu = (
    <Menu className="profile-dropdown">
      <Menu.Item className="dropdown-item">
        <NavLink to="/profiles">
          <i className="mdi mdi-account-circle m-r-5"></i>My profile
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item className="dropdown-item">
        <NavLink to="/profiles">
          <i className="mdi mdi-link m-r-5"></i> My Blinks
        </NavLink>
      </Menu.Item> */}
      {/* <Menu.Item className="dropdown-item">
        <NavLink to="/profiles">
          <i className="mdi mdi-settings m-r-5"></i> Reset pssword
        </NavLink>
      </Menu.Item> */}
      <Menu.Item className="dropdown-item" onClick={onLogout}>
        <span className="text-burgundy pb-3">
          <i className="mdi mdi-power text-burgundy"></i> Logout
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <span style={{ float: "right", cursor: "pointer" }}>
          <Badge className="burgundy">
            <img
              style={{
                marginLeft: "20px",
                marginBottom: "8px",
                width: "24px",
                height: "24px",
              }}
              src={require("../../../../assets/icons/profilesvgs/profile.svg")}
              alt=""
            />
            <span className="fs-top-bar">My profile</span>
          </Badge>
        </span>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
