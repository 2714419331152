import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { httpClear } from "../../../../store/http/actions";
import { HttpState } from "../../../../store/http/types";
import {
  Form,
  Input,
  Button,
  notification,
  Select,
  Checkbox,
  Typography,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { ReactComponent as Company } from "assets/icons/DetailedProfile/Company.svg";
import { ReactComponent as Location } from "assets/icons/DetailedProfile/LocationSignup.svg";
import { ReactComponent as Envelope } from "assets/icons/DetailedProfile/Envelop.svg";
import { Fade } from "react-awesome-reveal";
import { Row, Col } from "antd";
import Logo from "../../../../components/UI/Logo/Logo";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
const { Option } = Select;

interface Props {
  onFinish: any;
  isLoading: boolean;
  hasError: boolean;
}

const Style = {
  fontSize: {
    fontSize: "14px",
  },
};

const RegistrationForm: React.FC<Props> = ({
  onFinish,
  isLoading,
  hasError,
}) => {
  const [value, setValue] = useState([]);
  const [isPolicy, setIsPolicy] = useState(false);
  const [form] = Form.useForm();
  const locationRef = useRef<any>();
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: "AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs",
      options: {
        types: ["geocode"],
      },
    });

  return (
    <>
      <h3 className="text-center m-t-40">
        <Logo height="40" />
      </h3>
      <p className="text-muted text-center pt-0 mtp0">
        You already have an account?{" "}
        <Link to="/login" className="text-primary">
          Just log in
        </Link>
      </p>
      <Fade>
        <Form
          name="registration-form"
          layout="vertical"
          form={form}
          // onFieldsChange={() => (httpClear())}
          onFinish={(v) => {
            onFinish(v, value);
          }}
          requiredMark={"optional"}
          scrollToFirstError
        >
          <Row>
            <Col span={11} style={{ marginRight: 10 }}>
              {" "}
              <Form.Item
                name="firstName"
                label={<span style={Style.fontSize}>First name</span>}
                className="custom-form-item"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                  {
                    max: 24,
                    message: "Max : 25 characters",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  type="text"
                  placeholder="Enter your first name"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                  maxLength={25}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="lastName"
                label={<span style={Style.fontSize}>Last name</span>}
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                  {
                    max: 24,
                    message: "Max : 25 characters",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  type="text"
                  placeholder="Enter your last name"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                  maxLength={25}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11} style={{ marginRight: 10 }}>
              {" "}
              <Form.Item
                name="companyName"
                label={<span style={Style.fontSize}>Company name</span>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your title!",
                  },
                  {
                    max: 49,
                    message: "Max : 50 characters",
                  },
                ]}
              >
                <Input
                  prefix={<Company />}
                  placeholder="Enter your company name"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="title"
                label={<span style={Style.fontSize}>Title</span>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your title!",
                  },
                  {
                    max: 49,
                    message: "Max : 50 characters",
                  },
                ]}
              >
                <Input
                  prefix={<Envelope />}
                  placeholder="Enter your title"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11} style={{ marginRight: 10 }}>
              <Form.Item
                name="location"
                label={<span style={Style.fontSize}>Location</span>}
                rules={[
                  { required: true, message: "Please select a location" },
                ]}
              >
                <div
                  style={{ position: "relative" }}
                  className="ant-register-select-locaion"
                >
                  <Location
                    style={{
                      position: "absolute",
                      left: 13,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1, // Ensure the icon is above other elements
                    }}
                  />
                  <Select
                    showSearch
                    className="experience-location-selector"
                    placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enter your location"
                    ref={locationRef}
                    style={{ fontSize: "14px", fontWeight: 400 }}
                    onSearch={(value: any) => {
                      getPlacePredictions({ input: value });
                      setValue(value);
                    }}
                    onChange={(value: any) => {
                      setValue(value);
                    }}
                    suffixIcon={""}
                    loading={isPlacePredictionsLoading}
                    filterOption={(input, option: any) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {placePredictions &&
                      placePredictions.map((company: any) => {
                        return (
                          <Option
                            key={company.description}
                            value={company.description}
                          >
                            {company.description}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="emailAddress"
                label={<span style={Style.fontSize}>Email address</span>}
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid email address!",
                  },
                  {
                    required: true,
                    message: "Please enter your email address!",
                  },
                ]}
              >
                <Input
                  prefix={<i className="far fa-envelope font-14" />}
                  placeholder="Enter your email address"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={11} style={{ marginRight: 10 }}>
              <Form.Item
                name="password"
                label={<span style={Style.fontSize}>Password</span>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                  {
                    whitespace: true,
                    message: "No whitespaces allowed",
                  },
                  {
                    min: 6,
                    message: "Please enter min 6 characters",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Enter your password"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirm"
                label={<span style={Style.fontSize}>Confirm password</span>}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                  {
                    whitespace: true,
                    message: "No whitespaces allowed",
                  },
                  {
                    min: 6,
                    message: "Please enter min 6 characters",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Confirm your password"
                  style={{ fontSize: "14px", fontWeight: 400 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex" }}>
              <Form.Item
                name="isPolicy"
                label=""
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isPolicy == false) {
                        return Promise.reject("Please select the check box.");
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <span style={{ display: "flex" }}>
                  <Checkbox
                    checked={isPolicy}
                    onChange={(e) => setIsPolicy(e.target.checked)}
                  />
                  <Typography
                    style={{ marginLeft: 5, fontSize: 14, fontWeight: 400 }}
                  >
                    I have read the <a href="">privacy polices</a> and the{" "}
                    <a href=""> user agreement</a>.
                  </Typography>
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                marginRight: 15,
              }}
            >
              <Button
                className="ant-btn-amber"
                htmlType="submit"
                shape="round"
                loading={isLoading}
                style={{ padding: "0 37px", height: "34px", fontSize: "14px" }}
                // disabled={isPolicy == false}
              >
                Register
              </Button>
            </div>
            {/* <div
              style={{
                textAlign: "end",
                textDecoration: "underline",
                marginTop: 10,
              }}
            > */}
            {/* <Link to="recover-password" className="text-muted">
                className="mdi mdi-lock"
                <span
                  style={{
                    color: "#012653",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Forgot your password?
                </span>
              </Link> */}
            {/* </div> */}
          </Form.Item>
          {/* <p className="font-14 text-muted  mb-0">
            By registering, you agree to <br />
            <Button type="link" className="text-navy no-left-padding">
              <span style={{ wordSpacing: "normal" }}>
                {" "}
                Blonk’s
                <span>
                  <a
                    style={{ color: "#20124D" }}
                    href="https://blonk.co/privacy-policy/"
                  >
                    {" "}
                    privacy policy
                  </a>
                </span>
                <span style={{ wordSpacing: "normal" }}>
                  {" "}
                  and
                  <span>
                    <a
                      style={{ color: "#20124D" }}
                      href="https://blonk.co/user-agreement/"
                    >
                      {" "}
                      user agreement.
                    </a>
                  </span>
                </span>
              </span>
            </Button>
          </p> */}
        </Form>
      </Fade>
    </>
  );
};

export default RegistrationForm;
