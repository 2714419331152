import {
  Notifications,
  notificationsActionTypes,
  STORE_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
} from "./types";

import _ from "lodash";

import { updateObject } from "../../helpers/updateObject";

const initialState: Notifications = {
  notifications: [],
};

const storeNotifications = (state: Notifications, action: any) => {
  return updateObject(state, { notifications: action.notifications });
};

const addNotification = (state: Notifications, action: any) => {
  const findIndex = state.notifications.findIndex(
    (notification: any) => notification._id == action.notification._id
  );
  if (findIndex > -1) {
    const updatedObject = _.merge(
      state.notifications[findIndex],
      action.notification
    );
    const notifications = state.notifications;
    notifications[findIndex] = updatedObject;
    return updateObject(state, { notifications: notifications });
  } else {
    return {
      ...state,
      notifications: [action.notification, ...state.notifications],
    };
  }
};

const deleteNotifcation = (state: Notifications, action: any) => {
  const findIndex = state.notifications.findIndex(
    (notification: any) => notification._id == action.notificationId
  );

  if (findIndex > -1) {
    const notifications = [...state.notifications];
    notifications.splice(findIndex, 1);

    return {
      ...state,
      notifications: notifications,
    };
  } else return { ...state };
};

const deleteAllNotifcations = (state: Notifications, action: any) => {
  const filteredNotifications = state.notifications.filter(
    (notif: any) => !action.notifications.includes(notif._id)
  );
  return {
    ...state,
    notifications: filteredNotifications,
  };
};

const notificationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_NOTIFICATIONS:
      return storeNotifications(state, action);
    case ADD_NOTIFICATION:
      return addNotification(state, action);
    case DELETE_NOTIFICATION:
      return deleteNotifcation(state, action);
    case DELETE_ALL_NOTIFICATIONS:
      return deleteAllNotifcations(state, action);
    default:
      return state;
  }
};

export default notificationsReducer;
