import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ValidateEmailForm from "./partials/ValidateEmailForm";
import ValidateResetCodeForm from "./partials/ValidateResetCodeForm";
import ResetPasswordForm from "./partials/ResetPasswordForm";

import Logo from "../../../components/UI/Logo/Logo";
import Footer from "../../../components/Footer/Footer";

import { Card, Row, Col, Affix, notification } from "antd";
import { AuthState } from "../../../store/auth/types";

const RecoverPassword: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: AuthState) => state.auth.accessToken !== null
  );

  const [resetPasswordCode, setResetPasswordCode] = useState(String);

  const [manageScreen, setManageScreen] = useState(
    localStorage.getItem("fpsDisplay")
  );

  const authRedirectPath = useSelector(
    (state: AuthState) => state.auth.authRedirectPath
  );

  const managerScreenHandler = (msg: string, desc: any, screen: string) => {
    localStorage.setItem("fpsDisplay", screen);
    notification["success"]({
      message: msg,
      description: desc,
    });
  };

  let dispayData = (
    <ValidateEmailForm managerScreenHandler={managerScreenHandler} />
  );

  return (
    <>
      {isAuthenticated && <Redirect to={authRedirectPath} />}
      <div className="bg-navy" style={{ minHeight: "100vh" }}>
        <div className="wrapper">
          <Row>
            <Col span={14}>
              <Affix offsetTop={130}>
                <div className="text-center">
                  <img
                    src="http://blonk.co/demo/SOBlonk/assets/images/login-image-2.png"
                    className="img-fluid m-t-10"
                    alt=""
                  />
                </div>
              </Affix>
            </Col>
            <Col span={10}>
              <Card
                style={{
                  minHeight: "100vh",
                  marginBottom: "0px",
                  borderRadius: "0",
                }}
              >
                <div className="pb-4">
                  <h3 className="text-center m-t-40">
                    <Logo height="40" />
                  </h3>
                  <div className="p-3">{dispayData}</div>
                </div>
                <Footer footer={false} />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
