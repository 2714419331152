import { NextStepsIcon } from "assets/icons/DetailedProfile";
import React, { useContext } from "react";

import { SectionTitle } from "../Shared";

interface PropsType {
  candidate: any;
  match: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #1A838C",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};

const NextSteps: React.FC<PropsType> = ({ candidate, match }) => {
  return (
    <>
      {match?.nextSteps != null && match?.nextSteps != "" && (
        <div style={Styles.root}>
          <SectionTitle
            title="Next steps"
            icon={NextStepsIcon}
            fontSize="16px"
            actionType="edit"
            showstyle={false}
          />
          <div style={Styles.body}>
            <div
              style={{ marginBottom: 10, fontSize: 14, color: "#495057" }}
              dangerouslySetInnerHTML={{
                __html: match?.nextSteps,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NextSteps;
