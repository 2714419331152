import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AuthState } from "../../store/auth/types";
import { setAuthRedirectPath } from "../../store/auth/actions";

const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  //check authentication
  const isAuthenticated = useSelector(
    (state: AuthState) => state.auth.accessToken !== null
  );
  let { pathname } = useLocation();

  // http actions
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAuthRedirectPath(pathname));
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
