import React from "react";
import { Typography } from "antd";
import { ActivityIcon } from "assets/icons/DetailedProfile";

interface PropsType {
  candidate: any;
}

const { Title } = Typography;

const Styles = {
  flexDefault: {
    display: "flex",
    alignItems: "center",
  },
};

const Activity: React.FC<PropsType> = ({ candidate }) => {
  return (
    <div style={Styles.flexDefault}>
      <Title style={{ fontSize: 16, margin: 0, marginTop: -8, width: 110 }}>
        Activity
      </Title>
      <div style={Styles.flexDefault}>
        <img style={{ cursor: "pointer" }} src={ActivityIcon} />
      </div>
    </div>
  );
};

export default Activity;
