import { useState, useEffect } from "react";
import { useRejectedAgainstAJobQuery } from "../graphql/generated/graphql";

const useRejectedAgainstAJob = (jobId: any) => {
  const [matchs, setMatchs]: any = useState(null);
  const { data, loading, error, refetch } = useRejectedAgainstAJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: "network-only",
  });
  const loadMatches = () => {
    if (!loading && data) {
      // console.log(data.rejectedAgainstAJob);
      setMatchs(data.rejectedAgainstAJob);
    }
  };

  useEffect(() => {
    loadMatches();
  }, [data]);

  return { matchs, refetch };
};

export default useRejectedAgainstAJob;
