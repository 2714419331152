import { Col, Row, Skeleton } from "antd";
import { NextButton, PreviousButton } from "assets/icons/DetailedProfile";
import { useGetSingleJobQuery } from "graphql/generated/graphql";
import { update } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface PropsType {
  jobId?: any;
  jobData: any;
  loading: any;
}

const Styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  normalTitle: {
    color: "#012653",
    fontSize: 20,
    fontWeight: 600,
    margin: 0,
    flex: 1,
  },
  bigTitle: {
    color: "#070B26",
    fontSize: 26,
    fontWeight: 500,
    width: "100%",
    textAlign: "center",
    margin: 0,
    flex: 1,
  },
  navBtn: {
    width: 40,
    cursor: "pointer",
  },
  disabledNavBtn: {
    width: 40,
    cursor: "not-allowed",
    opacity: 0.5,
  },
};

const TopNavigation: React.FC<PropsType> = ({ jobId, jobData, loading }) => {
  const [candidates, setCandidates] = useState<any>(null);
  const location: any = useLocation();
  const history = useHistory();
  const [candidateIndex, setCandidateIndex]: any = useState(null);
  const candidateArray = location?.state?.from
    ? [...location?.state?.from]
    : [];

  // const { data: jobData, loading }: any = useGetSingleJobQuery({
  //   variables: {
  //     jobId: jobId,
  //   },
  //   fetchPolicy: "network-only",
  // });
  const jobTitle = jobData?.getJobDetails?.title;
  const companyName = jobData?.getJobDetails?.company?.name;
  const isJobDataAvailable = jobTitle && companyName;
  const pageTitle = isJobDataAvailable && `${jobTitle} at ${companyName}`;

  useEffect(() => {
    if (location?.state?.from) {
      const index = candidateArray.findIndex(
        (cand) => cand._id == location?.state?.matchId
      );
      setCandidateIndex(index);
      setCandidates(location?.state?.from);
    } else {
      goBack();
      return;
    }
  }, [location?.state?.matchId]);

  const goBack = () => {
    history.push({
      pathname: `/candidate-listings/${location.state?.jobId}`,
      state: {
        tabActiveKey: location.state?.tabActiveKey,
      },
    });
  };

  const btnHandleNavigate = (e: "next" | "prev") => {
    let nextCandidate = null;
    let updateCandidate = false;
    if (e == "next") {
      if (candidateIndex < candidates.length - 1) {
        nextCandidate = candidates[candidateIndex + 1];
        updateCandidate = true;
      }
    } else {
      if (candidateIndex > 0) {
        nextCandidate = candidates[candidateIndex - 1];
        updateCandidate = true;
      }
    }

    if (updateCandidate) {
      history.push({
        pathname: `/candidate-listings/view/${nextCandidate.candidateListing._id}`,
        state: {
          matchId: nextCandidate._id,
          from: location.state?.from,
          jobId: location.state?.jobId,
          jobName: nextCandidate?.jobListing?.title,
          companyName: nextCandidate?.company?.name,
        },
      });
    }
  };
  return (
    <div style={Styles.root}>
      {location?.state.from.length > 1 && (
        <span
          style={
            candidateIndex == 0 || candidateIndex == -1
              ? Styles.disabledNavBtn
              : null
          }
          onClick={() => btnHandleNavigate("prev")}
        >
          <img
            style={
              candidateIndex == 0 || candidateIndex == -1
                ? Styles.disabledNavBtn
                : Styles.navBtn
            }
            src={PreviousButton}
          />
        </span>
      )}
      <p
        style={{
          ...(location?.state.from.length > 0
            ? Styles.normalTitle
            : Styles.bigTitle),
          textAlign: "center",
        }}
      >
        {!loading ? (
          pageTitle
        ) : (
          <>
            <Row
              gutter={24}
              style={{ position: "relative", left: "30%", marginTop: 5 }}
            >
              <Col span={24}>
                <Skeleton active paragraph={{ rows: 0, width: "100%" }} />
              </Col>
            </Row>
          </>
        )}
      </p>
      {location?.state.from.length > 1 && (
        <span
          style={
            candidateIndex == location?.state?.from.length - 1
              ? Styles.disabledNavBtn
              : null
          }
          onClick={() => btnHandleNavigate("next")}
        >
          <img
            style={
              candidateIndex == location?.state?.from.length - 1
                ? Styles.disabledNavBtn
                : Styles.navBtn
            }
            src={NextButton}
          />
        </span>
      )}
    </div>
  );
};

export default TopNavigation;
