import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import { useRouteMatch, Link } from "react-router-dom";
import ScheduleInterview from "./partials/ScheduleInterview";
import {
  Row,
  Col,
  Card,
  Skeleton,
  Tabs,
  Button,
  Table,
  Typography,
  Popconfirm,
  List,
  Avatar,
  Tag,
  Tooltip,
  Space,
  Progress,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ClockCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { DataBar, DataBarItem } from "../../../components/UI/DataBar/DataBar";
import { Fade } from "react-awesome-reveal";

const { Title } = Typography;
const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs;
interface Props {}

const InterviewDashboard: React.FC<Props> = () => {
  const { url } = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [displayScheduleInterview, setDisplayScheduleInterview] =
    useState(false);

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
      // console.log(url, "url");
    }, 1000);
  }, []);

  const upcomingInteriviewData = [
    {
      key: "1",
      scheduledOn: "20 Sept 2020",
      title: "Initial Interview Call ",
      candidate: "M Bilal",
      interviewer: "Waqas Mumtaz",
      type: "Initial interview",
      interviewDate: "23 Sept 2020",
      interviewTime: "2:15 PM",
      link: "https://meet.google.com/dju-bimc-jit?authuser=0",
    },
    {
      key: "2",
      scheduledOn: "18 Sept 2020",
      title: "Final Interview Call",
      candidate: "Areeb Vohra",
      interviewer: "Vincent Mailard",
      type: "Final interview",
      interviewDate: "28 Sept 2020",
      interviewTime: "9:15 AM",
      link: "https://meet.google.com/dju-bimc-jit?authuser=0",
    },
    {
      key: "3",
      scheduledOn: "21 Sept 2020",
      title: "Technical Interview Call",
      candidate: "Javeria Khan",
      interviewer: "Ahmad Ali",
      type: "Techincal interview",
      interviewDate: "24 Sept 2020",
      interviewTime: "6:15 PM",
      link: "https://meet.google.com/dju-bimc-jit?authuser=0",
    },
  ];

  const previousInterviews = [
    {
      key: "1",
      scheduledOn: "10 Sept 2020",
      title: "Initial Interview Call ",
      candidate: "M Mustafa",
      interviewer: "Waqas Mumtaz",
      type: "Initial interview",
      interviewDate: "15 Sept 2020",
      interviewTime: "2:15 PM",
      status: "interviewed",
    },
    {
      key: "2",
      scheduledOn: "11 Sept 2020",
      title: "Final Interview Call",
      candidate: "Areeb Vohra",
      interviewer: "Vincent Mailard",
      type: "Final interview",
      interviewDate: "15 Sept 2020",
      interviewTime: "9:15 AM",
      status: "cancelled",
    },
  ];

  const testDetails = [
    {
      key: "1",
      candidate: "M Mustafa",
      givenDate: "15 Sept 2020",
      subbmissionDate: "16 Sept 2020",
      status: "submitted",
    },
    {
      key: "1",
      candidate: "Areeb Vohra",
      givenDate: "13 Sept 2020",
      subbmissionDate: "14 Sept 2020",
      status: "late",
    },
  ];

  const reportData = [
    {
      title: "Muhammad Mustafa",
    },
    {
      title: "Shaheer Baig",
    },
    {
      title: "Areeb Vohra",
    },
  ];
  return (
    <>
      <PageTitle
        back
        title="Interview dashboard"
        btnActive={true}
        btnText="Manage Assessment list"
        to={`${url}/manage-assessment`}
      >
        Junior React-native developer (remote)
      </PageTitle>
      <DataBar>
        <DataBarItem
          size={6}
          title="Selected candidates"
          stats="10"
          icon="up"
          extraStats="34%"
          barColor="primary"
          barWidth={90}
        />
        <DataBarItem
          size={6}
          title="Interviewed candidates"
          stats="3"
          icon="up"
          extraStats="34%"
          barColor="teal"
          barWidth={70}
        />
        <DataBarItem
          size={6}
          title="upcoming interviews"
          stats="2"
          barColor="amber"
          icon="up"
          extraStats="34%"
          barWidth={80}
        />
        <DataBarItem
          size={6}
          title="Hired candidate(s)"
          stats="0"
          icon="down"
          extraStats="34%"
          barColor="burgundy"
          barWidth={20}
        />
      </DataBar>
      <div className="text-center">
        <Title level={4}>Interviews details</Title>
      </div>

      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <Fade>
            <Card>
              <div className="text-right py-2">
                <Button
                  className="ant-btn-primary"
                  shape="round"
                  onClick={() => setDisplayScheduleInterview(true)}
                >
                  Schedule an interview
                </Button>
              </div>

              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={
                    <span>
                      Upcoming Interviews
                      <span className="tab-pane-count">3</span>
                    </span>
                  }
                  key="1"
                >
                  <Table dataSource={upcomingInteriviewData} size="small">
                    <Column
                      title="Scheduled on"
                      dataIndex="scheduledOn"
                      key="scheduledOn"
                    />
                    <Column
                      title="Title"
                      dataIndex="title"
                      key="title"
                      render={(title, record: any) => (
                        <div>
                          <Link to={`${url}/23223232/candidate/13234324`}>
                            {title}
                          </Link>
                        </div>
                      )}
                    />
                    <Column
                      title="Candidate"
                      dataIndex="candidate"
                      key="candidate"
                      render={(candidate) => (
                        <div>
                          <Link to="#">{candidate}</Link>
                        </div>
                      )}
                    />
                    <Column
                      title="Interviewer"
                      dataIndex="interviewer"
                      key="interviewer"
                      render={(interviewer) => (
                        <div>
                          <Link to="#">{interviewer}</Link>
                        </div>
                      )}
                    />
                    <Column
                      title="Interview type"
                      dataIndex="type"
                      key="type"
                      render={(type) => (
                        <>
                          <Tag color="blue" key={type}>
                            {type}
                          </Tag>
                        </>
                      )}
                    />
                    <Column
                      title="Interview time"
                      dataIndex="interviewDate"
                      key="interviewDate"
                      render={(interviewDate, record: any) => (
                        <>
                          <Space>
                            {interviewDate} - <ClockCircleOutlined />
                            {record.interviewTime}
                          </Space>
                        </>
                      )}
                    />
                    <Column
                      title="Google Link"
                      dataIndex="link"
                      key="link"
                      render={(link) => (
                        <>
                          <Link to={link} target="_blank">
                            <Button className="ant-btn-navy" shape="round">
                              Google Meet
                            </Button>
                          </Link>
                        </>
                      )}
                    />
                    <Column
                      title="Actions"
                      dataIndex="key"
                      key="key"
                      render={(key) => (
                        <>
                          <Space>
                            <Tooltip title="edit">
                              <Button
                                className="border-primary"
                                shape="circle"
                                onClick={() =>
                                  setDisplayScheduleInterview(true)
                                }
                                icon={<EditOutlined className="text-primary" />}
                              />
                            </Tooltip>
                            <Tooltip title="archive">
                              <Popconfirm
                                title="Are you sure archive this recruiter?"
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button className="border-amber" shape="circle">
                                  <i className="dripicons-archive text-amber" />
                                </Button>
                              </Popconfirm>
                            </Tooltip>
                            <Popconfirm
                              title="Are you sure delete this recruiter?"
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                className="border-burgundy"
                                shape="circle"
                                icon={
                                  <DeleteOutlined className="text-burgundy" />
                                }
                              />
                            </Popconfirm>
                          </Space>
                        </>
                      )}
                    />
                  </Table>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      Previous Interviews
                      <span className="tab-pane-count">3</span>
                    </span>
                  }
                  key="2"
                >
                  <Table dataSource={previousInterviews} size="small">
                    <Column
                      title="Scheduled on"
                      dataIndex="scheduledOn"
                      key="scheduledOn"
                    />
                    <Column
                      title="Title"
                      dataIndex="title"
                      key="title"
                      render={(title, record: any) => (
                        <div>
                          <Link to="#">{title}</Link>
                        </div>
                      )}
                    />
                    <Column
                      title="Candidate"
                      dataIndex="candidate"
                      key="candidate"
                      render={(candidate) => (
                        <div>
                          <Link to="#">{candidate}</Link>
                        </div>
                      )}
                    />
                    <Column
                      title="Interviewer"
                      dataIndex="interviewer"
                      key="interviewer"
                      render={(interviewer) => (
                        <div>
                          <Link to="#">{interviewer}</Link>
                        </div>
                      )}
                    />
                    <Column
                      title="Interview type"
                      dataIndex="type"
                      key="type"
                      render={(type) => (
                        <>
                          <Tag color="blue" key={type}>
                            {type}
                          </Tag>
                        </>
                      )}
                    />
                    <Column
                      title="Interview time"
                      dataIndex="interviewDate"
                      key="interviewDate"
                      render={(interviewDate, record: any) => (
                        <>
                          <Space>
                            {interviewDate} - <ClockCircleOutlined />
                            {record.interviewTime}
                          </Space>
                        </>
                      )}
                    />
                    <Column
                      title="Status"
                      dataIndex="status"
                      key="status"
                      render={(status) => (
                        <>
                          <span
                            className={`font-10 px-2 m-2 badge badge-pill badge-${
                              status === "interviewed" ? "teal" : "burgundy"
                            }`}
                          >
                            {status}
                          </span>
                        </>
                      )}
                    />
                    <Column
                      title="Actions"
                      dataIndex="key"
                      key="key"
                      render={(key, record: any) => (
                        <>
                          <Space>
                            {record.status === "cancelled" && (
                              <Tooltip title="rescheduled">
                                <Button
                                  className="border-primary"
                                  shape="circle"
                                  icon={
                                    <RedoOutlined className="text-primary" />
                                  }
                                />
                              </Tooltip>
                            )}
                            <Tooltip title="edit">
                              <Button
                                className="border-primary"
                                shape="circle"
                                icon={<EditOutlined className="text-primary" />}
                              />
                            </Tooltip>

                            <Popconfirm
                              title="Are you sure delete this record?"
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                className="border-burgundy"
                                shape="circle"
                                icon={
                                  <DeleteOutlined className="text-burgundy" />
                                }
                              />
                            </Popconfirm>
                          </Space>
                        </>
                      )}
                    />
                  </Table>
                </TabPane>
              </Tabs>
            </Card>
          </Fade>
        </Col>
      </Row>

      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={16}>
          <div className="text-center">
            <Title level={4}>Tests details</Title>
          </div>
          <Fade>
            <Card>
              <Table dataSource={testDetails} size="small">
                <Column
                  title="Candidate"
                  dataIndex="candidate"
                  key="candidate"
                  render={(candidate) => (
                    <div>
                      <Link to="#">{candidate}</Link>
                    </div>
                  )}
                />
                <Column
                  title="Invitation sent"
                  dataIndex="givenDate"
                  key="givenDate"
                />
                <Column
                  title="Deadline"
                  dataIndex="subbmissionDate"
                  key="subbmissionDate"
                />
                <Column
                  title="Status"
                  dataIndex="status"
                  key="status"
                  render={(status) => (
                    <>
                      <span
                        className={`font-10 px-2 m-2 badge badge-pill badge-${
                          status === "submitted" ? "teal" : "burgundy"
                        }`}
                      >
                        {status}
                      </span>
                    </>
                  )}
                />
                <Column
                  title="Actions"
                  dataIndex="key"
                  key="key"
                  render={(key) => (
                    <>
                      <Space>
                        <Tooltip title="edit">
                          <Button
                            className="border-primary"
                            shape="circle"
                            icon={<EditOutlined className="text-primary" />}
                          />
                        </Tooltip>
                        <Popconfirm
                          title="Are you sure delete this recruiter?"
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            className="border-burgundy"
                            shape="circle"
                            icon={<DeleteOutlined className="text-burgundy" />}
                          />
                        </Popconfirm>
                      </Space>
                    </>
                  )}
                />
              </Table>
            </Card>
          </Fade>
        </Col>
        <Col span={8}>
          <div className="text-center">
            <Title level={4}>Candidate Reports</Title>
          </div>

          <Fade>
            <Card>
              <List
                itemLayout="horizontal"
                dataSource={reportData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                      }
                      title={
                        <Link to={`${url}/candidate/13234324`}>
                          {item.title}
                        </Link>
                      }
                      description={`Last update: 23 Sept 2020`}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Fade>
        </Col>
      </Row>
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={16}>
          <div className="text-center">
            <Title level={4}>Hired Candidate(s)</Title>
          </div>
          <Fade>
            <Card>
              <p className="ptb-20 text-center">No canidate(s) hired yet.</p>
            </Card>
          </Fade>
        </Col>
      </Row>

      <ScheduleInterview
        show={displayScheduleInterview}
        hide={() => setDisplayScheduleInterview(false)}
      />
    </>
  );
};

export default InterviewDashboard;
