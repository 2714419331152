import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import CandidateListings from "./CandidateListings/CandidateListings";
import CandidateProfile from "./ViewCandidate/ViewCandidate";
import Message from "./Chat/App";
import TabActvContextProvider from "./CandidateListings/context/tabActvContext";

const Candidates: React.FC = () => {
  let { path, url } = useRouteMatch();
  let { pathname } = useLocation();

  return (
    <>
      <TabActvContextProvider>
        <Switch>
          <Route exact path={`${path}/:jobId`} component={CandidateListings} />
          <Route
            path={`${path}/view/:candidateId`}
            component={CandidateProfile}
          />
          {/* <Route path={`${path}/view/:jobId`} component={ViewJob} />
        <Route
          path={`${path}/dashboard/:jobId/candidate/:candidateId`}
          component={CandidateReport}
        />
        <Route
          path={`${path}/dashboard/:jobId/manage-assessment`}
          component={ManageAssessment}
        />
        <Route
          path={`${path}/dashboard/:jobId/:interviewId/candidate/:candidateId`}
          component={CandidateInterview}
        />

        <Route path={`${path}/add-job`} component={AddJob} />
        <Route path={`${path}/edit/:jobId`} component={AddJob} />
        <Route
          path={`${path}/dashboard/:jobId`}
          component={InterviewDashboard}
        />

        <Route path="*" exact={true} component={NotFound} /> */}
        </Switch>
      </TabActvContextProvider>
    </>
  );
};

export default Candidates;
