import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import CompanyList from "./CompanyList/CompanyList";
import AddCompany from "./AddCompany/AddCompany";
import ViewCompany from "./ViewCompany/ViewCompany";
import EditCompany from "./EditCompany/EditCompany";
import NotFound from "../../components/NotFound/NotFound";
const Companies: React.FC = () => {
  let { path, url } = useRouteMatch();
  let { pathname } = useLocation();

  return (
    <>
      <Switch>
        <Route exact path={path} component={CompanyList} />
        <Route path={`${path}/new`} component={AddCompany} />
        <Route path={`${path}/view/:companyId`} component={ViewCompany} />
        <Route path={`${path}/edit/:companyId`} component={AddCompany} />
        {/* <Route path={`${path}/edit/:companyId`} component={EditCompany} /> */}
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    </>
  );
};

export default Companies;
