import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Col, Form, Input, Row, notification } from "antd";
import { Fade, Bounce } from "react-awesome-reveal";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Logo from "../../../../components/UI/Logo/Logo";
import { useVerifyRecruiterSignupMutation } from "graphql/generated/graphql";
interface Props {
  fullName: string | undefined | null;
  userId: string | undefined | null;
}
const RegistrationSuccess: React.FC<Props> = ({ fullName, userId }) => {
  const [form] = Form.useForm();
  let history = useHistory();
  const [redirect, setRedirect] = useState(false);
  console.log(userId);
  const [VerifyEmail, { data, loading, error }] =
    useVerifyRecruiterSignupMutation();
  const onFinish = (values) => {
    VerifyEmail({
      variables: {
        code: values?.submissionCode,
        userId: userId,
      },
    })
      .then(({ data }) => {
        console.log(data);
        if (data?.VerifyRecruiterSignup?.message) {
          notification["success"]({
            message: "Recruiter verify successfully",
            duration: 3,
          });
          setRedirect(true);
        } else {
          form.resetFields();
          notification["error"]({
            message: "Invalid Code",
            description: "Please enter correct verification code.",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to Verify",
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
        });
      });
  };
  return (
    <>
      {redirect && (
        <Redirect to={{ pathname: "/login", state: { isVerified: true } }} />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "80vh",
        }}
      >
        <div className="text-center">
          <h3 className="text-center m-t-40">
            <Logo height="40" />
          </h3>
          <p className="text-muted text-center pt-0 mtp0">
            You already have an account?{" "}
            <Link to="/login" className="text-primary">
              Just log in
            </Link>
          </p>
          <p style={{ color: "#008D8B", fontSize: 14, fontWeight: 400 }}>
            To verify your email, please enter the code <br /> that has been
            sent to you.
          </p>
          <div className="m-t-40">
            <Form
              name="registration-form"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row justify="center">
                <Col span={22}>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    name="submissionCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Code!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Code"
                      width={200}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  className="ant-btn-amber"
                  htmlType="submit"
                  shape="round"
                  style={{
                    padding: "0 120px",
                    height: "29px",
                    fontSize: "14px",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationSuccess;
