import imageCompression from "browser-image-compression";

/**
 * Compress image file function
 *
 * @param file The image file to be compressed
 * @returns The original or compressed image file
 */
export const compressImage = async (file: File): Promise<File> => {
  const options = {
    maxSizeMB: 5,
    maxWidthOrHeight: 2160,
    useWebWorker: true,
  };

  const fileSizeMB = file.size / 1024 / 1024;
  if (fileSizeMB <= 5) {
    return file;
  }

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Error during image compression:", error);
    throw error;
  }
};
