import {
  Jobs,
  jobsActionTypes,
  STORE_JOBS,
  STORE_JOB,
  DELETE_JOB,
} from "./types";
import _ from "lodash";

import { updateObject } from "../../helpers/updateObject";

const initialState: Jobs = {
  jobs: [],
};

const storeJobs = (state: Jobs, action: any) => {
  return updateObject(state, { jobs: action.jobs });
};
const storeJob = (state: Jobs, action: any) => {
  const findIndex = state.jobs.findIndex(
    (job: any) => job._id == action.job._id
  );
  if (findIndex > -1) {
    const updatedObject = _.merge(state.jobs[findIndex], action.job);
    // console.log('Updated : ', updatedObject);
    const jobs = state.jobs;
    jobs[findIndex] = updatedObject;
    return updateObject(state, { jobs: jobs });
  } else return state;
};

const deleteJob = (state: Jobs, action: any) => {
  const findIndex = state.jobs.findIndex((job: any) => job._id == action.jobId);
  if (findIndex > -1) {
    const jobs = [...state.jobs];
    jobs.splice(findIndex, 1);
    state.jobs = jobs;
    return state;
  } else return state;
};

const jobsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_JOBS:
      return storeJobs(state, action);
    case STORE_JOB:
      return storeJob(state, action);
    case DELETE_JOB:
      return deleteJob(state, action);
    default:
      return state;
  }
};

export default jobsReducer;
