import React from "react";
import "./LeftSideBar.scss";
import { NavLink } from "react-router-dom";
const LeftSideBar: React.FC = () => {
  return (
    <div className="side-menu">
      <ul>
        {/* <li>
          <NavLink to="/" className="ripple" exact>
            <i className="mdi mdi-view-dashboard"></i>
            <span> Dashboard </span>
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/companies" className="ripple">
            <i className="mdi mdi-bank"></i>
            <span> Companies </span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/job-listings" className="ripple">
            <i className="mdi mdi-buffer"></i>
            <span> Jobs</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/profiles" className="ripple">
            <i className="mdi mdi-account"></i>
            <span> My profile </span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/blinks" className="ripple">
            <i className="mdi mdi-link"></i>
            <span> My Blinks </span>
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/chat" className="ripple">
            <i className="mdi mdi-email-outline"></i>
            <span> My Chat </span>
          </NavLink>
        </li> */}
      </ul>
      <div className="clearfix"></div>
    </div>
  );
};

export default LeftSideBar;
