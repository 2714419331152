import { Typography } from "antd";
import { CompensationIcon } from "assets/icons/DetailedProfile";
import React, { useContext, useState } from "react";
import Inspiringcompany from "assets/icons/profilesvgs/Inspiringcompany.svg";

// import { YearlyCompensationDrawer } from '../Edit'
import { SectionTitle } from "../Shared";

const { Title } = Typography;

interface PropsType {
  candidate: any;
  nocomments: any;
  match?: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #364972",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
  subBody: {
    paddingLeft: 16,
    marginTop: 10,
  },
  smallText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#495057",
  },
  primaryText: {
    color: "#344976",
  },
  CompensationColor: {
    color: "#2E4A79",
  },
  commentBox: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: "#E7F1F9",
    marginTop: 12,
    borderRadius: 9,
    marginBottom: 10,
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.14)",
    border: "solid 0.5px #DADADA",
  },
};

const Fields = [
  { key: "base", value: "Base" },
  { key: "onTargetEarnings", value: "On target earnings" },
  { key: "shortTermIncentives", value: "Short term incentives" },
  { key: "longTermIncentives", value: "Long term incentives" },
  { key: "other", value: "Other" },
];

const YearlyCompensation: React.FC<PropsType> = ({
  candidate,
  nocomments,
  match,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = () => {
    setOpen(true);
  };
  //conversion function
  const conversion = (value: any) => {
    if (value > 1000 && value != undefined) {
      return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return value?.toString();
    }
  };
  const formattedCurrentComment =
    candidate?.yearlyCompensation?.currentComments?.replace(/\n/g, "<br />");
  const formattedExpectedComment =
    candidate?.yearlyCompensation?.comments?.replace(/\n/g, "<br />");
  const Discount = () => {
    const { current, expected } = candidate?.yearlyCompensation;
    const currentTotal = current?.total;
    const expectedTotal = expected?.total;
    let discount = parseInt(
      ((expectedTotal / currentTotal) * 100 - 100).toFixed(0)
    );
    if (!isNaN(discount)) {
      return (
        <span style={{ color: "#00AC07", fontSize: 9, marginLeft: "2px" }}>
          ({(discount < 0 ? "" : "+") + discount}%)
        </span>
      );
    } else {
      return null;
    }
  };

  const calcaluteTotal = (expected: any) => {
    if (!expected) {
      return null;
    }
    let total: any = 0;
    for (const key in expected) {
      if (expected.hasOwnProperty(key)) {
        if (expected[key] && expected[key] !== "Compensation") {
          total += Number(expected[key]);
        }
      }
    }
    total = total.toString();
    console.log(total);
    if (total == 0) return null;
    return conversion(total);
  };

  const anyExpectedCompensation = (expected: any) => {
    let flag = false;

    console.log(expected);

    for (const key in expected) {
      if (expected.hasOwnProperty(key)) {
        if (
          expected[key] &&
          expected[key] !== "Compensation"
          // ||
          // expected[key] != null
        ) {
          console.log(expected[key]);
          flag = true;
          break;
        }
      }
    }
    console.log(flag);
    return flag;
  };

  return (
    <>
      {/* <YearlyCompensationDrawer open={open} candidate={candidate} handleClose={handleClose} /> */}
      {candidate?.yearlyCompensation != null &&
        String(candidate?.yearlyCompensation?.currency)?.length > 0 && (
          <div style={Styles.root}>
            <SectionTitle
              actionHandler={openEdit}
              title="Yearly compensation details"
              icon={CompensationIcon}
              fontSize="16px"
              actionType="edit"
              showstyle={false}
            />
            <div style={Styles.body}>
              {candidate?.yearlyCompensation?.current?.total != null && (
                <span
                  style={{ fontSize: 14, color: "#2E4A79", fontWeight: "600" }}
                >
                  Current / Last compensation{" "}
                  <span
                    style={{ fontWeight: "400" }}
                  >{`(in ${candidate?.yearlyCompensation?.currency})`}</span>
                </span>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {candidate?.yearlyCompensation?.current?.total != null &&
                  String(candidate?.yearlyCompensation?.current?.total)
                    ?.length > 0 && (
                    <>
                      <span style={Styles.smallText}>Total</span>
                      <span
                        style={{
                          ...Styles.smallText,
                          // paddingRight: 40,
                          display: "block",
                          width: 75,
                          ...Styles.CompensationColor,
                        }}
                      >
                        {conversion(
                          candidate?.yearlyCompensation?.current?.total
                        ) + "k"}
                        {/* {candidate?.yearlyCompensation?.currency} */}
                      </span>
                    </>
                  )}
              </div>
              <div style={Styles.subBody}>
                {Fields.map((field: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {candidate?.yearlyCompensation?.current != null && (
                      <>
                        {candidate?.yearlyCompensation?.current[field.key] !=
                          null && (
                          <>
                            <span
                              style={{
                                ...Styles.smallText,
                                paddingLeft: "40px",
                              }}
                            >
                              {candidate?.yearlyCompensation?.current[
                                field.key
                              ] > "0"
                                ? field.value
                                : ""}
                            </span>
                            <span
                              style={{
                                ...Styles.smallText,
                                ...Styles.CompensationColor,
                              }}
                            >
                              {candidate?.yearlyCompensation?.current[
                                field.key
                              ] > "0" && field?.value !== "Other"
                                ? conversion(
                                    candidate?.yearlyCompensation?.current[
                                      field.key
                                    ]
                                  ) + "k"
                                : field?.value == "Other"
                                ? conversion(
                                    candidate?.yearlyCompensation?.current[
                                      field.key
                                    ]
                                  )
                                : ""}

                              {/* for number only */}
                              {/* {candidate?.yearlyCompensation?.current[
                              field?.key
                            ] > "0" && field?.value
                              ? conversion(
                                  candidate?.yearlyCompensation?.current[
                                    field?.key
                                  ]
                                ) + "k"
                              : ""} */}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {candidate?.yearlyCompensation?.currentComments != null &&
              candidate?.yearlyCompensation?.currentComments?.length > 0 && (
                <>
                  <div style={Styles.commentBox}>
                    <p
                      style={{
                        fontSize: 14,
                        marginTop: 0,
                        fontWeight: 400,
                        color: "#012653",
                        marginBottom: 3,
                      }}
                    >
                      Comments
                    </p>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#495057",
                        fontStyle: "italic",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: formattedCurrentComment,
                      }}
                    />
                  </div>
                </>
              )}
            <div style={Styles.body}>
              {(candidate?.yearlyCompensation?.expected?.total ||
                anyExpectedCompensation(
                  candidate?.yearlyCompensation?.expected
                )) && (
                // Fields?.some(
                //   (field) =>
                //     candidate?.yearlyCompensation?.expected[field?.key] != null
                // ) &&
                <span
                  style={{ fontSize: 14, color: "#2E4A79", fontWeight: "600" }}
                >
                  Expected compensation{" "}
                  {candidate?.yearlyCompensation?.currency && (
                    <span
                      style={{
                        fontSize: 14,
                        color: "#2E4A79",
                        fontWeight: "400",
                      }}
                    >
                      {`(in ${candidate?.yearlyCompensation?.currency})`}
                    </span>
                  )}
                </span>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* {candidate?.yearlyCompensation?.expected?.total != null && ( */}
                <>
                  {candidate?.yearlyCompensation?.expected?.total > 0 ||
                  calcaluteTotal(candidate?.yearlyCompensation?.expected) >
                    0 ? (
                    <>
                      <span style={Styles.smallText}>Total</span>
                      <span
                        style={{
                          ...Styles.smallText,
                          // paddingRight: 9,
                          display: "block",
                          width: 75,
                          ...Styles.CompensationColor,
                        }}
                      >
                        {candidate?.yearlyCompensation?.expected?.total
                          ? conversion(
                              candidate?.yearlyCompensation?.expected?.total
                            ) + "k"
                          : calcaluteTotal(
                              candidate?.yearlyCompensation?.expected
                            ) + "k"}
                        {/* {candidate?.yearlyCompensation?.currency} */}
                        {<Discount />}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </>
                {/* )} */}
              </div>
              <div style={Styles.subBody}>
                {Fields.map((field: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {candidate?.yearlyCompensation?.expected != null && (
                      <>
                        {candidate?.yearlyCompensation?.expected[field.key] !=
                          null && (
                          <>
                            <span
                              style={{
                                ...Styles.smallText,
                                paddingLeft: "40px",
                              }}
                            >
                              {candidate?.yearlyCompensation?.expected[
                                field.key
                              ] > "0"
                                ? field.value
                                : ""}
                            </span>
                            <span
                              style={{
                                ...Styles.smallText,
                                ...Styles.CompensationColor,
                              }}
                            >
                              {candidate?.yearlyCompensation?.expected[
                                field.key
                              ] > "0" && field?.value !== "Other"
                                ? conversion(
                                    candidate?.yearlyCompensation?.expected[
                                      field.key
                                    ]
                                  ) + "k"
                                : field?.value == "Other"
                                ? conversion(
                                    candidate?.yearlyCompensation?.expected[
                                      field.key
                                    ]
                                  )
                                : ""}

                              {/* only number */}
                              {/* {candidate?.yearlyCompensation?.expected[
                              field.key
                            ] > "0" && field?.value
                              ? conversion(
                                  candidate?.yearlyCompensation?.expected[
                                    field.key
                                  ]
                                ) + "k"
                              : ""} */}

                              {/* <span style={{}}>
                              {candidate?.yearlyCompensation?.expected[
                                field.key
                              ] > 0
                                ? candidate?.yearlyCompensation?.currency
                                : candidate?.yearlyCompensation?.expected[
                                  field.key
                                ]}
                            </span> */}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {nocomments === false ? (
              <></>
            ) : (
              candidate?.yearlyCompensation?.comments != null && (
                <>
                  {candidate?.yearlyCompensation?.comments?.length > 0 ? (
                    <div style={Styles.commentBox}>
                      <p
                        style={{
                          fontSize: 14,
                          marginTop: 0,
                          fontWeight: 400,
                          color: "#012653",
                          marginBottom: 3,
                        }}
                      >
                        Comments
                      </p>
                      <div
                        style={{
                          fontSize: 12,
                          color: "#495057",
                          fontWeight: 400,
                          fontStyle: "italic",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: formattedExpectedComment,
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )
            )}
          </div>
        )}
    </>
  );
};

export default YearlyCompensation;
