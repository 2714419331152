import React, { useEffect, useState } from "react";
import {
  Drawer,
  Input,
  Form,
  Button,
  Divider,
  DatePicker,
  Col,
  Row,
  Switch,
} from "antd";
import { ExperienceInput } from "graphql/generated/graphql";
import moment from "moment";
const { TextArea } = Input;
interface Props {
  show: boolean;
  hide: any;
  experience?: ExperienceInput | any;
  loading: boolean;
  updateExperience: (experience: any) => void;
}

const ExperienceDrawer: React.FC<Props> = ({
  show,
  hide,
  experience,
  loading,
  updateExperience,
}) => {
  const [values, setValues] = useState<any>({});
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(false);
  useEffect(() => {
    experience?.current !== "" &&
      experience?.current !== null &&
      experience?.current !== undefined &&
      setCurrent(experience?.current);
  }, [experience?.current]);
  useEffect(() => {
    const experienceData = {
      ...experience,
      startYear: experience?.startYear && moment(experience.startYear),
      endYear: experience?.endYear && moment(experience.endYear),
      current: !!experience?.current,
    };
    setValues(experienceData);
    form.setFieldsValue(experienceData);
  }, [experience.index]);

  const onFinish = () => {
    if (!!values.company && !!values.position) {
      updateExperience({ ...values });
      hide();
    }
  };
  if (current) {
    form.setFieldsValue({ endYear: "" }); // Set endYear to an empty string
  }
  return (
    <>
      <Drawer
        title="My experience"
        placement="right"
        closable={true}
        onClose={hide}
        visible={show}
        width={500}
        className="menu-drawer"
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          justify="center"
          className="pt-4"
        >
          <Col md={22}>
            <Form
              form={form}
              layout="vertical"
              name="recruiter-experience"
              onFinish={onFinish}
              onValuesChange={(v) => {
                setValues({ ...values, ...v });
              }}
            >
              <Form.Item label="Position" name="position" required>
                <Input />
              </Form.Item>
              <Form.Item label="Company" name="company" required>
                <Input />
              </Form.Item>
              <Row justify="center" className="pb-4" gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Start"
                    name="startYear"
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject("Start date is required!");
                          }

                          if (moment(value).isAfter(moment())) {
                            return Promise.reject(
                              "Start date cannot be a future date!"
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <DatePicker picker="year" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="End"
                    name="endYear"
                    dependencies={["current"]}
                    rules={[
                      {
                        required: current == false,
                        message: "End date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      // defaultValue={moment()}
                      disabled={values.current ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" className="pb-4" gutter={16}>
                <Col span={12}>
                  <Form.Item label="Currently working there" />
                </Col>
                <Col span={4}>
                  <Form.Item name="current">
                    <Switch
                      onChange={(e) => setCurrent(e)}
                      checked={!!values?.current}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ant-btn-teal float-right"
                  shape="round"
                  size="middle"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default ExperienceDrawer;
