import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space, Typography } from "antd";
import DeleteIcon from "../../../../assets/icons/DetailedProfile/delete.svg";

interface TargetItem {
  title: string;
  description: string;
}

interface Props {
  label: string;
  addAssessment: any;
  editAssessment: any;
  setIsSaveDisabled?: any;
}

const TargetProfile: React.FC<Props> = ({
  label,
  addAssessment,
  editAssessment,
  setIsSaveDisabled,
}) => {
  const [assessments, setAssessments] = useState<TargetItem[]>([]);

  // console.log("herherhehrehrehrher", editAssessment);

  const addNewAssessment = () => {
    if (setIsSaveDisabled) {
      setIsSaveDisabled(false);
    }
    if (assessments.length < 7) {
      setAssessments((prevData) => [
        ...prevData,
        { title: "", description: "" },
      ]);
    }
  };

  const deleteAssessment = (assessmentIndex: number) => {
    const updatedItems = assessments.filter(
      (_, index) => index !== assessmentIndex
    );
    setAssessments(updatedItems);
    if (setIsSaveDisabled) {
      setIsSaveDisabled(false);
    }
  };

  const handleAssessmentChange = (
    index: number,
    field: "title" | "description",
    value: string
  ) => {
    if (setIsSaveDisabled) {
      setIsSaveDisabled(false);
    }
    const updatedAssessments = [...assessments];
    updatedAssessments[index] = {
      ...updatedAssessments[index],
      [field]: value,
    };
    setAssessments(updatedAssessments);
  };

  useEffect(() => {
    addAssessment(label, assessments);
  }, [assessments]);

  useEffect(() => {
    const initializeAssessmentsState = (editAssessment: any): TargetItem[] => {
      if (editAssessment && editAssessment.length > 0) {
        return editAssessment.map((item: any) => ({
          title: item.title || "",
          description: item.description || "",
        }));
      }
      return [];
    };

    setAssessments(initializeAssessmentsState(editAssessment));
  }, [editAssessment]);

  return (
    <div className="section-container">
      <Typography className="assessmentContainerTitle">
        Required {label}
      </Typography>
      <Space direction="vertical">
        {assessments.map((assessment, index) => (
          <div key={index}>
            <Row className="assessmentContainer">
              <Col span={24} className="assessmentContainer-Header">
                <Button
                  style={{
                    border: "none",
                    boxShadow: "none",
                  }}
                  shape="circle"
                  size="small"
                  icon={<img src={DeleteIcon} alt="delete icon" />}
                  onClick={() => deleteAssessment(index)}
                />
              </Col>
              <Col span={24} className="assessmentItem">
                <Row gutter={[8, 8]}>
                  <Col span={24} className="assessmentInput">
                    <Form.Item
                      className="section-input"
                      rules={[
                        {
                          required: true,
                          message: "Please input the title",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={`Enter the title of the required ${
                          label === "experience" ? "experience" : "trait"
                        }`}
                        maxLength={45}
                        value={assessment.title}
                        onChange={(e) =>
                          handleAssessmentChange(index, "title", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography className="assessmentItemText">
                      You can enter max 45 characters
                    </Typography>
                  </Col>
                  <Col span={24} className="assessmentInput">
                    <Form.Item
                      className="section-input"
                      rules={[
                        {
                          required: true,
                          message: "Please input the Definition",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={`Enter the definition of the required ${
                          label === "experience" ? "experience" : "trait"
                        }`}
                        maxLength={180}
                        value={assessment.description}
                        onChange={(e) =>
                          handleAssessmentChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography className="assessmentItemText">
                      You can enter max 180 characters
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </Space>
      <Button
        block
        shape="round"
        className="addAssessment"
        onClick={addNewAssessment}
        style={{ fontSize: "12px" }}
      >
        + Add a required {label}
      </Button>
    </div>
  );
};

export default TargetProfile;
