import { useState, useEffect } from "react";
import { useCountriesQuery } from "../graphql/generated/graphql";

const useCountries = () => {
  const [countries, setcountries]: any = useState([]);
  const { data, loading, error } = useCountriesQuery();

  const loadcountries = () => {
    if (!loading && data) {
      const result = data.countries.filter((country) => {
        return country.name !== null;
      });
      setcountries([...result]);
    }
  };
  useEffect(() => {
    loadcountries();
  }, [data]);

  return countries;
};

export default useCountries;
