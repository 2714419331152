import { Avatar, Button, Modal } from "antd";
import MyModal from "components/UI/Modal";
import React, { FC } from "react";
import { Link } from "react-router-dom";

interface InitialProps {
  showMatchModal: boolean;
  handleCancel: any;
  selectedCandidate: any;
  showChatModal: any;
  chatNotifications: any;
  data: any;
  clickOnNotification: any;
}
interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
  candidate: any;
}
const NotificationModal: FC<InitialProps> = ({
  data,
  chatNotifications,
  showChatModal,
  showMatchModal,
  handleCancel,
  selectedCandidate,
}) => {
  return (
    <>
      {showMatchModal && (
        <Modal
          centered={true}
          style={{ borderRadius: 30 }}
          visible={showMatchModal}
          // onOk={handleOk}
          footer={null}
          onCancel={handleCancel}
        >
          <div className="bg-img">
            <p>It’s a Match!</p>
          </div>
          <div style={{ textAlign: "center", padding: "3rem 0" }}>
            <Avatar.Group
              maxCount={2}
              size="large"
              maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            >
              <Avatar
                src={
                  selectedCandidate && selectedCandidate?.companyData?.logoUrl
                }
                style={{ height: 120, width: 120 }}
              />

              <Avatar
                src={selectedCandidate && selectedCandidate?.profilePicture}
                style={{
                  backgroundColor: "#f56a00",
                  height: 120,
                  width: 120,
                  marginLeft: -10,
                }}
              >
                K
              </Avatar>
            </Avatar.Group>
            <br />
            <p
              style={{
                padding: "1rem 3rem",
                fontSize: "24px",
                fontWeight: 600,
                color: "#2E4A79",
              }}
            >
              You can chat now directly with the candidate. It's that simple!
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: 30 }}>
              {showChatModal && (
                <MyModal
                  titleType="button"
                  enableCustomBtnIcon={true}
                  customBtnIcon={
                    chatNotifications.find(
                      (notification: Notification) =>
                        notification.matchId === selectedCandidate?._id
                    ) ? (
                      <Button className="Modal-sms-btn">Send a message</Button>
                    ) : (
                      <Button className="Modal-sms-btn">Send a message</Button>
                    )
                  }
                  onOK={() => {}}
                  width={700}
                  footer={null}
                  ModalTytle="huu"
                  titleBtnClass="ant-btn-info"
                  record={selectedCandidate}
                  candidate={selectedCandidate}
                  company={data?.companyData}
                >
                  <div
                    style={{
                      paddingTop: 35,
                      paddingBottom: 35,
                    }}
                  ></div>
                </MyModal>
              )}
              <Link
                to={{
                  pathname: `/candidate-listings/view/${selectedCandidate?._id}`,
                  state: {
                    company: data?.companyData,
                    matchId: selectedCandidate?.matchId,
                    // from: machesCandidatesFromStore,
                    jobId: selectedCandidate?.jobData?._id,
                    jobName: selectedCandidate?.jobData?.title,
                    companyName: selectedCandidate?.companyData?.name,
                    selectedCandidate: selectedCandidate,
                    // tabActiveKey,
                  },
                }}
              >
                <Button className="Modal-sms-btn">View Candidate</Button>
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default NotificationModal;
