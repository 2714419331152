export interface Matchs {
  newCandidates: any;
  likedCandidates: any;
  disLikedCandidates: any;
  matchedCandidates: any;
  selectedCandidates: any;
  rejectedCandidates: any;
  hiredCandidates: any;
  favouritesCandidates: any;
}
export interface MatchsState {
  matchs: Matchs;
}

// Describing the different ACTION NAMES available
export const STORE_NEW_CANDIDATES = "STORE_NEW_CANDIDATES";
export const STORE_LIKED_CANDIDATES = "STORE_LIKED_CANDIDATES";
export const MOVED_TO_LIKED_CANDIDATES = "MOVED_TO_LIKED_CANDIDATES";
export const MOVED_TO_MATCHED_CANDIDATES = "MOVED_TO_MATCHED_CANDIDATES";
export const MOVED_TO_DISLIKED_CANDIDATES = "MOVED_TO_DISLIKED_CANDIDATES";
export const MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES =
  "MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES";
export const MOVED_TO_MATCHED_FROM_DISLIKED_CANDIDATES =
  "MOVED_TO_MATCHED_FROM_DISLIKED_CANDIDATES";
export const MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES =
  "MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES";
export const MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES =
  "MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES";
export const MOVED_TO_MATCHED_FROM_SELECTED_CANDIDATES =
  "MOVED_TO_MATCHED_FROM_SELECTED_CANDIDATES";
export const MOVED_TO_SELECTED_FROM_REJECTED_CANDIDATES =
  "MOVED_TO_SELECTED_FROM_REJECTED_CANDIDATES";
export const STORE_DISLIKED_CANDIDATES = "STORE_DISLIKED_CANDIDATES";
export const STORE_MATCHED_CANDIDATES = "STORE_MATCHED_CANDIDATES";
export const STORE_SELECTED_CANDIDATES = "STORE_SELECTED_CANDIDATES";
export const STORE_SELECTED_CANDIDATES_LOAD = "STORE_SELECTED_CANDIDATES_LOAD";
export const STORE_REJECTED_CANDIDATES = "STORE_REJECTED_CANDIDATES";
export const LOAD_REJECTED_CANDIDATES = "LOAD_REJECTED_CANDIDATES";
export const STORE_HIRED_CANDIDATES = "STORE_HIRED_CANDIDATES";
export const LOAD_HIRED_CANDIDATES = "LOAD_HIRED_CANDIDATES";
export const STORE_FAVOURITES_CANDIDATES = "STORE_FAVOURITES_CANDIDATES";
export const LOAD_FAVOURITES_CANDIDATES = "LOAD_FAVOURITES_CANDIDATES";

interface storeNewCandidates {
  type: typeof STORE_NEW_CANDIDATES;
  newCandidates: Array<object>;
}

interface storeLikedCandidates {
  type: typeof STORE_LIKED_CANDIDATES;
  likedCandidates: Array<object>;
}
interface movedToLikedCandidates {
  type: typeof MOVED_TO_LIKED_CANDIDATES;
  candidateId: String;
}
interface movedToMatchedCandidates {
  type: typeof MOVED_TO_MATCHED_CANDIDATES;
  candidateId: String;
}
interface movedToDisLikedCandidates {
  type: typeof MOVED_TO_DISLIKED_CANDIDATES;
  candidateId: String;
}
interface movedToDisLikedFromLikedCandidates {
  type: typeof MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES;
  candidateId: String;
}
interface movedToLikedFromDisLikedCandidates {
  type: typeof MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES;
  candidateId: String;
}
interface movedToMatchedFromDisLikedCandidates {
  type: typeof MOVED_TO_MATCHED_FROM_DISLIKED_CANDIDATES;
  candidateId: String;
}
interface movedToDisLikedFromMatchedCandidates {
  type: typeof MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES;
  candidateId: String;
}

interface movedToMatchedFromSelectedCandidates {
  type: typeof MOVED_TO_MATCHED_FROM_SELECTED_CANDIDATES;
  candidateId: String;
}
interface movedToSelectedFromRejectedCandidates {
  type: typeof MOVED_TO_SELECTED_FROM_REJECTED_CANDIDATES;
  candidateId: String;
}
interface storeDislikedCandidates {
  type: typeof STORE_DISLIKED_CANDIDATES;
  disLikedCandidates: Array<object>;
}

interface storeMatchedCandidates {
  type: typeof STORE_MATCHED_CANDIDATES;
  matchedCandidates: Array<object>;
}

interface storeSelectedCandidatesLoad {
  type: typeof STORE_SELECTED_CANDIDATES_LOAD;
  storeSelected: Array<object>;
}

interface loadRejectedCandidates {
  type: typeof LOAD_REJECTED_CANDIDATES;
  storeSelected: Array<object>;
}

interface loadHiredCandidates {
  type: typeof LOAD_HIRED_CANDIDATES;
  storeSelected: Array<object>;
}

interface loadFavouritesCandidates {
  type: typeof LOAD_FAVOURITES_CANDIDATES;
  storeSelected: Array<object>;
}

interface storeSelectedCandidates {
  type: typeof STORE_SELECTED_CANDIDATES;
  candidateId: String;
}

interface storeRejectedCandidates {
  type: typeof STORE_REJECTED_CANDIDATES;
  candidateId: String;
}

interface storeHiredCandidates {
  type: typeof STORE_HIRED_CANDIDATES;
  candidateId: String;
}

interface storeFavouritesCandidates {
  type: typeof STORE_FAVOURITES_CANDIDATES;
  candidateId: String;
}

export type matchsActionTypes =
  | storeLikedCandidates
  | storeDislikedCandidates
  | storeNewCandidates
  | storeMatchedCandidates
  | movedToLikedCandidates
  | movedToMatchedCandidates
  | movedToDisLikedCandidates
  | movedToDisLikedFromLikedCandidates
  | movedToLikedFromDisLikedCandidates
  | movedToDisLikedFromMatchedCandidates
  | movedToMatchedFromDisLikedCandidates
  | movedToMatchedFromSelectedCandidates
  | movedToSelectedFromRejectedCandidates
  | storeSelectedCandidates
  | storeSelectedCandidatesLoad
  | storeRejectedCandidates
  | loadRejectedCandidates
  | storeHiredCandidates
  | loadHiredCandidates
  | storeFavouritesCandidates
  | loadFavouritesCandidates;
