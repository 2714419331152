import { FlagIcon } from "assets/icons/DetailedProfile";
import React, { useEffect, useState } from "react";
import { SectionTitle } from "../../Shared";
import FileViewer from "react-file-viewer";

import "./coverLetter.scss";
interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #BC9623",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 10,
  },
  fileWrapper: {
    height: 600,
    border: "solid 1px #ececec",
    marginBottom: 10,
  },
};

const Attachment: React.FC<PropsType> = ({ candidate }) => {
  const [fileAdded, setFileAdded] = useState(true);
  const [docs, setDocs] = useState<any>([]);

  function get_url_extension(url: string) {
    return url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
  }

  useEffect(() => {
    setFileAdded(false);
    setTimeout(() => {
      setFileAdded(true);
    }, 1000);
  }, [candidate?.coverLetter]);

  return (
    <>
      {candidate?.coverLetter != null && candidate?.coverLetter != "" && (
        <div style={Styles.root}>
          <SectionTitle
            fileLink={candidate?.coverLetter}
            title="Attachment"
            fontSize="16px"
            actionType="uvd"
            showstyle={false}
          />
          {/* <div className="resume-preview">
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={docs}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
              }}
              style={{ height: 500 }}
            />
          </div> */}
          <div style={Styles.body}>
            <div style={Styles.fileWrapper}>
              {fileAdded && candidate?.coverLetter && (
                <iframe
                  src={
                    candidate?.coverLetter?.includes(".pdf")
                      ? candidate?.coverLetter +
                        "#toolbar=0&scrollbar=0&view=fitH&view=fitV"
                      : `https://docs.google.com/gview?url=${candidate?.coverLetter}&embedded=true`
                  }
                  scrolling="auto"
                  width="100%"
                  height="100%"
                  allowFullScreen={true}
                ></iframe>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Attachment;
