import { CheckOutlined, CloseOutlined, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import PageTitle from "components/UI/PageTitle/PageTitle";
import React, { useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import "../styles.scss";

interface PropsType {
  handleSave: () => void;
}

const PageHeader: React.FC<PropsType> = ({ handleSave }) => {
  let { path, url } = useRouteMatch();
  const history = useHistory();
  const location: any = useLocation();

  const handleBack = () => {
    history.push(`/candidate-listings/${location.state?.jobId}`);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PageTitle
        title={""}
        btnActive={false}
        back
        fromProfile={true}
        backHandler={handleBack}
        // handleGoBack={goBack}
      />
    </div>
  );
};

export default PageHeader;
