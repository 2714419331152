import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./AssessmentTableStyles.scss";
import { CompetenciesIcon } from "assets/icons/DetailedProfile";
import NoData from "components/UI/NoData/NoData";

const colors = {
  experiences: "#990000CC",
  traits: "#2E4A79E5",
};

interface ExperienceItem {
  title: string;
  description: string;
}

interface PropsType {
  open: boolean;
  setOpen: any;
  view: string;
  setView: any;
  assessment: any;
}

const GetRow = ({
  rowData,
  index,
  view,
  TableData,
  setTableData,
}: {
  rowData: ExperienceItem;
  index: number;
  view: string;
  TableData: ExperienceItem[];
  setTableData: any;
}) => {
  const { title, description } = rowData;

  return (
    <>
      <Col
        span={5}
        style={{
          backgroundColor: colors[view],
          color: "white",
          height: "60px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "14px",
          lineHeight: "16px",
        }}
      >
        {title}
      </Col>
      <Col
        span={18}
        style={{
          backgroundColor: colors[view],
          color: "white",
          height: "60px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          lineHeight: "16px",
          //   marginLeft: "14px",
          paddingInline: "10px",
        }}
      >
        {description}
      </Col>
    </>
  );
};

const AssessmentTableModal: React.FC<PropsType> = ({
  open,
  setOpen,
  view,
  setView,
  assessment,
}) => {
  const [TableData, setTableData] = useState([]);

  useEffect(() => {
    if (open && view === "experiences") {
      setTableData(assessment?.experience);
    } else {
      setTableData(assessment?.traits);
    }
  }, [view, assessment]);

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <>
      <Modal
        width={800}
        title={
          <span style={{ fontSize: 18 }}>
            <img
              src={CompetenciesIcon}
              alt="Competencies icon"
              style={{ marginRight: "10px" }}
            />
            {`Required ${view}`}
          </span>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <Row className="view-switches">
          <Button
            className={view === "experiences" && "Experience"}
            onClick={() => setView("experiences")}
          >
            Experiences
          </Button>
          <Button
            className={view === "traits" && "traits"}
            onClick={() => setView("traits")}
          >
            Traits
          </Button>
        </Row>

        <Row justify="space-evenly" className="table-row" gutter={[10, 10]}>
          {TableData?.length > 0 ? (
            TableData?.map((row, index) => {
              return (
                <GetRow
                  key={index}
                  rowData={row}
                  index={index}
                  view={view}
                  TableData={TableData}
                  setTableData={setTableData}
                />
              );
            })
          ) : (
            <NoData message={`No required ${view} added`} />
          )}
        </Row>
      </Modal>
    </>
  );
};

export default AssessmentTableModal;
