import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authCheckState } from "./store/auth/actions";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import MainLayout from "./hoc/Layouts/MainLayout/MainLayout";
import Login from "./containers/Auth/Login";
import Registration from "./containers/Auth/Registration/Registeration";
import Activation from "./containers/Auth/Activation";
import RecoverPassword from "./containers/Auth/RecoverPassword/RecoverPassword";
import ProfilePage from "./containers/Auth/Profile";
import ResetPassword from "./containers/Auth/RecoverPassword/ResetPassword";
import InternetConnection from "components/UI/InternetConnection";
import TabActvContextProvider from "containers/Candidates/CandidateListings/context/tabActvContext";
import MainApp from "./containers";
import Jobs from "./containers/Job";
import Candidates from "./containers/Candidates";
import Companies from "./containers/Companies";
import NotFound from "./components/NotFound/NotFound";
import "./App.scss";
import NotificationsMain from "containers/Notifications";
import Printview from "containers/Companies/AddCompany/Printview";
// import TwakChat from "modules/TwakChat";
// import Notifications from "components/Notifications";
// import { requestFirebaseNotificationPermission } from "./firebase";

const App = () => {
  const dispatch = useDispatch();

  // requestFirebaseNotificationPermission()
  //   .then((firebaseToken) => {
  //     console.log(firebaseToken);
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //   });

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);
  return (
    <>
      <div className="App">
        {/* <TwakChat /> */}
        <InternetConnection />
        {window.location.pathname.indexOf("/print") > -1 ? (
          <Switch>
            <Route path="/print/:candidateId/:matchId" component={Printview} />
          </Switch>
        ) : (
          <MainLayout>
            <Switch>
              <Route path="/pr" component={Printview} />
              {/* <Notifications /> */}
              <PrivateRoute path="/" component={MainApp} exact />
              <PrivateRoute path="/companies" component={Companies} />
              <PrivateRoute path="/job-listings" component={Jobs} />
              <PrivateRoute path="/candidate-listings" component={Candidates} />
              <PrivateRoute path="/profiles" component={ProfilePage} />
              <PrivateRoute
                path="/notifications"
                component={NotificationsMain}
              />

              {/* Auth */}
              <Route path="/login" component={Login} />
              <Route path="/register" component={Registration} />
              <Route path="/activateAccount/:code" component={Activation} />
              <Route path="/recover-password" component={RecoverPassword} />
              <Route path="/reset-password/:email" component={ResetPassword} />
              <Route path="*" exact={true} component={NotFound} />
            </Switch>
          </MainLayout>
        )}
      </div>
    </>
  );
};

export default withRouter(App);
