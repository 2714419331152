import { UserIcon } from "assets/icons/DetailedProfile";
import React, { useContext, useState } from "react";
// import { PersonalDetailsDrawer } from '../Edit'
import { SectionTitle } from "../Shared";

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #364972",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};

const PersonalDetails: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const editInfo = () => {
    setOpen(true);
  };

  return (
    <>
      {/* <PersonalDetailsDrawer open={open} handleClose={handleClose} candidate={candidate} /> */}
      {candidate?.personalInformation != null &&
        candidate?.personalInformation?.length > 0 && (
          <div style={Styles.root}>
            <SectionTitle
              actionHandler={editInfo}
              title="Personal details"
              icon={UserIcon}
              fontSize="16px"
              actionType="edit"
              showstyle={false}
            />
            <div style={Styles.body}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#495057",
                }}
                dangerouslySetInnerHTML={{
                  __html: candidate?.personalInformation,
                }}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default PersonalDetails;
