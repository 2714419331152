import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import {
  useGetCandidateQuery,
  useGetMatchQuery,
} from "graphql/generated/graphql";
import { Row, Col, message } from "antd";
import { PersonalInfo } from "containers/Candidates/Profile";
import Comments from "containers/Candidates/Profile/RecruiterComments/Comments";
import NextSteps from "containers/Candidates/Profile/RecruiterComments/NextSteps";
import "./print.scss";
import printlogo from "assets/icons/printicons/printlogo.svg";

interface ViewRecruiterProps {}

const Printview2: React.FC<ViewRecruiterProps> = ({}) => {
  const { path } = useRouteMatch();
  const professionalInterestsRef = useRef<HTMLDivElement>(null);
  const pdRef = useRef<HTMLDivElement>(null);
  const ycRef = useRef<HTMLDivElement>(null);
  const tfRef = useRef<HTMLDivElement>(null);
  const { candidateId, matchId } = useParams<any>();
  const location: any = useLocation();
  const [candidateData, setCandidateData] = useState<any | null | undefined>(
    null
  );
  const [candidates, setCandidates] = useState<any>(null);
  const [matchData, setMatchData] = useState<any | null | undefined>(null);
  const [match, setMatch] = useState<any | null | undefined>(null);

  //gql query for get data
  const {
    data: data1,
    loading: loading1,
    error: error1,
  }: any = useGetMatchQuery({
    variables: {
      // matchId: location.state?.matchId,
      // matchId: "62e3b851ff4442000f716feb",
      matchId: matchId,
    },
    fetchPolicy: "network-only",
  });

  const { data, loading, error } = useGetCandidateQuery({
    variables: {
      // candidateId: "62e3b851ff4442000f716fe7",
      candidateId: candidateId,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data1 && data1?.getMatch && data1?.getMatch?.candidateListing) {
      const { candidateListing, ...rest }: any = data1?.getMatch;
      setMatch(rest);
      setMatchData(rest);
      setCandidates(location.state?.candidateArray);
    }
  }, [data1]);

  useEffect(() => {
    if (data && data.getCandidate) {
      console.log({ ...data?.getCandidate });
      setCandidateData(data?.getCandidate);
    }
  }, [data]);
  return (
    <>
      <div style={{ marginBottom: "25px", marginTop: "25px" }}>
        <img src={printlogo} alt="" />
      </div>
      <Row gutter={24} style={{ marginTop: 25, justifyContent: "center" }}>
        <Col span={7}>
          <PersonalInfo
            match={matchData}
            candidate={candidateData}
            showActions={false}
            showSocial={false}
            showVideo={false}
          />
        </Col>

        <Col span={10}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              fontSize: 18,
              fontWeight: 600,
              color: "#2E4A79",
              backgroundColor: "rgba(26, 131, 140, 0.42)",
              borderRadius: 10,
              marginBottom: 30,
            }}
          >
            <span>RECRUITERS’ COMMENTS</span>
          </div>

          <div style={{ marginTop: 20 }}>
            <Comments match={match} candidate={candidateData} />
          </div>

          <div style={{ marginTop: 20 }}>
            <NextSteps candidate={candidateData} match={match} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Printview2;
