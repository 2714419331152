import React, { useState, useContext } from "react";
import { SectionTitle } from "../Shared";
import { SummaryIcon } from "assets/icons/DetailedProfile";
import { Typography, Row, Col } from "antd";
import { Benchmark, Skills, Tags } from "./Index";
import "./Components/components.scss";
// import { SummaryDrawer } from '../Edit'

// import SkillsDrawer from '../Edit/SkillsDrawer'
// import TagsDrawer from '../Edit/TagsDrawer'

interface PropsType {
  candidate: any;
  match: any;
  Visible?: Boolean;
  showBenchmark?: Boolean;
}

const Styles = {
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 29,
    paddingBottom: 14,
    // border: "solid 1px #314774",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    height: "100%",
  },
  bodyContent: {
    paddingLeft: 25,
    marginTop: 5,
    paddingBottom: 10,
    display: "flex",
    // minHeight: "80px",
  },
};

const Summary: React.FC<PropsType> = ({
  candidate,
  match,
  Visible,
  showBenchmark,
}) => {
  const [open, setOpen] = useState(false);
  const [openSkill, setOpenSkills] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [openTag, setOpenTags] = useState(false);

  const handleEdit = () => {
    setOpen(true);
  };

  const handleAddSkills = () => {
    setOpenSkills(true);
  };

  const handleAddTags = () => {
    setOpenTags(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSkills = () => {
    setOpenSkills(false);
  };

  const handleCloseTags = () => {
    setOpenTags(false);
  };

  const handleUpdate = (data: any) => {
    setOpen(false);
  };

  const handleUpdateSkills = (data: any) => {
    setOpenSkills(false);
  };

  const handleUpdateTags = (data: any) => {
    setOpenTags(false);
  };

  return (
    <>
      {candidate?.description?.length > 0 && (
        <div style={showBenchmark == true ? Styles.root : {}}>
          <Row style={{ height: "100%" }}>
            <Col
              span={showBenchmark == true ? 14 : 24}
              style={{ height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    color: "#012653",
                    fontSize: "16px",
                  }}
                >
                  Summary
                </Typography>
                <div
                  style={
                    showBenchmark == true
                      ? { ...Styles.bodyContent, flexWrap: "wrap" }
                      : {
                          ...Styles.bodyContent,
                          flexWrap: "wrap",
                          paddingLeft: "0px",
                        }
                  }
                >
                  {candidate?.description != null &&
                  candidate?.description != "" ? (
                    <>
                      {candidate?.description && (
                        <>
                          <Typography
                            style={{ fontWeight: 400, fontSize: "14px" }}
                          >
                            {candidate?.description}
                            {/* {seeMore
                            ? candidate?.description
                            : candidate?.description.slice(0, 270)}
                          {!seeMore && candidate?.description?.length > 250 && (
                            <>
                              <span> ...</span>
                            </>
                          )} */}
                            {/* <span
                            onClick={() => setSeeMore(!seeMore)}
                            style={{
                              marginLeft: 1,
                              cursor: "pointer",
                              color: "#344976",
                            }}
                          >
                            {" "}
                            {candidate?.description?.length > 250 &&
                              (seeMore ? "See less" : "See more")}
                            {}
                          </span> */}
                          </Typography>
                          {/* ) : (
                          <>
                            {seeMore
                              ? candidate?.description
                              : candidate?.description.slice(0, 350)}
                            {!seeMore &&
                              candidate?.description?.length > 250 && (
                                <>
                                  <span> ...</span>
                                </>
                              )}
                            <span
                              onClick={() => setSeeMore(!seeMore)}
                              style={{
                                marginLeft: 1,
                                cursor: "pointer",
                                color: "#344976",
                              }}
                            >
                              {" "}
                              {candidate?.description &&
                                (seeMore ? "See less" : "See more")}
                              {}
                            </span>
                          </> */}
                        </>
                      )}
                    </>
                  ) : (
                    <Typography style={{ color: "gray" }}>
                      No summary provided by the candidate
                    </Typography>
                  )}
                </div>

                {/* <Skills
                onAdd={handleAddSkills}
                inDrawer={false}
                candidate={candidate}
                showSkills={Visible}
                showSeeMore={true}
                showskillstyle={false}
              /> */}
              </div>
            </Col>
            {/* <Col span={10}>
            <Row align="middle">
              <Col style={{ width: "100%" }}>
                <div style={{ paddingLeft: 33, paddingRight: 10 }}>
                  <Benchmark
                    match={match}
                    candidate={candidate}
                    showBenchmark={showBenchmark}
                  />
                </div>
              </Col>
            </Row>
          </Col> */}
          </Row>
          {/* <Row style={{ marginTop: 41 }}> */}
          {/* <Col span={15}>
                        <Skills onAdd={handleAddSkills} inDrawer={false} candidate={candidate} />
                    </Col> */}
          {/* <Col span={9}>
                        <Tags onAdd={handleAddTags} candidate={candidate} />
                    </Col> */}
          {/* </Row> */}
        </div>
      )}
    </>
  );
};

export default Summary;
