import { Tag, Typography } from "antd";
import targetsector from "assets/icons/profilesvgs/targetsector.svg";
import React, { useContext, useState } from "react";

// import { ProfessionalInterestsDrawer } from '../Edit'
import { SectionTitle } from "../Shared";

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #364972",
    boxShadow: "0px 0px 4px #00000040 ",
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};
let selectedSectors = [
  {
    _id: "61eecf80f21816aa12cd6878",
    title: "Art | Entertainment",
    label1: "&",
    label2: "Entertainment",
    selected: false,
  },
  {
    _id: "5fff12254a28d2476b41ecda",
    title: "Communications | Marketing",
    label1: "&",
    label2: "",
    selected: false,
  },
  { _id: "61eecf42f21816aa12cd6877", title: "Construction", selected: false },
  {
    _id: "5fff12254a28d2476b41ecdd",
    title: "Defense | Aerospace",
    label1: "&",
    label2: "",
    selected: false,
  },
  { _id: "5fff12254a28d2476b41ece1", title: "Education", selected: false },
  {
    _id: "5fff12254a28d2476b41ecde",
    title: "Energy | Environment",
    label1: "&",
    label2: "",
    selected: false,
  },
  {
    _id: "5fff12254a28d2476b41ed34",
    title: "Finance | Banking",
    label1: "",
    label2: "",
    selected: false,
  },
  {
    _id: "5fff12254a28d2476b41ecd6",
    title: "Healthcare | Biotech",
    label1: "&",
    label2: "Biotech",
    selected: false,
  },
  { _id: "5fff12254a28d2476b41ecd7", title: "ITC", selected: false },
  { _id: "5fff12254a28d2476b41ece2", title: "Other", selected: false },
  {
    _id: "5fff12254a28d2476b41ece0",
    title: "Professional services",
    selected: false,
  },
  { _id: "5fff12254a28d2476b41ecdb", title: "Public", selected: false },
];
const Mytarget: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);
  const [sectors, setSectors] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = () => {
    setOpen(true);
  };

  const Interest = ({ title }: any) => {
    return (
      <Tag
        style={{
          border: "none",
          marginRight: 10,
          marginBottom: 10,
          backgroundColor: "#990000",
          borderRadius: 20,
          color: "white",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {title}
      </Tag>
    );
  };
  React.useEffect(() => {
    const filteredSectors = candidate?.sectors.filter((interest: any) =>
      selectedSectors?.some((sector: any) => sector?.title === interest?.title)
    );
    setSectors(filteredSectors);
  }, [candidate, selectedSectors]);
  return (
    <>
      {/* <ProfessionalInterestsDrawer candidate={candidate} open={open} handleClose={handleClose} /> */}
      {sectors?.length > 0 && (
        <div style={Styles.root}>
          <SectionTitle
            actionHandler={openEdit}
            title="Target sectors"
            icon={targetsector}
            fontSize="16px"
            actionType="edit"
            showstyle={false}
          />
          <div style={Styles.body}>
            {
              // candidate?.professionalInterests != null &&

              sectors?.map(
                (interest: any, index: any) =>
                  index < 5 && <Interest title={interest.title} />
              )
            }
          </div>
        </div>
      )}
    </>
  );
};

export default Mytarget;
